import { NgModule } from '@angular/core';
import { MaterialModule } from '@shared/material.module';
import { LoadingSpinnerComponent } from '@shared/components/loading-spinner/loading-spinner.component';
import {AsyncPipe, NgIf} from '@angular/common';

@NgModule({
    imports: [
        MaterialModule,
        AsyncPipe,
        NgIf,
    ],
  declarations: [
    LoadingSpinnerComponent,
  ],
  exports: [
    LoadingSpinnerComponent,
  ],
})
export class LoadingSpinnerModule {}
