import {AfterViewInit, Component, HostBinding, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
import {NavService} from '@shared/services/nav-service.service';
import {PermissionService} from '@shared/services/permission.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslationService} from '@shared/services/translation.service';

@Component({
  selector: 'saf-navigation-list-item',
  templateUrl: './navigation-list-item.component.html',
  styleUrls: ['./navigation-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('500ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class NavigationListItemComponent implements OnInit {

  expanded: boolean;
  @Input() item;
  @Input() depth: number;
  @HostBinding('attr.aria-expanded') ariaExpanded ;


  constructor(
    public navService: NavService,
    public router: Router,
    private permissionService: PermissionService,
    private snackBar: MatSnackBar,
    private translationService: TranslationService) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }

  onItemSelected(item) {
    localStorage.removeItem('pageIndex');
    localStorage.removeItem('start');
    if (!item.children || !item.children.length) {
      if (this.permissionService.hasPermission(item.permission) || !item.permission) {
        this.router.navigate([item.routes]);
      } else {
        return this.snackBar.open(this.translationService.translate('permissionError'));
      }
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
      // we need this later
      // this.router.navigate(['/navigation/helper'], {queryParams: {navigation: item.label}} );
    }
  }
}
