import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FacilityPartListDialogComponent} from '@shared/components/facility-part-list-dialog/facility-part-list-dialog.component';
import { FacilityPartAddDialogComponent } from './facility-part-add-dialog/facility-part-add-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {ExtendedModule, FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {EmptyMessageModule} from '@shared/components/empty-message/empty-message.module';
import {LoadingSpinnerModule} from '@shared/components/loading-spinner/loading-spinner.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';



@NgModule({
  declarations: [FacilityPartListDialogComponent, FacilityPartAddDialogComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        PipesModule,
        EmptyMessageModule,
        LoadingSpinnerModule,
        LoadingOverlayModule,
        ReactiveFormsModule,
    ],
  exports: [FacilityPartListDialogComponent, FacilityPartAddDialogComponent]
})
export class FacilityPartListDialogModule { }
