import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginTerminal } from '@terminal/store/terminal-page/terminal-page.actions';
import { TerminalPageState } from '@terminal/store/terminal-page/terminal-page.state';

@Component({
  selector: 'saf-terminal-login',
  templateUrl: './terminal-login.component.html',
  styleUrls: ['./terminal-login.component.scss']
})
export class TerminalLoginComponent implements OnInit {
  @Select(TerminalPageState.loading) loading$: Observable<boolean>;
  formGroup: UntypedFormGroup;

  constructor(private store: Store) {}

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      terminalId: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required),
    });
  }

  submitForm() {
    if (this.formGroup.valid) {
      this.store.dispatch(new LoginTerminal(this.formGroup.value));
    }
  }
}
