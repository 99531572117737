import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { SuccessResponse } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateClassificationService {

  constructor(private api: ApiService) {}

  delete(templateId: number, classificationId: number): Observable<SuccessResponse> {
    return this.api.delete(`/templateHazards`, {
      templateId,
      classificationId,
    });
  }
}
