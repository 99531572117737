import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { AuthState } from '@shared/store/auth/auth.state';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedOutGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private store: Store, private router: Router) {}

  canActivate(): boolean {
    return this.checkLogin();
  }

  canActivateChild(): boolean {
    return this.checkLogin();
  }

  canLoad(): boolean {
    return this.checkLogin();
  }

  private checkLogin(): boolean {
    if (this.store.selectSnapshot(AuthState.authenticated)) {
      this.router.navigateByUrl('/');

      return false;
    }

    return true;
  }
}
