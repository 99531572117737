import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InstructionQuickViewComponent} from '@shared/components/instruction-quick-view/instruction-quick-view.component';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';



@NgModule({
  declarations: [InstructionQuickViewComponent],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    LoadingOverlayModule
  ],
  exports: [InstructionQuickViewComponent]
})
export class InstructionQuickViewModule { }
