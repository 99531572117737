import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualificationTypeSelectComponent } from './qualification-type-select.component';
import {MaterialModule} from '@shared/material.module';
import {FormsModule} from '@angular/forms';
import {PipesModule} from '@shared/pipes/pipes.module';



@NgModule({
  declarations: [
    QualificationTypeSelectComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    PipesModule
  ],
  exports: [
    QualificationTypeSelectComponent
  ]
})
export class QualificationTypeSelectModule { }
