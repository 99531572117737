import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'date',
  pure: true
})
export class DatePipe implements PipeTransform {

  transform(date: string, format: string = 'DD.MM.YY'): any {
    if (!date || (typeof date === 'string' && date.indexOf('0000-00-00') !== -1)) {
      return null;
    }

    return moment(date).format(format);
  }
}
