import { Injectable } from '@angular/core';
import { Country } from '@shared/models/country.model';
import { Observable, Subject } from 'rxjs';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import { ListResponse } from '@shared/models/response.model';
import { ApiService } from '@shared/services/api.service';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getAll(params?: object): Observable<ListResponse<Country>> {
    return this.api.get(`/country-list`, params);
  }

  getById(id): Observable<Country> {
    return this.api.get(`/country/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
