import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import { Profile } from '@shared/models/profile.model';
import { ProfileDetails } from '@shared/models/profile-details.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

	constructor(private api: ApiService) {}

	@Cacheable({
	cacheBusterObserver: cacheNotifier
	})
	getAll(params?: object): Observable<ListResponse<Profile>> {
		return this.api.get(`/profiles`, params);
	}

	getList(params?: object): Observable<ListResponse<ProfileDetails>> {
		return this.api.get(`/profile-list`, params);
	}

	getById(id: number): Observable<DataResponse<Profile>> {
		return this.api.get(`/profile/${id}`);
	}

	@CacheBuster({
	cacheBusterNotifier: cacheNotifier
	})
	create(data: object): Observable<DataResponse<Profile>> {
		return this.api.post(`/profile`, data);
	}

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  copyProfile(id: number, data: object): Observable<DataResponse<Profile>> {
    return this.api.post(`/profile/${id}/copy`, data);
  }

	@CacheBuster({
	cacheBusterNotifier: cacheNotifier
	})
	update(id: number, data: object): Observable<DataResponse<Profile>> {
		return this.api.put(`/profile/${id}`, data);
	}

	@CacheBuster({
	cacheBusterNotifier: cacheNotifier
	})
	delete(id: number): Observable<SuccessResponse> {
		return this.api.delete(`/profile/${id}`);
	}

	static clearCache() {
		cacheNotifier.next();
	}

	getDetail(profileId: number): Observable<ProfileDetails> {
		return this.api.get(`/profile-detail/${profileId}`);
	}
}
