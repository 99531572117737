import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TerminalComponent } from '@terminal/pages/terminal/terminal.component';
import { TerminalLoginComponent } from '@terminal/pages/terminal-login/terminal-login.component';
import { IsLoggedInTerminalGuard } from '@shared/guards/is-logged-in-terminal.guard';
import { IsLoggedOutTerminalGuard } from '@shared/guards/is-logged-out-terminal.guard';
import { TerminalPageComponent } from '@terminal/pages/terminal-page/terminal-page.component';

const routes: Routes = [
  {
    path: 'terminal',
    component: TerminalComponent,
    data: { title: 'terminal' },
    children: [
      {
        path: 'login',
        component: TerminalLoginComponent,
        canActivate: [IsLoggedOutTerminalGuard],
        data: {
          title: 'terminalLogin',
        }
      },
      {
        path: '',
        component: TerminalPageComponent,
        canActivate: [IsLoggedInTerminalGuard],
        pathMatch: 'full',
        data: {
          title: 'terminal',
        }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TerminalRoutingModule {}
