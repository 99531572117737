import { createSelector, NgxsAfterBootstrap, Selector, State, StateContext } from '@ngxs/store';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Breakpoints } from '@shared/models/breakpoints.model';
import {Injectable} from '@angular/core';

export class BreakpointStateModel {
  currentBreakpoint: string;
  breakpoints: { [key: string]: boolean };
}

@State<BreakpointStateModel>({
  name: 'breakpoint',
})

@Injectable()
export class BreakpointState implements NgxsAfterBootstrap {

  @Selector()
  static currentBreakpoint(state: BreakpointStateModel) {
    return state.currentBreakpoint;
  }

  @Selector()
  static breakpoints(state: BreakpointStateModel) {
    return state.breakpoints;
  }

  static isBreakpoint(breakpoint: string | string[]) {
    return createSelector([BreakpointState], (state: BreakpointStateModel) => {
      const breakpoints = Array.isArray(breakpoint) ? breakpoint : [breakpoint];

      return breakpoints.some((breakpoint) => state.currentBreakpoint === breakpoint);
    });
  }

  @Selector()
  static isXSmall(state: BreakpointStateModel) {
    return state.currentBreakpoint === Breakpoints.XSmall;
  }

  @Selector()
  static isSmall(state: BreakpointStateModel) {
    return state.currentBreakpoint === Breakpoints.Small;
  }

  @Selector()
  static isMedium(state: BreakpointStateModel) {
    return state.currentBreakpoint === Breakpoints.Medium;
  }

  @Selector()
  static isLarge(state: BreakpointStateModel) {
    return state.currentBreakpoint === Breakpoints.Large;
  }

  @Selector()
  static isXLarge(state: BreakpointStateModel) {
    return state.currentBreakpoint === Breakpoints.XLarge;
  }

  @Selector()
  static ltSmall(state: BreakpointStateModel) {
    return state.currentBreakpoint === Breakpoints.XSmall;
  }

  @Selector()
  static ltMedium(state: BreakpointStateModel) {
    return [
      Breakpoints.XSmall,
      Breakpoints.Small,
    ].includes(state.currentBreakpoint);
  }

  @Selector()
  static ltLarge(state: BreakpointStateModel) {
    return [
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
    ].includes(state.currentBreakpoint);
  }

  @Selector()
  static ltXLarge(state: BreakpointStateModel) {
    return [
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
    ].includes(state.currentBreakpoint);
  }

  @Selector()
  static gtXSmall(state: BreakpointStateModel) {
    return [
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ].includes(state.currentBreakpoint);
  }

  @Selector()
  static gtSmall(state: BreakpointStateModel) {
    return [
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ].includes(state.currentBreakpoint);
  }

  @Selector()
  static gtMedium(state: BreakpointStateModel) {
    return [
      Breakpoints.Large,
      Breakpoints.XLarge,
    ].includes(state.currentBreakpoint);
  }

  @Selector()
  static gtLarge(state: BreakpointStateModel) {
    return state.currentBreakpoint === Breakpoints.XLarge;
  }

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngxsAfterBootstrap({ patchState, dispatch }: StateContext<BreakpointStateModel>) {
    this.breakpointObserver.observe(Object.values(Breakpoints)).subscribe((result) => {
      for (const [key, value] of Object.entries(result.breakpoints)) {
        if (value === true) {
          patchState({
            currentBreakpoint: key,
            breakpoints: result.breakpoints,
          });

          break;
        }
      }
    });
  }
}
