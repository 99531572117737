import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeasureTypeSelectComponent } from './measure-type-select.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
  ],
  declarations: [
      MeasureTypeSelectComponent,
  ],
  exports: [
	  MeasureTypeSelectComponent,
  ],
})
export class MeasureTypeSelectModule {}
