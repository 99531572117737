import { NgModule } from '@angular/core';
import { SearchFilterComponent } from './search-filter.component';
import { SearchInputModule } from '../search-input/search-input.module';
import { FilterModule } from '@shared/components/filter/filter.module';

@NgModule({
  imports: [
    SearchInputModule,
    FilterModule,
  ],
  declarations: [
    SearchFilterComponent,
  ],
  exports: [
    SearchFilterComponent,
  ],
})
export class SearchFilterModule {}
