import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '@auth/pages/login/login.component';
import { LogoutComponent } from '@auth/pages/logout/logout.component';
import { IsLoggedOutGuard } from '@shared/guards/is-logged-out.guard';
import {IsLoggedInGuard} from '@shared/guards/is-logged-in.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'loginTitle' }, canActivate: [IsLoggedOutGuard] },
  { path: 'logout', component: LogoutComponent, data: { title: 'logoutTitle' }, canActivate: [IsLoggedInGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
