import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { supportsTouch } from '@shared/utils/utils';

@Directive({
  selector: '[safAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
  @Input('safAutofocus')
  get active(): boolean { return this._active; }
  set active(value) { this._active = coerceBooleanProperty(value); }
  private _active: boolean = true;

  @Input('safAutofocusMobile')
  get mobile(): boolean { return this._mobile; }
  set mobile(value) { this._mobile = coerceBooleanProperty(value); }
  private _mobile: boolean = false;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (this.active && (!supportsTouch || this.mobile)) {
      window.setTimeout(() => this.el.nativeElement.focus(), 100);
    }
  }
}
