import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import { ApiService } from '@shared/services/api.service';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { ObservationListItem } from '@shared/models/observation-list-item.model';
import { ObservationDetails } from '@shared/models/observation-details.model';
import { Observation } from '@shared/models/observation.model';
import { ObservationWithMeasures } from '@shared/models/observation-with-measures.model';
import { catchError } from 'rxjs/operators';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class ObservationService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<ObservationListItem>> {
    return this.api.get(`/observation-list`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getAll(params?: object): Observable<ListResponse<Observation>> {
    return this.api.get(`/observations`, params);
  }

  getObservationWithMeasures(params?: object): Observable<ListResponse<ObservationWithMeasures>> {
    return this.api.get(`/observations-with-measures`, params);
  }

  getById(id: number): Observable<Observation> {
    return this.api.get(`/instruction/${id}`);
  }

  getDetails(id: number): Observable<ObservationDetails> {
    return this.api.get(`/observationDetails/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Observation>> {
    return this.api.post(`/observation`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<Observation>> {
    return this.api.put(`/observation/${id}`, data);
  }

  getExportURL(filter: object, sortingParams: object, jwt: string) {
    return `${this.api.apiURL}/observation-list/export?filter=${JSON.stringify(filter)}&${this.api.getSortingStringForExport(sortingParams)}&jwt=${jwt}`;
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/observation/${id}`);
  }

  getPrintURL(id: number, jwt: string): string {
    return `${this.api.apiURL}/observation/${id}/print?jwt=${jwt}`;
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
