import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {FilterPanelModule} from '@shared/components/filter-panel/filter-panel.module';
import {InfoboxDialogComponent} from '@shared/components/infobox-dialog/infobox-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    LoadingOverlayModule,
    FilterPanelModule,
  ],
  declarations: [
    InfoboxDialogComponent,
  ],
  exports: [
    InfoboxDialogComponent,
  ]
})
export class InfoboxDialogModule { }
