<div class="search-bar-container" #container></div>

<ng-template #template>
  <div class="search-bar" [@fadeInOut] (@fadeInOut.done)="focus()">
    <div class="search-bar-content" matAutocompleteOrigin #suggestionsOrigin="matAutocompleteOrigin">
      <button mat-icon-button (click)="close()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <input #input
             matInput
             class="search-bar-input"
             placeholder="{{ 'enterSearchTerm' | translate }}"
             [(ngModel)]="searchText"
             (ngModelChange)="update()"
             (keyup.enter)="submit()"
             [matAutocomplete]="suggestions"
             [matAutocompleteConnectedTo]="suggestionsOrigin">
      <button mat-icon-button (click)="submit()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <mat-autocomplete #suggestions="matAutocomplete" (closed)="onCloseAutocomplete()">
      <mat-optgroup *ngFor="let group of (suggestions$ | async)" [label]="group.label">
        <mat-option *ngFor="let option of group.options" [value]="option.value">{{ option.label }}</mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </div>
</ng-template>
