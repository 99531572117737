import { NgModule } from '@angular/core';
import {FilterModule} from '@shared/components/filter/filter.module';
import {
  HazardAssessmentLawCategorySelectModule
} from '@shared/components/hazard-assessment-law-category-select/hazard-assessment-law-category-select.module';
import {
  HazardAssessmentLawCategoryFilterComponent
} from '@shared/components/hazard-assessment-law-category-filter/hazard-assessment-law-category-filter.component';
import {
  HazardAssessmentTemplateCategorySelectModule
} from '@shared/components/hazard-assessment-template-category-select/hazard-assessment-template-category-select.module';



@NgModule({
  declarations: [
    HazardAssessmentLawCategoryFilterComponent
  ],
  imports: [
    FilterModule,
    HazardAssessmentLawCategorySelectModule,
  ],
  exports: [
    HazardAssessmentLawCategoryFilterComponent
  ]
})
export class HazardAssessmentLawCategoryFilterModule { }
