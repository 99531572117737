import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChecklistAddCategoryDialogComponent} from '@shared/components/checklist-add-category-dialog/checklist-add-category-dialog.component';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FlexModule} from '@angular/flex-layout';



@NgModule({
  declarations: [ChecklistAddCategoryDialogComponent],
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        ReactiveFormsModule,
        FlexModule
    ],
  exports: [ChecklistAddCategoryDialogComponent]
})
export class ChecklistAddCategoryDialogModule { }
