import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {AccidentTypeSelectComponent} from '@shared/components/accident-type-select/accident-type-select.component';



@NgModule({
  declarations: [
    AccidentTypeSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    ReactiveFormsModule
  ],
  exports: [
    AccidentTypeSelectComponent
  ]
})
export class AccidentTypeSelectModule { }
