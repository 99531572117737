export class Address {
  id: number = null;
  countryId: number = 1;
  street: string = '';
  zipCode: string = '';
  city: string = '';

  constructor(config?: Partial<Address>) {
    if (config) {
      Object.keys(config)
        .filter(key => typeof config[key] !== 'undefined')
        .forEach(key => this[key] = config[key]);
    }
  }
}
