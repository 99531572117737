import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {Login, LoginError, LoginSuccess} from '@shared/store/auth/auth.actions';
import {AuthState} from '@shared/store/auth/auth.state';
import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Navigate} from '@ngxs/router-plugin';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslationService} from '@shared/services/translation.service';
import {MatDialog} from '@angular/material/dialog';
import {ForgetPasswordDialogComponent} from '@auth/pages/forget-password-dialog/forget-password-dialog.component';
import {DepartmentService} from '@shared/services/department.service';
import {AssetService} from '@shared/services/asset.service';
import {CaptchaDialogComponent} from '@auth/pages/captcha-dialog/captcha-dialog.component';
import packageJson from '../../../../../package.json';

@Component({
  selector: 'saf-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @Select(AuthState.loading) loading$: Observable<boolean>;
  form: UntypedFormGroup;
  onDestroy$ = new Subject<void>();
  mainMandantLogo = '';
  public activateCaptcha: boolean;
  private countLoginAttempt = 0;
  protected readonly packageJson = packageJson;

  constructor(
    private store: Store,
    private departmentService: DepartmentService,
    private assetService: AssetService,
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.companyLogoUrl();
    }, 200);
    this.form = new UntypedFormGroup({
      login: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
      saveSession: new UntypedFormControl(true),
    }, {updateOn: 'submit'});

    this.actions$.pipe(
      ofActionCompleted(LoginSuccess),
      takeUntil(this.onDestroy$),
    ).subscribe(() => this.onLoginSuccess());

    this.actions$.pipe(
      ofActionCompleted(LoginError),
      takeUntil(this.onDestroy$),
    ).subscribe(({ action }) => this.onLoginError(action.payload));
  }

  onSubmit() {
    if (this.form.valid) {
      if (!localStorage.getItem('activateCaptcha')) {
        this.store.dispatch(new Login(this.form.value));
        this.countLoginAttempt ++;
        if(this.countLoginAttempt > 1) {
          localStorage.setItem('activateCaptcha', 'true');
        }      } else {
        this.dialog.open(CaptchaDialogComponent)
          .afterClosed()
          .pipe(filter((confirmed) => confirmed))
          .subscribe(() => this.store.dispatch(new Login(this.form.value)));
        return;
      }
    } else {
      this.countLoginAttempt ++;
      if(this.countLoginAttempt > 1) {
        localStorage.setItem('activateCaptcha', 'true');
      }
      this.snackBar.open(this.translationService.translate('requiredFields'));
    }
  }

  onLoginSuccess() {
    const redirect = sessionStorage.getItem('redirect');

    if (redirect) {
      sessionStorage.removeItem('redirect');
    }

    this.store.dispatch(new Navigate([redirect || '/']));
  }

  onLoginError({ error }) {
    this.snackBar.open(this.translationService.translate(error || 'loginIncorrect'));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  forgetPasswordDialog() {
    this.dialog.open(ForgetPasswordDialogComponent);
  }

  get avatarURL(): string {
    return this.assetService.getAssetURL(this.mainMandantLogo || '');
  }

  companyLogoUrl() {
    this.departmentService.getMainMandantLogo().subscribe((data) => {
      this.mainMandantLogo = data;
    });
  }

}
