import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalCompanyEmployeeSelectDialogComponent } from './external-company-employee-select-dialog.component';
import {EmptyMessageModule} from "@shared/components/empty-message/empty-message.module";
import {FilterPanelModule} from "@shared/components/filter-panel/filter-panel.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {PipesModule} from "@shared/pipes/pipes.module";
import {MaterialModule} from "@shared/material.module";
import {LoadingSpinnerModule} from "@shared/components/loading-spinner/loading-spinner.module";
import {LoadingOverlayModule} from "@shared/components/loading-overlay/loading-overlay.module";
import {ExternalCompanyFilterModule} from "@shared/components/external-company-filter/external-company-filter.module";



@NgModule({
  declarations: [
    ExternalCompanyEmployeeSelectDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    EmptyMessageModule,
    LoadingSpinnerModule,
    LoadingOverlayModule,
    FilterPanelModule,
    PipesModule,
    ExternalCompanyFilterModule
  ],
  exports: [
    ExternalCompanyEmployeeSelectDialogComponent
  ]
})
export class ExternalCompanyEmployeeSelectDialogModule { }
