<div class="closeCross">
  <span mat-dialog-title>{{ 'supportPortal' | translate }}</span>
  <mat-icon (click)="close()" class="cross">close</mat-icon>
</div>

<h2>{{ 'doYouNeedHelp' | translate }}</h2>

<div class="phone">
  <h3>{{ 'callOurSupportPortal' | translate }}</h3>
  <p>+49 (0) 25 51 - 91 90 801</p>
</div>

<mat-divider></mat-divider>

<div class="mail">
  <h3>{{ 'writeToOurSupportPortal' | translate }}</h3>
  <button mat-raised-button class="administration" color="primary" (click)="mailTo()">
    <mat-icon>mail</mat-icon>
    <span>support@safety-doors.de</span>
  </button>
</div>
<mat-divider></mat-divider>
<div class="portal">
  <h3>{{ 'useOurSupportPortal' | translate }}</h3>
  <button mat-raised-button class="administration" color="primary" (click)="openSupportPortal()">
    <mat-icon>computer</mat-icon>
    <span>{{ 'openSupportPortal' | translate }}</span>
  </button>
  <div class="center-passwordForgot">
    <p><u>{{'Version ' + packageJson.version}}</u></p>
  </div>
</div>
