import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { HazardClassification } from '@shared/models/hazard-classification.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class HazardClassificationService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(groupId: number, params?: object): Observable<ListResponse<HazardClassification>> {
    return this.api.get(`/hazardAssessmentClassifications`, { groupId, ...params });
  }

  getById(id: number): Observable<HazardClassification> {
    return this.api.get(`/hazardAssessmentClassification/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<HazardClassification>> {
    return this.api.post(`/hazardAssessmentClassification`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<HazardClassification>> {
    return this.api.put(`/hazardAssessmentClassification/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/hazardAssessmentClassification/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
