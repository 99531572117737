import { Language } from '@shared/models/language.model';
import { Theme } from '@shared/models/theme.model';

export class LoadSettings {
  static readonly type = '[Settings] Load Settings';
}

export class SaveSettings {
  static readonly type = '[Settings] Save Settings';
}

export class SetSettings {
  static readonly type = '[Settings] Set Settings';
  constructor(public payload: { language: Language, theme: Theme }) {}
}
