import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {LanguageSelectDialogComponent} from '@shared/components/language-select-dialog/language-select-dialog.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {EmptyMessageModule} from '@shared/components/empty-message/empty-message.module';
import {LoadingSpinnerModule} from '@shared/components/loading-spinner/loading-spinner.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {FilterPanelModule} from '@shared/components/filter-panel/filter-panel.module';



@NgModule({
  declarations: [
    LanguageSelectDialogComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        PipesModule,
        FlexLayoutModule,
        EmptyMessageModule,
        LoadingSpinnerModule,
        LoadingOverlayModule,
        FilterPanelModule,
    ],
  exports: [
    LanguageSelectDialogComponent
  ]
})
export class LanguageSelectDialogModule { }
