import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileCategoryFilterComponent } from './file-category-filter.component';
import {FileCategorySelectModule} from "@shared/components/file-category-select/file-category-select.module";



@NgModule({
  declarations: [
    FileCategoryFilterComponent
  ],
  exports: [
    FileCategoryFilterComponent
  ],
  imports: [
    CommonModule,
    FileCategorySelectModule
  ]
})
export class FileCategoryFilterModule { }
