import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepartmentDialogComponent } from './department-dialog.component';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        LoadingOverlayModule,
    ],
    declarations: [
        DepartmentDialogComponent,
    ],
    exports: [
        DepartmentDialogComponent,
    ]
})
export class DepartmentDialogModule {}
