<form [formGroup]="formGroup" (ngSubmit)="submitForm()">
  <mat-card class="login-card">
    <mat-card-header class="no-text-margin">
      <h3>{{ 'terminalLogin' | translate }}</h3>
    </mat-card-header>
    <mat-card-content class="block-fields">
      <saf-terminal-select formControlName="terminalId" required></saf-terminal-select>
      <mat-form-field appearance="outline" floatLabel>
        <mat-label>{{ 'password' | translate }}</mat-label>
        <input type="password" formControlName="password" matInput required autocomplete="off">
      </mat-form-field>
      <div fxLayoutAlign="end">
        <button class="administration saveButtonHover" mat-raised-button type="submit" color="primary">{{ 'login' | translate }}</button>
      </div>
    </mat-card-content>
  </mat-card>
</form>
