import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { InstructionRefreshDialogComponent } from '@shared/components/instruction-refresh-dialog/instruction-refresh-dialog.component';
import { HazardMeasureSelectModule } from '@shared/components/hazard-measure-select/hazard-measure-select.module';
import { HazardAssessmentSelectModule } from '@shared/components/hazard-assessment-select/hazard-assessment-select.module';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        PipesModule,
        HazardAssessmentSelectModule,
        HazardMeasureSelectModule,
        LoadingOverlayModule,
    ],
    declarations: [
        InstructionRefreshDialogComponent,
    ],
    exports: [
        InstructionRefreshDialogComponent,
    ]
})
export class InstructionRefreshDialogModule {}
