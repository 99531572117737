<mat-sidenav-container fullscreen (backdropClick)="toggleSidenav()">
  <mat-sidenav class="saf-sidenav"
               [attr.role]="(smallViewport$ | async) ? 'dialog' : 'navigation'"
               [mode]="(smallViewport$ | async) ? 'over' : 'side'"
               [opened]="sidenavOpened">
<!--    <saf-user-panel [ngStyle]="{'background': this.color}"></saf-user-panel>-->
    <div class="side-nav-header" [ngStyle]="{'background': this.color}">
      <button  class="user-notifications" mat-icon-button (click)="toggleSidenav()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <saf-navigation-with-child></saf-navigation-with-child>
  </mat-sidenav>
  <mat-sidenav-content>
    <saf-header [headerColor]="this.color"></saf-header>
    <main [class]="mobileViewport? 'main-mobile' : 'main'">
      <router-outlet></router-outlet>
    </main>
<!--    <saf-footer *ngIf="mobileViewport"></saf-footer>-->
  </mat-sidenav-content>
</mat-sidenav-container>
