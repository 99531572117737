import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LanguageSelectionComponent} from '@shared/components/language-selection/language-selection.component';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';



@NgModule({
  declarations: [
    LanguageSelectionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule
  ],
  exports: [
    LanguageSelectionComponent
  ]
})
export class LanguageSelectionModule { }
