import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChecklistAddExecutionDialogComponent} from '@shared/components/checklist-add-execution-dialog/checklist-add-execution-dialog.component';
import {EmployeeSelectModule} from '@shared/components/employee-select/employee-select.module';
import {CompanySelectModule} from '@shared/components/company-select/company-select.module';
import {DateSelectModule} from '@shared/components/date-select/date-select.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {ChecklistSelectModule} from '@shared/components/checklist-select/checklist-select.module';
import {DepartmentSelectModule} from '@shared/components/department-select/department-select.module';
import {FlexModule} from '@angular/flex-layout';



@NgModule({
    declarations: [
        ChecklistAddExecutionDialogComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        EmployeeSelectModule,
        CompanySelectModule,
        DateSelectModule,
        ReactiveFormsModule,
        PipesModule,
        ChecklistSelectModule,
        DepartmentSelectModule,
        FlexModule,
    ],
    exports: [
        ChecklistAddExecutionDialogComponent
    ]
})
export class ChecklistAddExecutionDialogModule { }
