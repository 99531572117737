import { NgModule } from '@angular/core';
import { FilterComponent } from '@shared/components/filter/filter.component';

@NgModule({
  declarations: [
    FilterComponent,
  ],
  exports: [
    FilterComponent,
  ]
})
export class FilterModule {}
