import { NgModule } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../pipes/pipes.module';
import { EmployeeDialogModule } from '../employee-dialog/employee-dialog.module';
import { EmployeeQuickViewCardComponent } from './employee-quick-view-card/employee-quick-view-card.component';
import { EmployeeQuickViewDirective } from './employee-quick-view.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        PipesModule,
        EmployeeDialogModule,
    ],
    declarations: [
        EmployeeQuickViewCardComponent,
        EmployeeQuickViewDirective,
    ],
    exports: [
        EmployeeQuickViewCardComponent,
        EmployeeQuickViewDirective,
    ]
})
export class EmployeeQuickViewModule {}
