import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {filter, mergeMap, take, tap} from 'rxjs/operators';

@Injectable()
export class RequestLimitInterceptor implements HttpInterceptor {

  private readonly timeFrame = 1000 ;

  private availableThreads = 8;

  private requestLimit = new BehaviorSubject(this.availableThreads);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.requestLimit.pipe(
      filter(() => this.availableThreads > 0),
      take(1),
      tap(() => this.removeThreads()),
      tap(() => timer(this.timeFrame).subscribe(() => this.addThreads())),
      mergeMap(() => next.handle(req))
    );
  }

  private addThreads(): void {
    this.changeThreads(1);
  }

  private removeThreads(): void {
    this.changeThreads(-1);
  }

  private changeThreads(value: number): void {
    this.requestLimit.next(this.availableThreads += value);
  }
}
