import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '@shared/services/auth.service';
import {tap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslationService} from '@shared/services/translation.service';
import {SuccessResponse} from '@shared/models/response.model';

@Component({
  selector: 'saf-forget-password-dialog',
  templateUrl: './forget-password-dialog.component.html',
  styleUrls: ['./forget-password-dialog.component.scss']
})
export class ForgetPasswordDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;
  loading: boolean;

  constructor(
    private dialogRef: MatDialogRef<ForgetPasswordDialogComponent>,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
  ) { }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      mail: new UntypedFormControl(),
    });
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    if (this.formGroup.valid) {
      this.loading = true;
      this.authService.forgetPassword(this.formGroup.getRawValue())
        .pipe(tap(() => this.loading = false))
        .subscribe( (response) => this.messageHandling(response),
          error => this.loading = false);
    }
  }

  messageHandling(response: SuccessResponse) {
    if (response.success) {
      this.snackBar.open(this.translationService.translate('yourLoginDataHasBeenSend'));
      this.dialogRef.close();
    } else {
      if (response.failureReason === '') {
        this.snackBar.open(this.translationService.translate('mailSettingErrorActive'));
      } else {
        this.snackBar.open(this.translationService.translate(response.failureReason));
      }
    }
  }

}
