<div class="closeCross">
  <span mat-dialog-title>{{ ('language') | translate }}</span>
  <mat-icon (click)="cancel()" class="cross">close</mat-icon>
</div>

<section fxLayout="row" fxLayoutGap="16px">
  <saf-filter-panel [placeholder]="('enterSearchTerm' | translate)" (filterChange)="applyDrawerFilters($event)"
                    [isDialog]="true">
  </saf-filter-panel>
</section>

<mat-dialog-content>
  <mat-divider></mat-divider>
  <div class="dialog-table-container" [fxShow]="dataSource.data.length">
    <table mat-table [dataSource]="dataSource" id="table" class="table" matSort matSortActive="name" matSortDirection="asc">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-slide-toggle (click)="$event.stopPropagation()"
                            (change)="$event ? selectedRow(row, $event) : null"
                            [checked]="isSelected(row)">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th class="titleFont" mat-header-cell *matHeaderCellDef disableClear mat-sort-header="name">{{ 'title' | translate }}</th>
        <td class="paddingLeft" mat-cell *matCellDef="let data">
          <mat-icon svgIcon="{{data.acronym}}" [ngStyle]="{'color': 'black'}" class="checkbox-icon"></mat-icon>
          <span>{{ data.name }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="checkPageSize()" [pageSizeOptions]="pageSizeOptions"
                   [length]="totalCount" [showFirstLastButtons]="true" (page)="pageChange($event)"></mat-paginator>
  </div>
  <saf-empty-message *ngIf="!dataSource.data.length && !loading" (reloadClicked)="reloadTable()"></saf-empty-message>


</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button mat-raised-button class="administration deleteButtonHover" color="primary"
          (click)="cancel()">{{ 'cancel' | translate }}
  </button>
  <button mat-raised-button class="administration saveButtonHover" (click)="submit()"
          color="primary">{{ 'save' | translate }}
  </button>
</div>
