import { NgModule } from '@angular/core';
import {
  InstructionPatternSelectDialogModule
} from '@shared/components/instruction-pattern-select-dialog/instruction-pattern-select-dialog.module';
import {InstructionPatternSelectComponent} from '@shared/components/instruction-pattern-select/instruction-pattern-select.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';

@NgModule({
  declarations: [
    InstructionPatternSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    InstructionPatternSelectDialogModule
  ],
  exports: [
    InstructionPatternSelectComponent
  ],
})
export class InstructionPatternSelectModule { }
