import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AccidentWitnessAddDialogComponent} from '@shared/components/accident-witness-add-dialog/accident-witness-add-dialog.component';
import {MaterialModule} from '@shared/material.module';
import {EmployeeSelectModule} from '@shared/components/employee-select/employee-select.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {ReactiveFormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    AccidentWitnessAddDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    EmployeeSelectModule,
    LoadingOverlayModule,
    PipesModule,
    ReactiveFormsModule
  ],
  exports: [
    AccidentWitnessAddDialogComponent
  ]
})
export class AccidentWitnessAddDialogModule { }
