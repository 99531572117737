import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentFilterComponent } from './equipment-filter.component';
import {FormsModule} from "@angular/forms";
import {MaterialModule} from "@shared/material.module";
import {PipesModule} from "@shared/pipes/pipes.module";
import {FilterModule} from "@shared/components/filter/filter.module";
import {
  EquipmentCategorySelectModule
} from "@shared/components/equipment-category-select/equipment-category-select.module";
import {EquipmentSelectModule} from "@shared/components/equipment-select/equipment-select.module";
import {AreaLocationSelectModule} from "@shared/components/area-location-select/area-location-select.module";



@NgModule({
  declarations: [
    EquipmentFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    FilterModule,
    EquipmentCategorySelectModule,
    EquipmentSelectModule,
    AreaLocationSelectModule
  ],
  exports: [
    EquipmentFilterComponent
  ]
})
export class EquipmentFilterModule { }
