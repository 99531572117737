import { NgModule } from '@angular/core';
import { MaterialModule } from '@shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EmployeeCategorySelectComponent } from '@shared/components/employee-category-select/employee-category-select.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        PipesModule,
        FlexLayoutModule,
    ],
  declarations: [
    EmployeeCategorySelectComponent,
  ],
  exports: [
    EmployeeCategorySelectComponent,
  ],
})
export class EmployeeCategorySelectModule {}
