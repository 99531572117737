import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { Cacheable } from 'ts-cacheable';
import { ListResponse } from '@shared/models/response.model';
import { Permission } from '@shared/models/permission.model';
import { Store } from '@ngxs/store';
import { UpdatePermissions } from '../store/auth/auth.actions';
import { AuthState } from '../store/auth/auth.state';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
      private api: ApiService,
      private store: Store
  ) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<Permission>> {
    return this.api.get(`/permissions`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getByUserId(userId: number, params?: object): Observable<ListResponse<Permission>> {
    return this.api.get(`/userPermissions`, { userId, ...params });
  }

  hasPermission(permission?: string): boolean {
	  let permissions = localStorage.getItem('permissions');
    if (!permissions) {
      this.store.dispatch(new UpdatePermissions({userId: this.store.selectSnapshot(AuthState.user).id }));
      permissions = localStorage.getItem('permissions');
      return JSON.parse(permissions).includes(permission);
    } else {
      return JSON.parse(permissions).includes(permission);

    }
  }



  static clearCache() {
    cacheNotifier.next();
  }
}
