import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { CountrySelectComponent } from '@shared/components/country-select/country-select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
  ],
  declarations: [
    CountrySelectComponent,
  ],
  exports: [
    CountrySelectComponent,
  ],
})
export class CountrySelectModule {}
