import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CostCenterSelectDialogComponent} from '@shared/components/cost-center-select-dialog/cost-center-select-dialog.component';
import {MaterialModule} from '@shared/material.module';
import {FormsModule} from '@angular/forms';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {EmptyMessageModule} from '@shared/components/empty-message/empty-message.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {LoadingSpinnerModule} from '@shared/components/loading-spinner/loading-spinner.module';
import {TreeModule} from '@circlon/angular-tree-component';
import {SearchFilterModule} from '@shared/components/search-filter/search-filter.module';
import {FilterPanelModule} from '@shared/components/filter-panel/filter-panel.module';



@NgModule({
  declarations: [CostCenterSelectDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    PipesModule,
    FlexModule,
    EmptyMessageModule,
    LoadingOverlayModule,
    LoadingSpinnerModule,
    TreeModule,
    SearchFilterModule,
    FlexLayoutModule,
    FilterPanelModule
  ],
  exports: [CostCenterSelectDialogComponent]
})
export class CostCenterSelectDialogModule { }
