<mat-card class="widget">
  <mat-card-header [ngStyle]="{'background': moduleColor.SCHOOLING, 'color': 'white'}" class="widget-header no-text-margin">
    <h3 class="widget-title">{{ 'myOpenSchoolings' | translate }}</h3>
    <span class="spacer"></span>
    <div class="widget-header-actions">
      <ng-content></ng-content>
      <button mat-icon-button (click)="reload()" matTooltip="{{ 'reload' | translate }}">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content class="widget-content">
    <div [fxShow]="dataSource.data.length">
      <table mat-table [dataSource]="dataSource" id="table" class="table" matSort>

        <ng-container matColumnDef="topicName">
          <th mat-header-cell *matHeaderCellDef>{{ 'topic' | translate }}</th>
          <td mat-cell *matCellDef="let state">
            <span [matTooltip]="state.topic.name">{{ state.topic.name }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
          <td mat-cell *matCellDef="let state" [ngSwitch]="state.plan.status">
            <div *ngSwitchCase="0" class="status-color yellow" matTooltip="{{ 'open' | translate }}"></div>
            <div *ngSwitchCase="1" class="status-color green" matTooltip="{{ 'done' | translate }}"></div>
            <div *ngSwitchCase="2" class="status-color red" matTooltip="{{ 'overdue' | translate }}"></div>
            <div *ngSwitchCase="3" class="status-color grey" matTooltip="{{ 'blocked' | translate }}"></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="intervalUntil">
          <th mat-header-cell *matHeaderCellDef>{{ 'dueUntil' | translate }}</th>
          <td mat-cell *matCellDef="let state">
            <span [matTooltip]="getDate(state) | date">{{ getDate(state) | date}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>{{ 'action' | translate }}</th>
          <td mat-cell *matCellDef="let state">
            <button mat-icon-button (click)="openOnline(state)"
                    matTooltip="{{ 'startSchooling' | translate }}">
              <mat-icon class="play_circle_outline">play_circle</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
        <tr mat-row class="selectable" *matRowDef="let row; columns: tableColumns;"></tr>
      </table>
    </div>
    <saf-empty-message *ngIf="!dataSource.data.length && !loading" (reloadClicked)="reload()"></saf-empty-message>
  </mat-card-content>

</mat-card>


<div style="margin-top: 16px" fxLayoutAlign="end">
  <button type="submit" class="administration deleteButtonHover" mat-raised-button color="primary" (click)="onClickNext()">{{ 'close' | translate }}</button>
</div>
