import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AccidentCauseService } from '@shared/services/accident-cause.service';
import { filter, tap } from 'rxjs/operators';
import { AccidentCause } from '@shared/models/accident-cause.model';
import { ConfirmDialogComponent, ConfirmDialogConfig } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { TranslationService } from '@shared/services/translation.service';
import { AccidentCauseDialogComponent } from '@frontend/modules/accident-cause/accident-cause-dialog/accident-cause-dialog.component';
import {PermissionService} from '@shared/services/permission.service';
import {checkPageSize, DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS, pageChange} from '@shared/utils/utils';
import {merge} from "rxjs";
import {PermissionKeys} from "@shared/models/permission.model";
@Component({
  selector: 'saf-accident-cause-list',
  templateUrl: './accident-cause-list.component.html',
  styleUrls: ['./accident-cause-list.component.scss']
})
export class AccidentCauseListComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    public tableColumns = ['name', 'editable', 'options'];
    public dataSource = new MatTableDataSource<AccidentCause>();
    public loading: boolean = false;
    public trackById = (item) => item.id;
    public drawerFilterParams: any = {};
    public searchFilterParams: any = {};
    public totalCount = 0;
    public hasPermission = false;

  constructor(
    private accidentCauseService: AccidentCauseService,
    private translationService: TranslationService,
    private changeDetectorRef: ChangeDetectorRef,
    private permissionService: PermissionService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    @Inject(PAGE_SIZE_OPTIONS) public pageSizeOptions,
    @Inject(DEFAULT_PAGE_SIZE) public pageSize,
  ) { }

  ngOnInit(): void {
    this.hasPermission = this.permissionService.hasPermission(PermissionKeys.ACCIDENT_COMPLETE_EDIT);
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page).subscribe(() => this.loadTable());
  }

  ngAfterViewInit() {
    this.loadTable();
    this.changeDetectorRef.detectChanges();
  }

  loadTable() {
    const params = {
      filter: {
        ...this.searchFilterParams,
        ...this.drawerFilterParams,
      },
      sort: this.sort.direction ? this.sort.active : '',
      dir: this.sort.direction,
      start: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize,
    };

    this.loading = true;

    this.accidentCauseService.getList(params)
      .pipe(tap(() => this.loading = false))
      .subscribe((response) => {
        this.dataSource.data = response.data;
        this.totalCount = response.total;
      });
  }

  reloadTable() {
    AccidentCauseService.clearCache();
    this.loadTable();
  }

  openDialog(data: AccidentCause = null) {
    if (this.hasPermission) {
      this.dialog.open(AccidentCauseDialogComponent, { data })
        .afterClosed()
        .pipe(filter((success) => success))
        .subscribe(() => this.loadTable());
    } else {
      this.returnPermissionError();
    }
  }

  openDeleteDialog(e, accidentCause: AccidentCause) {
    e.stopPropagation();
    if (!accidentCause.editAble) {
      return this.snackBar.open(this.translationService.translate('notDeletable'));
    }

    if (this.hasPermission) {
      const data = new ConfirmDialogConfig({
        title: this.translationService.translate('confirmDeleteTitle'),
        message: this.translationService.translate('confirmDeleteAccidentCause'),
        confirmText: this.translationService.translate('confirm'),
      });

      this.dialog.open(ConfirmDialogComponent, {data})
        .afterClosed()
        .pipe(filter((confirmed) => confirmed))
        .subscribe((confirmed) => {
          if (confirmed) {
            this.accidentCauseService.delete(accidentCause.id)
              .subscribe(() => this.loadTable());
          }
        });

    } else {
      this.returnPermissionError();
    }
  }

  applyDrawerFilters(filterParams) {
    this.drawerFilterParams = filterParams;
    this.paginator.pageIndex = 0;
    this.loadTable();
  }

  applySearchFilter({ param, value }) {
    this.dataSource.filter = value;
  }

  openUpdateDialog(e, item: AccidentCause) {
    e.stopPropagation();
    if (item.editAble) {
      this.openDialog(item);
    } else {
      this.snackBar.open(this.translationService.translate('notEditable'));
    }
  }

  openCreateDialog() {
    this.openDialog();
  }

  returnPermissionError() {
    return  this.snackBar.open(this.translationService.translate('permissionError'));
  }

    protected readonly pageChange = pageChange;
  protected readonly checkPageSize = checkPageSize;
}
