import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordResetDialogComponent } from '@shared/components/password-reset-dialog/password-reset-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        PasswordResetDialogComponent,
    ],
    exports: [
        PasswordResetDialogComponent,
    ]
})
export class PasswordResetDialogModule {}
