import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {Language} from '@shared/models/language.model';
import {LanguageService} from '@shared/services/language.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TranslationService} from '@shared/services/translation.service';
import {filter, tap} from 'rxjs/operators';
import {LanguageSelectDialogComponent} from '@shared/components/language-select-dialog/language-select-dialog.component';
import {checkFilter, killEvent} from '@shared/utils/utils';

@Component({
  selector: 'saf-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageSelectComponent),
      multi: true
    }
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectComponent implements OnInit, AfterViewInit {
  _valueText: string = '';
  @Input() standardValue: number = null;

  private language: Language;

  @Input()
  get translated(): boolean {
    return this._translated;
  }

  set translated(value: boolean) {
    this._translated = coerceBooleanProperty(value);
  }

  private _translated = false;

  @Input()
  get onlyId(): boolean {
    return this._onlyId;
  }

  set onlyId(value: boolean) {
    this._onlyId = coerceBooleanProperty(value);
  }

  private _onlyId = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disabled = false;

  @Input()
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }

  private _multiple = false;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
  }

  private _placeholder: string;

  @Input()
  get appearance(): MatFormFieldAppearance {
    return this._appearance;
  }

  set appearance(value: MatFormFieldAppearance) {
    this._appearance = value;
  }

  private _appearance: MatFormFieldAppearance = 'outline';

  @Input()
  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this.writeValue(value);
  }

  private _value: any = null;

  get loading(): boolean {
    return this._loading;
  }

  private _loading: boolean = false;

  @Input()
  get valueField(): string | null {
    return this._valueField;
  }

  set valueField(value: string | null) {
    this._valueField = value;
  }

  private _valueField: string | null = null;

  @Input()
  get isFilter(): boolean {
    return this._isFilter;
  }

  set isFilter(value: boolean) {
    this._isFilter = coerceBooleanProperty(value);
  }

  private _isFilter = false;

  get showClearButton(): boolean {
    return this.hasValue && !this.disabled && (this.multiple || !this.required);
  }

  get hasValue(): boolean {
    return this.multiple ? this.value && this.value.length : this.value !== null;
  }

  get hasNoValue(): boolean {
    if (this._valueText === '' || this.value === null) {
      return true;
    }
  }

  @Output() valueChange = new EventEmitter<any>();
  @Input() listName = '';

  onChange: Function = () => {
  };
  onTouched: Function = () => {
  };

  constructor(
    private languageService: LanguageService,
    private dialog: MatDialog,
    private translationService: TranslationService
  ) {
  }

  ngOnInit() {
    if (this.standardValue && !this.value) {
      this.loadById(this.standardValue);
    }
    this.checkSavedFilter();
  }

  checkSavedFilter() {
    if (this._isFilter && checkFilter('languageId', this.listName)) {
      this.writeValue(checkFilter('languageId', this.listName));
      // this.value = this.multiple ? [this.language].map(language => language.id) : [this.language][0].id;
      // this.language = [this.language][0];
      // this._valueText = this.multiple ? [this.language].map((language) => language.name).join(', ') : [this.language][0].name;
    }
  }

  ngAfterViewInit() {
    if (this.value) {
      this.value.id ? this.loadById(this.value.id) : this.loadById(this.value);
    }
  }

  loadById(id?: number) {
    if (id) {
      this._loading = true;
      this.disabled = true;

      this.languageService.getById(id)
        .pipe(tap(() => {
          this._loading = false;
          this.disabled = false;

        }))
        .subscribe((response) => {
          this._valueText = response.name;
          this.language = response;
          if (this.standardValue && !this.value) {
            this.value = response;
          }
        });
    }
  }

  openLanguageSelectDialog(e) {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'table-dialog',
      disableClose: false,
      data: {
        multiple: this.multiple,
        selected: this.language ? [this.language] : null
      }
    };

    this.dialog.open(LanguageSelectDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(filter((result) => result))
      .subscribe((result) => {
        if (this._onlyId) {
          this.value = this.multiple
            ? result.map(language => language.id)
            : result[0].id;
        } else {
          this.value = this.multiple
            ? result.map(language => language)
            : result[0];
        }
        this.language = result[0];
        this._valueText = this.multiple
          ? result.map((language) => language.name).join(', ')
          : result[0].name;
      });

    killEvent(e);
  }

  registerOnChange(onChange: Function) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.valueChange.emit(value);
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  clear() {
    this.value = null;
    this._valueText = null;
    this.language = null;
  }

  onClickClear(event) {
    this.clear();

    event.stopImmediatePropagation();
  }
}
