import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { DataResponse, SuccessResponse } from '@shared/models/response.model';
import { ExternalPresence } from '@shared/models/external-presence.model';

@Injectable({
  providedIn: 'root'
})
export class ExternalPresenceService {

  constructor(private api: ApiService) {}

  getById(id): Observable<ExternalPresence> {
    return this.api.get(`/external/presence/${id}`);
  }

  create(data: object): Observable<DataResponse<ExternalPresence>> {
    return this.api.post(`/external/presence`, data);
  }

  update(id: number, data: object): Observable<DataResponse<ExternalPresence>> {
    return this.api.put(`/external/presence/${id}`, data);
  }

  updateByExternalEmployeeId(id: number, data: object): Observable<DataResponse<ExternalPresence>> {
    return this.api.put(`/external/presence/externalEmployee/${id}`, data);
  }

  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/external/presence/${id}`);
  }

  signOutAllExternalEmployee(): Observable<SuccessResponse> {
    return this.api.put(`/external/presences/signOut`);
  }
}
