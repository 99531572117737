import { NgModule } from '@angular/core';
import { MaterialModule } from '@shared/material.module';
import { LoadingOverlayComponent } from '@shared/components/loading-overlay/loading-overlay.component';
import {AsyncPipe, NgIf} from '@angular/common';

@NgModule({
    imports: [
        MaterialModule,
        AsyncPipe,
        NgIf,
    ],
  declarations: [
    LoadingOverlayComponent,
  ],
  exports: [
    LoadingOverlayComponent,
  ],
})
export class LoadingOverlayModule {}
