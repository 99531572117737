import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HazardMeasureSelectComponent } from './hazard-measure-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { HazardMeasureDialogModule } from '@shared/components/hazard-measure-dialog/hazard-measure-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    HazardMeasureDialogModule,
  ],
  declarations: [
    HazardMeasureSelectComponent,
  ],
  exports: [
    HazardMeasureSelectComponent,
  ],
})
export class HazardMeasureSelectModule {}
