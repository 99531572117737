import { NgModule } from '@angular/core';
import {ModuleFilterComponent} from './module-filter.component';
import {FilterModule} from '@shared/components/filter/filter.module';
import {ModuleSelectModule} from '@shared/components/module-select/module-select.module';



@NgModule({
  declarations: [ModuleFilterComponent],
  imports: [
    FilterModule,
    ModuleSelectModule
  ],
  exports: [ModuleFilterComponent]
})
export class ModuleFilterModule { }
