import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {YearSelectComponent} from '@shared/components/year-select/year-select.component';
import {MatInputModule} from '@angular/material/input';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FormsModule} from '@angular/forms';



@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MaterialModule,
    PipesModule,
    FormsModule
  ],
  declarations: [
    YearSelectComponent,
  ],
  exports: [
    YearSelectComponent,
  ]
})
export class YearSelectModule { }
