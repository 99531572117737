import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import { DataResponse, ListResponse, SuccessResponse } from '@shared/models/response.model';
import { ExternalCompanyCategory } from '@shared/models/external-company-category.model';
import {Topic} from "../models/topic.model";

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class ExternalCompanyCategoryService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getAll(params?: object): Observable<ListResponse<ExternalCompanyCategory>> {
    return this.api.get(`/external/categories`, params);
  }

  getById(id): Observable<ExternalCompanyCategory> {
    return this.api.get(`/external/category/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<ExternalCompanyCategory>> {
    return this.api.post(`/external/category`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<ExternalCompanyCategory>> {
    return this.api.put(`/external/category/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/external/category/${id}`);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getListTopics(id: number, params?: object): Observable<ListResponse<Topic>> {
    return this.api.get(`/external/category/${id}/requiredTopics`, params);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  updateTopics(id: number, data: object): Observable<SuccessResponse> {
    return this.api.put(`/external/category/${id}/requiredTopics`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  deleteTopic(id: number, data: object): Observable<SuccessResponse> {
    return this.api.delete(`/external/category/${id}/requiredTopic`, data);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
