import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Logout } from '@shared/store/auth/auth.actions';
import { Navigate } from '@ngxs/router-plugin';
import {EmployeeService} from '@shared/services/employee.service';
import {DepartmentService} from '@shared/services/department.service';
import {AuditService} from '@shared/services/audit.service';
import {ObservationService} from '@shared/services/observation.service';
import {InspectionService} from '@shared/services/inspection.service';
import {HazardAssessmentService} from '@shared/services/hazard-assessment.service';
import {InstructionService} from '@shared/services/instruction.service';
import {AccidentService} from '@shared/services/accident.service';
import {MeasureService} from '@shared/services/measure.service';
import {ChecklistService} from '@shared/services/checklist.service';
import {ExternalCompanyService} from '@shared/services/external-company.service';

@Component({
  selector: 'saf-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private store: Store) {}

  ngOnInit() {
    EmployeeService.clearCache();
    DepartmentService.clearCache();
    AuditService.clearCache();
    ObservationService.clearCache();
    InspectionService.clearCache();
    HazardAssessmentService.clearCache();
    InstructionService.clearCache();
    AccidentService.clearCache();
    MeasureService.clearCache();
    ChecklistService.clearCache();
    ExternalCompanyService.clearCache();
    this.store.dispatch(new Logout())
      .subscribe(() => this.store.dispatch(new Navigate(['/'])));
  }
}
