import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FilterPanelModule} from '@shared/components/filter-panel/filter-panel.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {
  InstructionPatternSelectDialogComponent
} from '@shared/components/instruction-pattern-select-dialog/instruction-pattern-select-dialog.component';
import {InstructionTypeFilterModule} from '@shared/components/instruction-type-filter/instruction-type-filter.module';
import {
  HazardAssessmentTemplateStateFilterModule
} from '@shared/components/hazard-assessment-template-state-filter/hazard-assessment-template-state-filter.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {EmptyMessageModule} from '@shared/components/empty-message/empty-message.module';
import {LoadingSpinnerModule} from '@shared/components/loading-spinner/loading-spinner.module';

@NgModule({
    declarations: [
        InstructionPatternSelectDialogComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        FilterPanelModule,
        InstructionTypeFilterModule,
        HazardAssessmentTemplateStateFilterModule,
        LoadingOverlayModule,
        FlexLayoutModule,
        EmptyMessageModule,
        LoadingSpinnerModule
    ],
    exports: [
        InstructionPatternSelectDialogComponent
    ]
})
export class InstructionPatternSelectDialogModule { }
