export class LoadTerminals {
  static readonly type = '[Terminal] Load Terminals';
}

export class CreateTerminal {
  static readonly type = '[Terminal] Create Terminal';
  constructor(public payload: any) {}
}

export class UpdateTerminal {
  static readonly type = '[Terminal] Update Terminal';
  constructor(public id: number, public payload: any) {}
}

export class DeleteTerminal {
  static readonly type = '[Terminal] Delete Terminal';
  constructor(public id: number) {}
}
