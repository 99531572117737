  <div class="closeCross">
    <span mat-dialog-title>{{ ('equipmentCategories') | translate }}</span>
    <mat-icon (click)="closeDialog($event)" class="cross">close</mat-icon>
  </div>

  <section fxLayout="row" fxLayoutGap="16px">
    <button *ngIf="!filter" class="administration" mat-raised-button color="primary" (click)="openDialog()">
      <mat-icon>add</mat-icon>
      <span>{{ 'add' | translate }}</span>
    </button>
    <saf-filter-panel [placeholder]="('enterSearchTerm' | translate)" (filterChange)="applyDrawerFilters($event)"
                      [isDialog]="true"></saf-filter-panel>
  </section>

  <mat-dialog-content>
    <mat-divider></mat-divider>
    <div class="dialog-table-container" [fxShow]="dataSource.data.length">
      <table mat-table [dataSource]="dataSource" id="table" class="table" matSort matSortDirection="asc" matSortActive="name" [trackBy]="trackById">

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-slide-toggle *ngIf="multiple" (change)="$event ? toggleAll() : null"
                              [checked]="isAllSelected()">
            </mat-slide-toggle>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-slide-toggle (click)="$event.stopPropagation()"
                              (change)="$event ? selectedRow(row, $event) : null"
                              [checked]="isSelected(row)">
            </mat-slide-toggle>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef disableClear mat-sort-header="name">{{ 'title' | translate }}</th>
          <td mat-cell *matCellDef="let equipmentCategory">
            <span [matTooltip]="equipmentCategory.name">{{ equipmentCategory.name }}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
        <tr mat-row class="selectable" *matRowDef="let row; columns: tableColumns;"></tr>
      </table>
      <mat-paginator class="mat-paginator-sticky" [pageSize]="checkPageSize()" [pageSizeOptions]="pageSizeOptions"
                     [length]="totalCount" [showFirstLastButtons]="true" (page)="pageChange($event)"></mat-paginator>
    </div>
    <saf-empty-message *ngIf="!dataSource.data.length && !loading" (reloadClicked)="reloadTable()"></saf-empty-message>

  </mat-dialog-content>

<div mat-dialog-actions align="end">
  <button mat-raised-button class="administration deleteButtonHover" color="primary" (click)="cancel()">{{ 'cancel' | translate }}</button>
  <button mat-raised-button class="administration saveButtonHover"  (click)="submit()" color="primary">{{ 'save' | translate }}</button>
</div>
