import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { FilterChange } from '@shared/models/filter-change.model';
import { FilterComponent } from '@shared/components/filter/filter.component';
import {DepartmentSelectComponent} from '@shared/components/department-select/department-select.component';

@Component({
  selector: 'saf-company-filter',
  templateUrl: './company-filter.component.html',
  styleUrls: ['./company-filter.component.scss'],
  host: { 'class': 'filter' },
  providers: [{
    provide: FilterComponent,
    useExisting: forwardRef(() => CompanyFilterComponent),
  }]
})
export class CompanyFilterComponent {
  @ViewChild(DepartmentSelectComponent) companySelect: DepartmentSelectComponent;
  @Output() valueChange = new EventEmitter<FilterChange>();
  @Output() valueReset = new EventEmitter<string>();
  @Input() filterParam: string = 'companyId';
  @Input() listName = '';
  @Input() placeholder: string;
  @Input() multiple: boolean = false;

  onChange(id: number | number[]) {
    this.valueChange.emit({
      param: this.filterParam,
      value: id,
    });
  }

  clear() {
    this.companySelect.clear();
    this.valueReset.emit(this.filterParam);
  }
}
