<mat-form-field [appearance]="appearance">
  <mat-label>{{ placeholder || ((multiple ? 'externalCompanyCategories' : 'externalCompanyCategory') | translate) }}</mat-label>
  <mat-select [(ngModel)]="value"
              [required]="required"
              [disabled]="disabled || (loading$ | async)"
              [multiple]="multiple"
              [disableOptionCentering]="true">
    <mat-option *ngIf="(!multiple && !required)" [value]="null">{{ 'cancelSelection' | translate }}</mat-option>
    <mat-option *ngFor="let externalCompanyCategory of (externalCompanyCategories$ | async)" [value]="externalCompanyCategory.id">{{ externalCompanyCategory.name }}</mat-option>
  </mat-select>
  <button mat-button *ngIf="showClearButton" [disabled]="disabled" type="button" matSuffix mat-icon-button (click)="onClickClear($event)">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
