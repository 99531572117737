import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {finalize, tap} from 'rxjs/operators';
import {AppConfigService} from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  constructor(private http: HttpClient, private appConfigService: AppConfigService) { }

  initializeApp(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.appConfigService.load()
        .pipe(
          tap(() => {
            resolve(undefined);
          })
        )
        .subscribe(
          () => {},
          error => reject(error)
        );
    });
  }
}
