import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChecklistSelectComponent} from '@shared/components/checklist-select/checklist-select.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import {PipesModule} from '@shared/pipes/pipes.module';



@NgModule({
  declarations: [
    ChecklistSelectComponent  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    PipesModule
  ],
  exports: [
    ChecklistSelectComponent
  ]
})
export class ChecklistSelectModule { }
