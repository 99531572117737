import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { LoadingIconDirective } from '@shared/directives/loading-icon.directive';
import { DisableDirectiveDirective } from './disable-directive.directive';
import { TableResizeDirective } from './table-resize.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AutofocusDirective,
    LoadingIconDirective,
    DisableDirectiveDirective,
    TableResizeDirective,
  ],
    exports: [
        AutofocusDirective,
        LoadingIconDirective,
        DisableDirectiveDirective,
        TableResizeDirective,
    ]
})
export class DirectivesModule {}
