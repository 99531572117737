import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {DataResponse, ListResponse, SuccessResponse} from '../models/response.model';
import {TopicIndex} from '../models/topic-index.model';
import {HttpClient, HttpContext, HttpEvent, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {map} from 'rxjs/operators';

const cacheNotifier: Subject<void> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class TopicIndexService {
  get apiURL() {
    return this.appConfigService.appConfig.apiURL;
  }

  constructor(private api: ApiService, private httpClient: HttpClient, private appConfigService: AppConfigService) {
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<TopicIndex>> {
    return this.api.get(`/topic/indices`, params);
  }

  getById(id: number): Observable<DataResponse<TopicIndex>> {
    return this.api.get(`/topic/index/${id}`);
  }

  getContent(id: number) {
    return this.api.get('/topic/index/' + id + '/contentNew');
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<TopicIndex>> {
    return this.api.post(`/topic/index`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<TopicIndex>> {
    return this.api.put(`/topic/index/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/topic/index/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
