import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageFilterComponent } from './language-filter.component';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FilterModule} from '@shared/components/filter/filter.module';
import {LanguageSelectModule} from '@shared/components/language-select/language-select.module';



@NgModule({
  declarations: [
    LanguageFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    FilterModule,
    LanguageSelectModule
  ],
  exports: [
    LanguageFilterComponent
  ]
})
export class LanguageFilterModule { }
