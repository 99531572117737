export class Department {
  companyId: number = 0;
  id: number = 0;
  isCompany: boolean = false;
  isPlace: boolean = false;
  name: string = '';
  namePath: string = '';
  number: string = '';
  numberPath: string = '';
  parentElements: DepartmentParent[] = [];
  parentSectionId: number = null;
  logoURL: string;
  city: string = '';
  country: number = 0;
  zipCode: string = '';
  street: string = '';
  cooperativeName: string = '';
  cooperativeStreet: string = '';
  cooperativeZipCode: string = '';
  cooperativeCity: string = '';
  cooperativeCountry: string = '';

  constructor(config?: Partial<Department>) {
    if (config) {
      Object.keys(config)
        .filter(key => typeof config[key] !== 'undefined')
        .forEach(key => this[key] = config[key]);
    }
  }
}

export interface DepartmentParent {
  id: number;
  name: string;
  number: number;
  type: string;
}
