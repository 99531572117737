import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import {
  CheckLogin,
  Login,
  LoginError,
  LoginSuccess,
  Logout,
  RefreshLogin,
  RefreshSuccess,
  UpdateAuthState, UpdateCompanySettings,
  UpdateModules,
  UpdatePermissions,
} from '@shared/store/auth/auth.actions';
import {AuthService} from '@shared/services/auth.service';
import {filter, tap} from 'rxjs/operators';
import {UserDetails} from '@shared/models/user-details.model';
import {parseJSON} from '@shared/utils/utils';
import {LoadSettings} from '@frontend/store/settings/settings.actions';
import {SetDefaultLanguage} from '@shared/store/language/language.actions';
import {SetDefaultTheme} from '@shared/store/theme/theme.actions';
import {Injectable} from '@angular/core';
import {PermissionService} from '@shared/services/permission.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ModuleService} from '@shared/services/module.service';
import {UserDetailsService} from '@shared/services/user-details.service';
import {CompanySettingModel} from '@shared/models/companySettingModel';
import {CompanyService} from '@shared/services/company.service';
import {Observable} from 'rxjs';

export class AuthStateModel {
  authenticated: boolean;
  userDetails: UserDetails;
  jwt: string;
  refreshToken: string;
  assignedModules: string[];
  permissions: string[];
  loading: boolean;
  companySettings: CompanySettingModel;
}

@State<AuthStateModel>({
  name: 'userDetail',
  defaults: {
    authenticated: false,
    userDetails: null,
    jwt: '',
    refreshToken: '',
    assignedModules: [],
    permissions: [],
    loading: false,
    companySettings: null
  }
})
@Injectable()
export class AuthState implements NgxsAfterBootstrap {

  refreshJwt: Observable<any>;
  @Selector()
  static authenticated(state: AuthStateModel) {
    return state.authenticated;
  }

  @Selector()
  static userDetails(state: AuthStateModel) {
    return state.userDetails;
  }

  @Selector()
  static jwt(state: AuthStateModel) {
    return state.jwt;
  }

  @Selector()
  static assignedModules(state: AuthStateModel) {
    return state.assignedModules;
  }

  @Selector()
  static hasAssignedModules(state: AuthStateModel) {
    return state.assignedModules.length > 0;
  }

  @Selector()
  static loading(state: AuthStateModel) {
    return state.loading;
  }

  @Selector()
  static employee(state: AuthStateModel) {
    return state.userDetails && state.userDetails.employee || null;
  }

  @Selector()
  static user(state: AuthStateModel) {
    return state.userDetails && state.userDetails.user || null;
  }

  @Selector()
  static section(state: AuthStateModel) {
    return state.userDetails && state.userDetails.section || null;
  }

  @Selector()
  static permissions(state: AuthStateModel) {
    return state.permissions;
  }

  @Selector()
  static companySetting(state: AuthStateModel) {
    return state.companySettings;
  }
  constructor(
    private authService: AuthService,
    private permissionService: PermissionService,
    private moduleService: ModuleService,
    private userDetailService: UserDetailsService,
    private companyService: CompanyService
  ) {
  }

  ngxsAfterBootstrap({dispatch, patchState}: StateContext<AuthStateModel>) {
    if (localStorage.getItem('userDetail')) {
      const userDetails: UserDetails = parseJSON(localStorage.getItem('userDetail'));

      if (!userDetails) {
        localStorage.removeItem('userDetail');

        return;
      }

      patchState({
        authenticated: true,
        userDetails: userDetails,
        assignedModules: userDetails.user.systemUser ?
          userDetails.modules.map((module) => module.name) :
          userDetails.assignedModules.map((module) => module.name),
      });

      dispatch(new LoadSettings());
    }
  }

  @Action(Login)
  login({dispatch, patchState}: StateContext<AuthStateModel>, {payload}: Login) {
    patchState({loading: true});

    return this.authService.login(payload)
      .pipe(
        tap((result) => {
          const action = result.success
            ? new LoginSuccess({userDetails: result.userDetails, jwt: result.jwt, refreshToken: result.refreshToken, companySettings: result.companySettings})
            : new LoginError({error: (result.errors && result.errors.reason || null)});

          dispatch(action);
        })
      );
  }

  @Action(LoginSuccess)
  loginSuccess({dispatch, setState, patchState}: StateContext<AuthStateModel>, {payload}: LoginSuccess) {
    localStorage.setItem('userDetail', JSON.stringify(payload.userDetails));
    localStorage.setItem('jwt', payload.jwt);
    localStorage.setItem('refreshToken', payload.refreshToken);
    localStorage.setItem('companySettings', JSON.stringify(payload.companySettings));
    localStorage.removeItem('activateCaptcha');
    localStorage.removeItem('filter');
    localStorage.removeItem('pageSize');


    setState({
      authenticated: true,
      jwt: payload.jwt,
      refreshToken: payload.refreshToken,
      userDetails: payload.userDetails,
      assignedModules: payload.userDetails.user.systemUser ?
        payload.userDetails.modules.map((module) => module.name) :
        payload.userDetails.assignedModules.map((module) => module.name),
      permissions: [],
      loading: true,
      companySettings: null
    });

    dispatch(new UpdatePermissions({userId: payload.userDetails.user.id}));
    if (payload.userDetails.section) {
      dispatch(new UpdateCompanySettings({companyId: payload.userDetails.section.companyId}));
    }
    dispatch(new LoadSettings());
    dispatch(new CheckLogin());
  }

  @Action(LoginError)
  loginError({patchState}: StateContext<AuthStateModel>) {
    patchState({
      authenticated: false,
      loading: false,
    });
  }

  @Action(Logout)
  logout({dispatch, setState}: StateContext<AuthStateModel>) {
    return this.authService.logout().pipe(tap(() => {
      localStorage.removeItem('userDetail');
      localStorage.removeItem('jwt');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('permissions');
      localStorage.removeItem('companySettings');
      localStorage.removeItem('filter');
      localStorage.removeItem('pageSize');

      setState({
        authenticated: false,
        userDetails: null,
        refreshToken: '',
        jwt: '',
        assignedModules: [],
        permissions: [],
        loading: false,
        companySettings: null
      });

      dispatch([
        new SetDefaultLanguage(),
        new SetDefaultTheme(),
      ]);
    }));
  }

  @Action(CheckLogin)
  checkLogin({dispatch, patchState}: StateContext<AuthStateModel>) {
    const helper = new JwtHelperService();

    const decodedJWT = helper.decodeToken(localStorage.jwt);

    if (!localStorage.getItem('permissions')) {
      dispatch(new UpdatePermissions({userId: decodedJWT.user.id}));
    }

    if (!localStorage.getItem('companySettings')) {
      dispatch(new UpdateCompanySettings({companyId: decodedJWT.section.companyId}));
    }
    const unixTime = window.Math.floor(Date.now() / 1000);

    if (unixTime >= (decodedJWT.exp - 60)) {
      dispatch(RefreshLogin);
    } else {
      setTimeout(() => {
        dispatch(CheckLogin);
      }, 1000 * 60);
    }
  }

  @Action(RefreshLogin)
  refreshLogin({dispatch}: StateContext<AuthStateModel>) {

    const param = {refreshToken: localStorage.getItem('refreshToken')};
    return this.authService.refreshLogin(param)
      .pipe(
        tap((result) => {
          const action = result.success
            ? new RefreshSuccess({jwt: result.jwt})
            : new Logout();

          dispatch(action);
        })
      );
  }

  @Action(RefreshSuccess)
  refreshSuccess({patchState, dispatch}: StateContext<AuthStateModel>, {payload}: RefreshSuccess) {
    localStorage.setItem('jwt', payload.jwt);

    patchState({
      jwt: payload.jwt
    });

    const helper = new JwtHelperService();

    const decodedJWT = helper.decodeToken(payload.jwt);

    dispatch(new UpdatePermissions({userId: decodedJWT.user.id}));
    if (decodedJWT.section) {
      dispatch(new UpdateCompanySettings({companyId: decodedJWT.section.companyId}));
    }
    dispatch(new UpdateAuthState());
  }

  @Action(UpdatePermissions)
  updatePermissions({patchState, dispatch}: StateContext<AuthStateModel>, {payload}) {
    this.permissionService.getByUserId(payload.userId)
      .subscribe((response) => {
        const permissions = response.data.map((permission) => permission.name);

        patchState({
          permissions: permissions
        });

        const permissionJSON = JSON.stringify(permissions);
        localStorage.setItem('permissions', permissionJSON);

      });
  }


  @Action(UpdateCompanySettings)
  updateCompanySettings({patchState, dispatch}: StateContext<AuthStateModel>, {payload}) {
    this.companyService.getCompanySettingsById(payload.companyId)
      .subscribe((response) => {
        const companySettings = response;

        patchState({
          companySettings: companySettings
        });

        const companySettingsJSON = JSON.stringify(companySettings);
        localStorage.setItem('companySettings', companySettingsJSON);

      });
  }
  @Action(UpdateModules)
  updateModules({patchState, dispatch}: StateContext<AuthStateModel>, {payload}) {
    this.moduleService.getModulesOfUser(payload.userId)
      .subscribe((response) => {
        const modules = response.data.map((module) => module.name);

        patchState({
          assignedModules: modules
        });
      });
  }

  @Action(UpdateAuthState)
  updateAuthState({patchState, dispatch}: StateContext<AuthStateModel>) {
    const helper = new JwtHelperService();

    if (localStorage.jwt) {
      const decodedJWT = helper.decodeToken(localStorage.jwt);

      this.userDetailService.getByUserId(decodedJWT.user.id)
        .subscribe((response) => {

          const userDetails = response.data;

          if (!userDetails) {
            localStorage.removeItem('userDetail');

            return;
          }

          patchState({
            authenticated: true,
            userDetails: userDetails,
              assignedModules: userDetails.user.systemUser ?
                userDetails.modules.map((module) => module.name) :
                userDetails.assignedModules.map((module) => module.name),
          });

          dispatch(new UpdatePermissions({userId: userDetails.user.id}));
          if (!userDetails.user.systemUser) {
            dispatch(new UpdateCompanySettings({companyId: userDetails.section.companyId}));
          }
          dispatch(new CheckLogin());
        });
    } else {
      return;
    }
  }

}

