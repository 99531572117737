import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import {HazardAssessmentTemplate, HazardAssessmentTemplateTemplate} from '@shared/models/hazard-assessment-template.model';
import { HazardAssessmentTemplateDetails } from '@shared/models/hazard-assessment-template-details.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class HazardAssessmentTemplateService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<HazardAssessmentTemplateDetails>> {
    return this.api.get(`/hazardAssessmentTemplate-list`, params);
  }

  getById(id: number): Observable<HazardAssessmentTemplate> {
    return this.api.get(`/hazardAssessmentTemplate/${id}`);
  }

  getHazardAssessmentTemplateDetails(hazardTemplateId: number): Observable<HazardAssessmentTemplateDetails> {
    return this.api.get(`/hazardAssessmentTemplateDetails/${hazardTemplateId}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<HazardAssessmentTemplateTemplate>> {
    return this.api.post(`/hazardAssessmentTemplate`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<HazardAssessmentTemplateTemplate>> {
    return this.api.put(`/hazardAssessmentTemplate/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  updateCategories(id: number, categoryIds: number[]): Observable<DataResponse<HazardAssessmentTemplateTemplate>> {
    return this.api.put(`/hazardAssessmentTemplate/${id}/categories`, { categoryIds });
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/hazardAssessmentTemplate/${id}`);
  }
  getPrintURL(templateId: number, jwt: string): string {
    // return `${this.api.apiURL}/resources_project/guiExtDesktop/_hazardAssessment/template/print/printAssessmentTemplate.php?
    // templateId=${templateId}&jwt=${jwt}`;
    return `${this.api.apiURL}/hazardAssessment/${templateId}/printTemplate?jwt=${jwt}`;
  }

  getPrintURL2(hazardAssessmentId: number, jwt: string) {
    return `${this.api.apiURL}/hazardAssessment/${hazardAssessmentId}/print?jwt=${jwt}`;
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
