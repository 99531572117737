import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AccidentDialogComponent} from '@shared/components/accident-dialog/accident-dialog.component';
import {MaterialModule} from '@shared/material.module';
import {LoadingSpinnerModule} from '@shared/components/loading-spinner/loading-spinner.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {AccidentStatusSelectModule} from '@shared/components/accident-status-select/accident-status-select.module';
import {AccidentTypeSelectModule} from '@shared/components/accident-type-select/accident-type-select.module';
import {EmployeeSelectModule} from '@shared/components/employee-select/employee-select.module';
import {DepartmentSelectModule} from '@shared/components/department-select/department-select.module';
import {AccidentCauseSelectModule} from '@shared/components/accident-cause-select/accident-cause-select.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {TimeInputModule} from '@shared/components/time-input/time-input.module';
import {DateSelectModule} from '@shared/components/date-select/date-select.module';
import {ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [AccidentDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    LoadingSpinnerModule,
    LoadingOverlayModule,
    AccidentStatusSelectModule,
    AccidentTypeSelectModule,
    EmployeeSelectModule,
    DepartmentSelectModule,
    AccidentCauseSelectModule,
    PipesModule,
    TimeInputModule,
    DateSelectModule,
    ReactiveFormsModule
  ],
  exports: [
    AccidentDialogComponent
  ]
})
export class AccidentDialogModule { }
