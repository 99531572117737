import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentCategorySelectComponent } from './equipment-category-select.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';



@NgModule({
  declarations: [
    EquipmentCategorySelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule
  ],
  exports: [
    EquipmentCategorySelectComponent
  ]
})
export class EquipmentCategorySelectModule { }
