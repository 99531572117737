import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SymbolIconSelectComponent} from '@shared/components/symbol-icon-select/symbol-icon-select.component';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {MaterialModule} from '@shared/material.module';
import {FilterPanelModule} from '@shared/components/filter-panel/filter-panel.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {EmptyMessageModule} from '@shared/components/empty-message/empty-message.module';
import {LoadingSpinnerModule} from '@shared/components/loading-spinner/loading-spinner.module';



@NgModule({
  declarations: [
    SymbolIconSelectComponent
  ],
    imports: [
        CommonModule,
        LoadingOverlayModule,
        MaterialModule,
        FilterPanelModule,
        PipesModule,
        FlexModule,
        EmptyMessageModule,
        ExtendedModule,
        LoadingSpinnerModule
    ],
  exports: [
    SymbolIconSelectComponent
  ]
})
export class SymbolIconSelectModule { }
