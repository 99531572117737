import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CloseSidenav, OpenSidenav, ToggleSidenav } from '@frontend/store/frontend/frontend.actions';
import {Injectable} from '@angular/core';

export class FrontendStateModel {
  sidenavOpened: boolean;
}

@State<FrontendStateModel>({
  name: 'frontend',
  defaults: {
    sidenavOpened: false,
  }
})
@Injectable()
export class FrontendState {

  @Selector()
  static sidenavOpened(state: FrontendStateModel) {
    return state.sidenavOpened;
  }

  @Action(OpenSidenav)
  openSidenav({ patchState }: StateContext<FrontendStateModel>) {
    patchState({ sidenavOpened: false });
  }

  @Action(CloseSidenav)
  closeSidenav({ patchState }: StateContext<FrontendStateModel>) {
    patchState({ sidenavOpened: false });
  }

  @Action(ToggleSidenav)
  toggleSidenav({ getState, patchState }: StateContext<FrontendStateModel>) {
    patchState({ sidenavOpened: !getState().sidenavOpened });
  }
}
