import { Action, Actions, NgxsAfterBootstrap, ofAction, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '@shared/services/translation.service';
import { Navigate } from '@ngxs/router-plugin';
import { Router } from '@angular/router';
import {
  LoginTerminal,
  LoginTerminalSuccess,
  LoginTerminalError,
  PollActivity,
  LoadSelectedTerminal,
  AddExternalCompanyDetails,
  AddExternalCompanyEmployee,
  AddExternalCompanyEmployeeSuccess,
  AddExternalCompanyEmployeeError,
  AddExternalCompanyDetailsSuccess,
  AddExternalCompanyDetailsError,
  GetExternalCompanyEmployee,
  SaveActivity,
} from '@terminal/store/terminal-page/terminal-page.actions';
import { TerminalService } from '@shared/services/terminal.service';
import { parseJSON } from '@shared/utils/utils';
import { of } from 'rxjs';
import { ExternalCompanyService } from '@shared/services/external-company.service';
import { ExternalCompanyEmployeeService } from '@shared/services/external-company-employee.service';
import { ExternalCompany } from '@shared/models/external-company.model';
import { ExternalCompanyEmployee } from '@shared/models/external-company-employee.model';
import { Terminal } from '@shared/models/terminal.model';
import { TerminalActivity } from '@shared/models/terminal-activity.model';
import { FormState } from '@shared/models/form-state.model';
import { TerminalActivityService } from '@shared/services/terminal-activity.service';
import {Injectable} from '@angular/core';

export class TerminalPageStateModel {
  selectedTerminal: number;
  externalCompany: ExternalCompany;
  externalEmployee: ExternalCompanyEmployee;
  terminal: Terminal;
  activity: TerminalActivity;
  loading: boolean;
  polling: boolean;
  addExternalCompanyForm?: FormState<{
    name: string;
    street: string;
    zipCode: string;
    city: string;
  }>;
  addExternalEmployeeForm?: FormState<{
    salutation: string;
    title: string;
    firstName: string;
    name: string;
    login: string;
    password: string;
    languageId: number;
    email: string;
    employeeNumber: string;
  }>;
}

@State<TerminalPageStateModel>({
  name: 'terminalPage',
  defaults: {
    selectedTerminal: null,
    externalCompany: null,
    externalEmployee: null,
    terminal: null,
    activity: null,
    loading: false,
    polling: false,
  }
})
@Injectable()
export class TerminalPageState implements NgxsAfterBootstrap {

  @Selector()
  static authenticated(state: TerminalPageStateModel) {
    return !!state.selectedTerminal;
  }

  @Selector()
  static selectedTerminal(state: TerminalPageStateModel) {
    return state.selectedTerminal;
  }

  @Selector()
  static terminal(state: TerminalPageStateModel) {
    return state.terminal;
  }

  @Selector()
  static activity(state: TerminalPageStateModel) {
    return state.activity;
  }

  @Selector()
  static loading(state: TerminalPageStateModel) {
    return state.loading;
  }

  @Selector()
  static externalCompany(state: TerminalPageStateModel) {
    return state.externalCompany;
  }

  @Selector()
  static externalCompanyEmployee(state: TerminalPageStateModel) {
    return state.externalEmployee;
  }

  @Selector()
  static externalCompanyFormData(state: TerminalPageStateModel) {
    return state.addExternalCompanyForm.model;
  }

  @Selector()
  static externalEmployeeFormData(state: TerminalPageStateModel) {
    return state.addExternalEmployeeForm.model;
  }

  constructor(
    private actions$: Actions,
    private terminalService: TerminalService,
    private terminalActivityService: TerminalActivityService,
    private externalCompanyService: ExternalCompanyService,
    private externalCompanyEmployeeService: ExternalCompanyEmployeeService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
  ) {}

  ngxsAfterBootstrap({ dispatch, patchState }: StateContext<TerminalPageStateModel>) {
    if (localStorage.getItem('terminal')) {
      const terminal = parseJSON(localStorage.getItem('terminal'));

      if (!terminal) {
        localStorage.removeItem('terminal');

        return;
      }

      patchState({
        selectedTerminal: terminal.id,
      });
    }
  }

  @Action(LoadSelectedTerminal)
  loadSelectedTerminal({ getState, patchState }: StateContext<TerminalPageStateModel>) {
    patchState({ loading: true });

    return this.terminalService.getById(getState().selectedTerminal)
      .pipe(
        tap((result) => {
          patchState({
            terminal: result,
            loading: false,
          });
        })
      );
  }

  @Action(LoginTerminal)
  login({ dispatch, patchState }: StateContext<TerminalPageStateModel>, { payload }: LoginTerminal) {
    patchState({ loading: true });

    return this.terminalService.login(payload)
      .pipe(
        tap((result) => {
          dispatch(result.success
            ? new LoginTerminalSuccess({ terminal: result.terminal })
            : new LoginTerminalError({ error: result.failureReason || null })
          );
        })
      );
  }

  @Action(LoginTerminalSuccess)
  loginSuccess({ dispatch, patchState }: StateContext<TerminalPageStateModel>, { payload }: LoginTerminalSuccess) {
    localStorage.setItem('terminal', JSON.stringify(payload.terminal));

    patchState({
      selectedTerminal: payload.terminal.id,
      loading: false,
    });

    dispatch([
      new Navigate(['/terminal']),
    ]);
  }

  @Action(LoginTerminalError)
  loginError({ patchState }: StateContext<TerminalPageStateModel>, { payload }: LoginTerminalError) {
    patchState({
      selectedTerminal: null,
      loading: false,
    });

    this.snackBar.open(this.translationService.translate(payload.error || 'loginIncorrect'));
  }

  @Action(PollActivity, { cancelUncompleted: true })
  pollActivity({ getState, patchState }: StateContext<TerminalPageStateModel>) {
    patchState({ polling: true });

    return this.terminalActivityService.getByTerminalId(getState().terminal.id)
      .pipe(
        tap((activity) => {
          patchState({
            activity: activity,
            polling: false,
          });
        }),
        catchError(() => {
          patchState({
            activity: null,
            polling: false,
          });

          return of(null);
        }),
      );
  }

  @Action(SaveActivity, { cancelUncompleted: true })
  closeActivity({ getState, patchState }: StateContext<TerminalPageStateModel>, { status }: SaveActivity) {
    patchState({ loading: true });

    return this.terminalActivityService.update(getState().activity.id, {
      status: status,
      fromTerminal: true,
      externalEmployeeId: (getState().externalEmployee && getState().externalEmployee.id) || getState().activity.externalEmployeeId,
    }).pipe(
      tap(() => patchState({
        activity: null,
        loading: false,
      }))
    );
  }

  @Action(AddExternalCompanyDetails)
  addExternalCompany({ getState, patchState, dispatch }: StateContext<TerminalPageStateModel>, { payload }: AddExternalCompanyDetails) {
    patchState({ loading: true });

    return this.externalCompanyService.createDetails(payload)
      .pipe(
        tap((response) => response.success
          ? dispatch(new AddExternalCompanyDetailsSuccess(response.data))
          : dispatch(new AddExternalCompanyDetailsError())
        ),
        catchError(() => dispatch(new AddExternalCompanyDetailsError()))
      );
  }

  @Action(AddExternalCompanyDetailsSuccess)
  addExternalCompanySuccess({ getState, patchState }: StateContext<TerminalPageStateModel>, { payload }: AddExternalCompanyDetailsSuccess) {
    patchState({
      externalCompany: payload.externalCompany,
      loading: false,
    });
  }

  @Action(AddExternalCompanyDetailsError)
  addExternalCompanyError({ patchState }: StateContext<TerminalPageStateModel>) {
    patchState({
      externalCompany: null,
      loading: false,
    });
  }

  @Action(GetExternalCompanyEmployee)
  getExternalCompanyEmployee({ patchState, dispatch }: StateContext<TerminalPageStateModel>, { id }: GetExternalCompanyEmployee) {
    patchState({ loading: true });

    return this.externalCompanyEmployeeService.getById(id)
      .pipe(
        tap((response) => patchState({
          externalEmployee: response,
          loading: false,
        }))
      )
  }

  @Action(AddExternalCompanyEmployee)
  addExternalCompanyEmployee({ getState, patchState, dispatch }: StateContext<TerminalPageStateModel>, { payload }: AddExternalCompanyEmployee) {
    patchState({ loading: true });

    return this.externalCompanyEmployeeService.create(payload)
      .pipe(
        tap((response) => response.success
          ? dispatch(new AddExternalCompanyEmployeeSuccess(response.data))
          : dispatch(new AddExternalCompanyEmployeeError())
        ),
        catchError(() => dispatch(new AddExternalCompanyEmployeeError()))
      )
  }

  @Action(AddExternalCompanyEmployeeSuccess)
  addExternalCompanyEmployeeSuccess({ getState, patchState }: StateContext<TerminalPageStateModel>, { payload }: AddExternalCompanyEmployeeSuccess) {
    patchState({
      externalEmployee: payload,
      loading: false,
    });
  }

  @Action(AddExternalCompanyEmployeeError)
  addExternalCompanyEmployeeError({ patchState }: StateContext<TerminalPageStateModel>) {
    patchState({
      externalEmployee: null,
      loading: false,
    });
  }
}
