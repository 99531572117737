import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { EmployeeCategory } from '@shared/models/employee-category.model';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import { EmployeeCategoryListItem } from '@shared/models/employee-category-list-item.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class EmployeeCategoryService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<EmployeeCategoryListItem>> {
    return this.api.get(`/userCategory-list`, params);
  }

	@Cacheable({
		cacheBusterObserver: cacheNotifier
	})
	getAll(params?: object): Observable<ListResponse<EmployeeCategoryListItem>> {
		return this.api.get(`/userCategories`, params);
	}

  getById(id: number): Observable<EmployeeCategory> {
    return this.api.get(`/userCategory/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<EmployeeCategory>> {
    return this.api.post(`/userCategory`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<EmployeeCategory>> {
    return this.api.put(`/userCategory/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/userCategory/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
