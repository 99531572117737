import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Language} from '@shared/models/language.model';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LanguageService} from '@shared/services/language.service';
import {TranslationService} from '@shared/services/translation.service';
import {checkPageSize, DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS, pageChange} from '@shared/utils/utils';
import {tap} from 'rxjs/operators';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MatPaginator} from '@angular/material/paginator';
import {SearchFilterComponent} from '@shared/components/search-filter/search-filter.component';
import {MatSort} from '@angular/material/sort';
import {merge} from 'rxjs';

export interface LanguageDialogConfig {
  multiple: boolean;
  selected: Language[];
}

@Component({
  selector: 'saf-language-select-dialog',
  templateUrl: './language-select-dialog.component.html',
  styleUrls: ['./language-select-dialog.component.scss']
})
export class LanguageSelectDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(SearchFilterComponent) searchFilter: SearchFilterComponent;
  public tableColumns = ['select', 'name'];
  public dataSource = new MatTableDataSource();
  public loading = true;
  public totalCount = 0;
  public multiple = false;
  public selected = null;
  public drawerFilterParams: any = {};
  public searchFilterParams: any = {};
  public selection: SelectionModel<Language>;

  constructor(
    public dialogRef: MatDialogRef<LanguageSelectDialogComponent, Language | Language[] | null>,
    private languageService: LanguageService,
    private translationService: TranslationService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private config: LanguageDialogConfig,
    @Inject(PAGE_SIZE_OPTIONS) public pageSizeOptions,
    @Inject(DEFAULT_PAGE_SIZE) public pageSize,
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer
  ) {
    this.multiple = this.config.multiple;
    this.selected = this.config.selected;
    this.selection = new SelectionModel<Language>(false, this.selected);

    if (this.multiple) {
      this.selection = new SelectionModel<Language>(this.multiple, this.selected);
      if (this.selected) {
      }
      this.tableColumns.unshift('select');
    }
  }

  ngOnInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page).subscribe(() => this.loadTable());
  }

  ngAfterViewInit() {
    this.loadTable();
    this.changeDetectorRef.detectChanges();
  }

  loadTable() {
    const params = {
      filter: {
        ...this.searchFilterParams,
        ...this.drawerFilterParams,
      },
      translated: true,
      sort: this.sort.direction ? this.sort.active : '',
      dir: this.sort.direction,
      start: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    };

    return this.languageService.getAll(params)
      .pipe(tap(() => this.loading = false))
      .subscribe(result => {
        this.totalCount = result.total;
        this.dataSource.data = result.data;
        this.addIcons(result.data);
      });
  }

  closeDialog(e, language: Language) {
    if (!this.multiple) {
      this.dialogRef.close(language);
    }
  }

  reloadTable() {
    LanguageService.clearCache();
    this.loadTable();
  }

  select(item) {
    this.dialogRef.close(item);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  submit() {
    this.dialogRef.close(this.selection.selected);
  }

  close(result = null) {
    this.dialogRef.close(result);
  }

  isSelected(row: Language) {
    return !!(this.selection.selected.find(language => language.id === row.id));
  }

  selectedRow(row: Language, e) {
    if (this.multiple) {
      if (e.checked) {
        this.selection.toggle(row);
      } else {
        const selection = this.selection.selected;
        this.selection.clear();
        selection.forEach(selected => {
          if (selected.id !== row.id) {
            this.selection.select(selected);
          }
        });
      }
    } else {
      if (e.checked) {
        this.selection.clear();
        this.selection.toggle(row);
      } else {
        this.selection.clear();
      }
    }
  }

  applyDrawerFilters(filterParams) {
    this.drawerFilterParams = filterParams;
    this.paginator.pageIndex = 0;
    this.loadTable();
  }

  addIcons(languages: Language[]) {
    languages.forEach(language => {
      this.matIconRegistry.addSvgIcon(
        `${language.acronym}`,
        this.domSanitzer.bypassSecurityTrustResourceUrl( `assets/icons/flags/4x3/${language.acronym}.svg`));
    });
  }

    protected readonly pageChange = pageChange;
  protected readonly checkPageSize = checkPageSize;
}
