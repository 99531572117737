import { Country } from '@shared/models/country.model';

export class LoadCountries {
  static readonly type = '[Country] Load Countries';
}

export class LoadCountriesSuccess {
  static readonly type = '[Country] Load Countries Success';
  constructor(public payload: Country[]) {}
}
