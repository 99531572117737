import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {TopicSelectComponent} from '@shared/components/topic-select/topic-select.component';



@NgModule({
  declarations: [
    TopicSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
  ],
  exports: [
    TopicSelectComponent
  ]
})
export class TopicSelectModule { }
