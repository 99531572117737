import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {finalize, Observable, throwError} from 'rxjs';
import {catchError, timeout} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {Logout, RefreshLogin} from '@shared/store/auth/auth.actions';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoadingService} from '@shared/services/loading.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  timout = 1000000000;
  constructor(private store: Store,
              private snackbar: MatSnackBar,
              private loading: LoadingService,
  ) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('external/terminalActivity')) {
      this.loading.showLoader();
    }
    if (req.url.includes('import')) {
      this.timout = 1000000000;
    }
    return next.handle(req).pipe(
      timeout(this.timout),
      finalize(() => this.loading.hideLoader()),
      catchError((error) => {
        if (error.status === 401) {
          this.snackbar.open('Ihre Sitzung ist abgelaufen');
          this.store.dispatch(Logout)
            .pipe()
            .subscribe( () => {
              this.loading.hideLoader();
            });
        }
        if (error.status === 500 && (req.method === 'PUT' || req.method === 'POST')) {
          this.snackbar.open('Ein Fehler ist aufgetreten');
        }
        this.loading.hideLoader();
        return throwError(error.message);
      })
    );
  }
}
