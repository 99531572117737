import { NgModule } from '@angular/core';
import {ProfileFilterComponent} from '@shared/components/profile-filter/profile-filter.component';
import {FilterModule} from '@shared/components/filter/filter.module';
import {ProfileSelectModule} from '@shared/components/profile-select/profile-select.module';



@NgModule({
  declarations: [
    ProfileFilterComponent
  ],
  imports: [
    FilterModule,
    ProfileSelectModule
  ],
  exports: [
    ProfileSelectModule,
    ProfileFilterComponent,
  ]
})
export class ProfileFilterModule { }
