import { Terminal } from '@shared/models/terminal.model';
import { ExternalCompanyEmployee } from '@shared/models/external-company-employee.model';
import { ExternalCompany } from '@shared/models/external-company.model';
import { ExternalCompanyDetails } from '@shared/models/external-company-details.model';

export class LoadSelectedTerminal {
  static readonly type = '[Terminal] Load Selected Terminal';
}

export class LoginTerminal {
  static readonly type = '[Terminal] Login Terminal';
  constructor(public payload: { terminalId: number, password: string }) {}
}

export class LoginTerminalSuccess {
  static readonly type = '[Terminal] Login Terminal Success';
  constructor(public payload: { terminal: Terminal }) {}
}

export class LoginTerminalError {
  static readonly type = '[Terminal] Login Terminal Error';
  constructor(public payload: { error: string }) {}
}

export class PollActivity {
  static readonly type = '[Terminal] Poll Activity';
  constructor(public payload: { terminalId: number }) {}
}

export class SaveActivity {
  static readonly type = '[Terminal] Save Activity';
  constructor(public status: number) {}
}

export class AddExternalCompanyDetails {
  static readonly type = '[Terminal] Add External Company Details';
  constructor(public payload: any) {}
}

export class AddExternalCompanyDetailsSuccess {
  static readonly type = '[Terminal] Add External Company Details Success';
  constructor(public payload: ExternalCompanyDetails) {}
}

export class AddExternalCompanyDetailsError {
  static readonly type = '[Terminal] Add External Company Details Error';
}

export class GetExternalCompanyEmployee {
  static readonly type = '[Terminal] Get External Company Employee';
  constructor(public id: number) {}
}

export class AddExternalCompanyEmployee {
  static readonly type = '[Terminal] Add External Company Employee';
  constructor(public payload: any) {}
}

export class AddExternalCompanyEmployeeSuccess {
  static readonly type = '[Terminal] Add External Company Employee Success';
  constructor(public payload: ExternalCompanyEmployee) {}
}

export class AddExternalCompanyEmployeeError {
  static readonly type = '[Terminal] Add External Company Employee Error';
}
