import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ImageCropperDialogComponent} from '@shared/components/image-cropper-dialog/image-cropper-dialog.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {PipesModule} from '@shared/pipes/pipes.module';
import {MatIconModule} from '@angular/material/icon';



@NgModule({
  declarations: [ImageCropperDialogComponent],
  exports: [ImageCropperDialogComponent],
    imports: [
        CommonModule,
        ImageCropperModule,
        MatCardModule,
        MatButtonModule,
        MatDialogModule,
        PipesModule,
        MatIconModule
    ]
})
export class ImageCropperDialogModule { }
