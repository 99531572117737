import { NgModule } from '@angular/core';
import { MaterialModule } from '@shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EmployeeSelectComponent } from '@shared/components/employee-select/employee-select.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { EmployeeDialogModule } from '@shared/components/employee-dialog/employee-dialog.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    EmployeeDialogModule,
    ScrollingModule,
  ],
  declarations: [
    EmployeeSelectComponent,
  ],
  exports: [
    EmployeeSelectComponent,
  ],
})
export class EmployeeSelectModule {}
