import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {DataResponse, ListResponse, SuccessResponse} from '../models/response.model';
import {TopicBooklet, TopicBookletDetails} from '../models/topic-booklet.model';

const cacheNotifier: Subject<void> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class TopicBookletService {

  constructor(private api: ApiService) {
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<TopicBooklet>> {
    return this.api.get(`/topic/booklets`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getListDetail(params?: object): Observable<ListResponse<TopicBookletDetails>> {
    return this.api.get(`/topic/booklet-list`, params);
  }

  getById(id: number): Observable<DataResponse<TopicBooklet>> {
    return this.api.get(`/topic/booklet/${id}`);
  }

  getDetail(id: number): Observable<DataResponse<TopicBookletDetails>> {
    return this.api.get(`/topic/booklet-details/${id}`);
  }

  getByTopic(id: number): Observable<DataResponse<TopicBooklet>> {
    return this.api.get(`/topic/${id}/booklet`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<TopicBooklet>> {
    return this.api.post(`/topic/booklet`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<TopicBooklet>> {
    return this.api.put(`/topic/booklet/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/topic/booklet/${id}`);
  }

  getPrintURL(id: number, jwt: string) {
    return `${this.api.apiURL}/topic/booklet/${id}/print?jwt=${jwt}`;
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
