import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChecklistFieldGroupDialogComponent} from '@shared/components/checklist-field-group-dialog/checklist-field-group-dialog.component';
import {PipesModule} from '@shared/pipes/pipes.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';



@NgModule({
    declarations: [
        ChecklistFieldGroupDialogComponent
    ],
    imports: [
        MaterialModule,
        CommonModule,
        PipesModule,
        ReactiveFormsModule,
        LoadingOverlayModule
    ],
    exports: [
        ChecklistFieldGroupDialogComponent
    ]
})
export class ChecklistFieldGroupDialogModule { }
