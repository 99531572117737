import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ImageViewerComponent} from '@shared/components/image-viewer/image-viewer.component';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {SharedModule} from '@shared/shared.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';



@NgModule({
  declarations: [ImageViewerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    LoadingOverlayModule,
  ],
  exports: [ImageViewerComponent]
})
export class ImageViewerModule { }
