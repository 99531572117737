export class LoadExternalCompanies {
  static readonly type = '[ExternalCompany] Load External Companies';
}

export class CreateExternalCompany {
  static readonly type = '[ExternalCompany] Create External Company';
  constructor(public payload: any) {}
}

export class UpdateExternalCompany {
  static readonly type = '[ExternalCompany] Update External Company';
  constructor(public id: number, public payload: any) {}
}

export class DeleteExternalCompany {
  static readonly type = '[ExternalCompany] Delete External Company';
  constructor(public id: number) {}
}
