import { NgModule } from '@angular/core';
import { MaterialModule } from '@shared/material.module';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
    ],
    declarations: [
        ConfirmDialogComponent,
    ],
    exports: [
        ConfirmDialogComponent,
    ]
})
export class ConfirmDialogModule {}
