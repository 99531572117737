import { NgModule } from '@angular/core';
import { FilterModule } from '@shared/components/filter/filter.module';
import { ExternalCompanySelectModule } from '@shared/components/external-company-select/external-company-select.module';
import { ExternalCompanyFilterComponent } from './external-company-filter.component';

@NgModule({
  imports: [
    ExternalCompanySelectModule,
    FilterModule,
  ],
  declarations: [
    ExternalCompanyFilterComponent,
  ],
  exports: [
    ExternalCompanyFilterComponent,
  ],
})
export class ExternalCompanyFilterModule {}
