import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreaLocationSelectAddComponent } from './area-location-select-add.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "@shared/material.module";
import {PipesModule} from "@shared/pipes/pipes.module";
import {LoadingOverlayModule} from "@shared/components/loading-overlay/loading-overlay.module";
import {DepartmentSelectModule} from "@shared/components/department-select/department-select.module";
import {
  EquipmentCategorySelectModule
} from "@shared/components/equipment-category-select/equipment-category-select.module";
import {AreaLocationSelectModule} from "@shared/components/area-location-select/area-location-select.module";
import {CountrySelectModule} from "@shared/components/country-select/country-select.module";



@NgModule({
  declarations: [
    AreaLocationSelectAddComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    LoadingOverlayModule,
    DepartmentSelectModule,
    EquipmentCategorySelectModule,
    AreaLocationSelectModule,
    CountrySelectModule
  ],
  exports: [
    AreaLocationSelectAddComponent
  ]
})
export class AreaLocationSelectAddModule { }
