import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmployeeEditingFilterComponent} from './employee-editing-filter.component';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FilterModule} from '@shared/components/filter/filter.module';



@NgModule({
  declarations: [EmployeeEditingFilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    FilterModule  ],
  exports: [EmployeeEditingFilterComponent]
})
export class EmployeeEditingFilterModule { }
