import { NgModule } from '@angular/core';
import { QualificationTypeFilterComponent } from './qualification-type-filter.component';
import {QualificationTypeSelectModule} from '@shared/components/qualification-type-select/qualification-type-select.module';
import {FilterModule} from '@shared/components/filter/filter.module';



@NgModule({
  declarations: [
    QualificationTypeFilterComponent
  ],
  imports: [
    FilterModule,
    QualificationTypeSelectModule,
  ],
  exports: [
    QualificationTypeFilterComponent
  ]
})
export class QualificationTypeFilterModule { }
