import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Terminal } from '@shared/models/terminal.model';
import { TerminalActivity } from '@shared/models/terminal-activity.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { LanguageState } from '@shared/store/language/language.state';
import { Store } from '@ngxs/store';
import { SetLanguage } from '@shared/store/language/language.actions';

@Component({
  selector: 'saf-terminal-page-select-language-view',
  templateUrl: './terminal-page-select-language-view.component.html',
  styleUrls: ['./terminal-page-select-language-view.component.scss']
})
export class TerminalPageSelectLanguageViewComponent implements OnInit {
  @SelectSnapshot(LanguageState.currentLanguage) currentLanguage;
  @Input() terminal: Terminal;
  @Input() activity: TerminalActivity;
  @Output() viewCompleted = new EventEmitter();
  form: UntypedFormGroup;

  constructor(private store: Store) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      language: new UntypedFormControl(this.currentLanguage || null, Validators.required),
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.store.dispatch(new SetLanguage(this.form.value.language));
      this.viewCompleted.emit();
    }
  }
}
