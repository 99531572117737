import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ExternalCompanyCategoryService } from '@shared/services/external-company-category.service';
import { ExternalCompanyCategory } from '@shared/models/external-company-category.model';
import {
  CreateExternalCompanyCategory,
  DeleteExternalCompanyCategory,
  LoadExternalCompanyCategories, UpdateExternalCompanyCategory
} from '@shared/store/external-company-category/external-company-category.actions';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import {Injectable} from '@angular/core';

export class ExternalCompanyCategoryStateModel {
  externalCompanyCategories: ExternalCompanyCategory[];
  loading: boolean;
}

@State<ExternalCompanyCategoryStateModel>({
  name: 'externalCompanyCategory',
  defaults: {
    externalCompanyCategories: [],
    loading: false,
  }
})
@Injectable()
export class ExternalCompanyCategoryState {

  @Selector()
  static externalCompanyCategories(state: ExternalCompanyCategoryStateModel) {
    return state.externalCompanyCategories;
  }

  @Selector()
  static loading(state: ExternalCompanyCategoryStateModel) {
    return state.loading;
  }

  constructor(private externalCompanyCategoryService: ExternalCompanyCategoryService) {}

  @Action(LoadExternalCompanyCategories, { cancelUncompleted: true })
  loadExternalCompanyCategories({ patchState }: StateContext<ExternalCompanyCategoryStateModel>) {
    patchState({ loading: true });

    return this.externalCompanyCategoryService.getAll()
      .pipe(
        tap((result) => patchState({
          externalCompanyCategories: result.data,
          loading: false,
        }))
      );
  }

  @Action(CreateExternalCompanyCategory)
  createExternalCompanyCategory({ patchState, setState }: StateContext<ExternalCompanyCategoryStateModel>, { payload }: CreateExternalCompanyCategory) {
    patchState({ loading: true });

    return this.externalCompanyCategoryService.create(payload)
      .pipe(
        tap((result) => {
          setState(patch<ExternalCompanyCategoryStateModel>({
            externalCompanyCategories: append([result.data]),
            loading: false,
          }))
        })
      );
  }

  @Action(UpdateExternalCompanyCategory)
  updateExternalCompanyCategory({ patchState, setState }: StateContext<ExternalCompanyCategoryStateModel>, { id, payload }: UpdateExternalCompanyCategory) {
    patchState({ loading: true });

    return this.externalCompanyCategoryService.update(id, payload)
      .pipe(
        tap((result) => {
          setState(patch<ExternalCompanyCategoryStateModel>({
            externalCompanyCategories: updateItem((category) => category.id === id, result.data),
            loading: false,
          }))
        })
      );
  }

  @Action(DeleteExternalCompanyCategory)
  deleteExternalCompanyCategory({ patchState, setState }: StateContext<ExternalCompanyCategoryStateModel>, { id }: DeleteExternalCompanyCategory) {
    patchState({ loading: true });

    return this.externalCompanyCategoryService.delete(id)
      .pipe(
        tap(() => {
          setState(patch<ExternalCompanyCategoryStateModel>({
            externalCompanyCategories: removeItem((category) => category.id === id),
            loading: false,
          }))
        })
      );
  }
}
