import { Injectable } from '@angular/core';
import { Company } from '@shared/models/company.model';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import { ListResponse } from '@shared/models/response.model';
import {CompanySettingModel} from '../models/companySettingModel';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private api: ApiService) {}

  getList(params?: object): Observable<ListResponse<Company>> {
    return this.api.get(`/companies`, params);
  }

  getById(id): Observable<Company> {
    return this.api.get(`/company/${id}`);
  }

  ////// Company-Settings///////////////
  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getCompanySettingsById(id: number): Observable<CompanySettingModel> {
    return this.api.get(`/companySettings/${id}`);
  }

  updateCompanySettingsById(id: number, params: object): Observable<CompanySettingModel> {
    return this.api.put(`/companySettings/${id}`, params);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
