import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopicSelectDialogComponent } from './topic-select-dialog.component';
import {MaterialModule} from '@shared/material.module';
import {FormsModule} from '@angular/forms';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FilterPanelModule} from '@shared/components/filter-panel/filter-panel.module';
import {EmptyMessageModule} from '@shared/components/empty-message/empty-message.module';
import {LoadingSpinnerModule} from '@shared/components/loading-spinner/loading-spinner.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {DepartmentFilterModule} from '@shared/components/department-filter/department-filter.module';
import {EmployeeActivityFilterModule} from '@shared/components/employee-activity-filter/employee-activity-filter.module';
import {EmployeeCategoryFilterModule} from '@shared/components/employee-category-filter/employee-category-filter.module';
import {EmployeeEditingFilterModule} from '@shared/components/employee-editing-filter/employee-editing-filter.module';
import {ModuleFilterModule} from '@shared/components/module-filter/module-filter.module';
import {QualificationFilterModule} from '@shared/components/qualification-filter/qualification-filter.module';
import {RoleFilterModule} from '@shared/components/role-filter/role-filter.module';
import {DefaultBooleanFilterModule} from '@shared/components/default-boolean-filter/default-boolean-filter.module';
import {LanguageFilterModule} from '@shared/components/language-filter/language-filter.module';
import {TopicCategoryFilterModule} from '@shared/components/topic-category-filter/topic-category-filter.module';



@NgModule({
  declarations: [
    TopicSelectDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    PipesModule,
    FlexLayoutModule,
    FilterPanelModule,
    EmptyMessageModule,
    LoadingSpinnerModule,
    LoadingOverlayModule,
    DepartmentFilterModule,
    EmployeeActivityFilterModule,
    EmployeeCategoryFilterModule,
    EmployeeEditingFilterModule,
    ModuleFilterModule,
    QualificationFilterModule,
    RoleFilterModule,
    DefaultBooleanFilterModule,
    LanguageFilterModule,
    TopicCategoryFilterModule
  ],
  exports: [
    TopicSelectDialogComponent
  ]
})
export class TopicSelectDialogModule { }
