import { Action, Actions, NgxsAfterBootstrap, ofActionCompleted, Selector, State, StateContext, Store } from '@ngxs/store';
import { LanguageService } from '@shared/services/language.service';
import {
  LoadLanguages, LoadLanguagesError, LoadLanguagesSuccess,
  SetDefaultLanguage,
  SetLanguage, SetLanguageById,
} from '@shared/store/language/language.actions';
import { catchError, tap } from 'rxjs/operators';
import { browserLanguage } from '@shared/utils/utils';
import { AuthState } from '@shared/store/auth/auth.state';
import { LoginSuccess } from '@shared/store/auth/auth.actions';
import { Language } from '@shared/models/language.model';
import {Injectable} from '@angular/core';

export class LanguageStateModel {
  languages: Language[];
  currentLanguage: Language;
  loading: boolean;
  error: any;
}

@State<LanguageStateModel>({
  name: 'language',
  defaults: {
    languages: [],
    currentLanguage: null,
    loading: false,
    error: false,
  }
})

@Injectable()
export class LanguageState implements NgxsAfterBootstrap {

  @Selector()
  static languages(state: LanguageStateModel) {
    return state.languages;
  }

  @Selector()
  static activeLanguages(state: LanguageStateModel) {
    return state.languages.filter((language) => language.active);
  }

  @Selector()
  static currentLanguage(state: LanguageStateModel) {
    return state.currentLanguage;
  }

  @Selector()
  static defaultLanguage(state: LanguageStateModel) {
    return state.languages.find((language) => language.default);
  }

  @Selector()
  static loading(state: LanguageStateModel) {
    return state.loading;
  }

  @Selector()
  static hasLanguages(state: LanguageStateModel) {
    return state.languages.length > 0;
  }

  constructor(
    private store: Store,
    private actions$: Actions,
    private languageService: LanguageService,
  ) {
    this.actions$.pipe(
      ofActionCompleted(LoginSuccess),
    ).subscribe(() => {
      const user = this.store.selectSnapshot(AuthState.user);

      if (user) {
        this.store.dispatch(new SetLanguageById(user.languageId));
      }
    });
  }

  ngxsAfterBootstrap({ dispatch }: StateContext<LanguageStateModel>) {
    dispatch(new LoadLanguages());
  }

  @Action(LoadLanguages, { cancelUncompleted: true })
  loadLanguages({ patchState, dispatch }: StateContext<LanguageStateModel>) {
    patchState({ loading: true });

    return this.languageService.getAll()
      .pipe(
        tap((result) => dispatch(new LoadLanguagesSuccess(result.data))),
        catchError((err) => dispatch(new LoadLanguagesError(err))),
      );
  }

  @Action(LoadLanguagesSuccess)
  loadLanguagesSuccess({ getState, patchState, dispatch }: StateContext<LanguageStateModel>, { languages }: LoadLanguagesSuccess) {
    patchState({
      languages: languages,
      loading: false,
    });

    if (!getState().currentLanguage) {
      const user = this.store.selectSnapshot(AuthState.user);

      if (user) {
        dispatch(new SetLanguageById(user.languageId));
      } else {
        dispatch(new SetDefaultLanguage());
      }
    }
  }

  @Action(LoadLanguagesError)
  loadLanguagesError({ patchState }: StateContext<LanguageStateModel>, { error }: LoadLanguagesError) {
    patchState({
      loading: false,
      error: error,
    });
  }

  @Action(SetLanguage)
  setLanguage({ patchState }: StateContext<LanguageStateModel>, { payload }: SetLanguage) {
    patchState({
      currentLanguage: payload,
    });
  }

  @Action(SetLanguageById)
  setLanguageById({ getState, patchState }: StateContext<LanguageStateModel>, { languageId }: SetLanguageById) {
    const language = getState().languages.find((language) => language.id === languageId);

    if (language) {
      patchState({
        currentLanguage: language,
      });
    }
  }

  @Action(SetDefaultLanguage)
  setDefaultLanguage({ getState, patchState }: StateContext<LanguageStateModel>) {
    const languages = getState().languages;

    patchState({
      currentLanguage: languages.find((language) => language.acronym === browserLanguage) || languages.find((language) => language.default) || null,
    });
  }
}
