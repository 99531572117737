import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FilterChange } from '../../models/filter-change.model';
import { merge } from 'rxjs';
import { FilterComponent } from '@shared/components/filter/filter.component';
import {isNgContent} from '@angular/compiler';
import {checkFilter, filterLength, loadSavedFilter, saveFilter} from '@shared/utils/utils';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'saf-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent implements AfterViewInit {
  @ViewChildren(FilterComponent) viewFilters: QueryList<FilterComponent>;
  @ContentChildren(FilterComponent) contentFilters: QueryList<FilterComponent>;
  @Output() filterChange: EventEmitter<any> = new EventEmitter();
  @Input() collapsible = true;
  @Input() opened = false;
  @Input() autofocusSearch = false;
  @Input() placeholder: string;
  @Input() listName: string;
  @Input() disabled: boolean = false;
  @Input() preSearch: string;
  @Input() isDialog: boolean = false;
  @Input() searchFilterParam = 'fastSearch';
  public filterParams: object = {};

  get filtersActive(): boolean {
    return Object.keys(this.filterParams).length > 0;
  }

  ngOnInit() {
    if (loadSavedFilter(this.listName) && this.listName !== undefined) {
      this.filterParams = loadSavedFilter(this.listName);
    }
  }

  ngAfterViewInit() {
    merge(
      ...this.viewFilters.map((filter) => filter.valueChange),
      ...this.contentFilters.map((filter) => filter.valueChange),
    ).subscribe((change: FilterChange) => this.setFilterParam(change.param, change.value));

    merge(
      ...this.viewFilters.map((filter) => filter.valueReset),
      ...this.contentFilters.map((filter) => filter.valueReset),
    ).subscribe((name: string) => this.resetFilterParam(name));
  }

  toggle() {
    this.opened = !this.opened;
  }

  setFilterParam(key: string, value: any) {
    if (this.listName !== undefined) {
      this.filterParams = loadSavedFilter(this.listName);
    }
    const filterParams = { ...this.filterParams, [key]: value };

    if (value === undefined || value === null) {
      delete filterParams[key];
    }

    if (this.paramsChanged(filterParams)) {
      this.filterParams = filterParams;
      if (this.listName !== undefined) {
        saveFilter(filterParams, this.listName);
      }
      this.filterChange.emit(filterParams);
    }
  }

  resetFilterParam(key: string) {
    if (key in this.filterParams) {
      delete this.filterParams[key];
      this.filterChange.emit(this.filterParams);
    }
  }

  paramsChanged(filterParams): boolean {
    if (Object.keys(this.filterParams).length !== Object.keys(filterParams).length) {
      return true;
    }

    for (const [key, value] of Object.entries(filterParams)) {
      if (this.filterParams[key] !== value) {
        return true;
      }
    }

    return false;
  }

  clearFilters() {
    const filters = [
      ...this.viewFilters.toArray(),
      ...this.contentFilters.toArray(),
    ];
    for (const filter of filters) {
      filter.clear();
    }
    this.filterChange.emit(this.filterParams);
  }
}
