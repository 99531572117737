import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { HazardIconSelectComponent } from './hazard-icon-select.component';
import { HazardIconDialogModule } from '@shared/components/hazard-icon-dialog/hazard-icon-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    HazardIconDialogModule,
  ],
  declarations: [
    HazardIconSelectComponent,
  ],
  exports: [
    HazardIconSelectComponent,
  ],
})
export class HazardIconSelectModule {}
