import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '@shared/services/translation.service';
import { isDisplayMode } from '@shared/utils/utils';
import { NotificationService } from '@shared/services/notification.service';
import { SystemNotification } from '@shared/models/system-notification.model';

@Injectable()
export class PwaService {
  private promptEvent: any;
  private promptShown: boolean = false;
  private installNotification: SystemNotification = null;

  constructor(
    private swUpdate: SwUpdate,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
    private notificationService: NotificationService,
  ) {
    this.checkForInstallation();
  }

  checkForInstallation() {
    if (!isDisplayMode('standalone')) {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
        this.promptEvent = event;

        if (!this.promptShown) {
          this.installNotification = this.notificationService.addNotification({
            message: this.translationService.translate('appCanBeInstalled'),
            action: () => this.promptInstallation(),
            icon: 'get_app',
          });
        }
      });

      window.addEventListener('appinstalled', () => this.notificationService.removeNotification(this.installNotification));
    }
  }

  canBeInstalled() {
    return !isDisplayMode('standalone') && this.promptEvent;
  }

  promptInstallation() {
    if (this.promptEvent) {
      this.promptEvent.prompt();
      this.promptEvent.userChoice.then((result: { outcome: 'dismissed' | 'accepted', platform: string }) => {
        this.promptShown = true;

        if (result.outcome === 'accepted') {
          this.snackBar.open(this.translationService.translate('appIsInstalling'));
          this.promptEvent = null;
        }
      });
    }
  }

  checkForUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.swUpdate.activateUpdate().then(() => {
          this.snackBar.open(this.translationService.translate('appHasBeenUpdated'), this.translationService.translate('reload'), { duration: 0 })
            .onAction()
            .subscribe(() => {
              localStorage.removeItem('currentGame');
              window.location.reload();
            });
        })
      });

      this.swUpdate.checkForUpdate();
    }
  }
}
