import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {QualificationFilterComponent} from './qualification-filter.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {QualificationSelectModule} from '@shared/components/qualification-select/qualification-select.module';



@NgModule({
  declarations: [QualificationFilterComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    QualificationSelectModule
  ],
  exports: [QualificationFilterComponent]
})
export class QualificationFilterModule { }
