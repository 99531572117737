import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { TerminalPageState } from '@terminal/store/terminal-page/terminal-page.state';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedInTerminalGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(): boolean {
    return this.checkLogin();
  }

  canActivateChild(): boolean {
    return this.checkLogin();
  }

  canLoad(): boolean {
    return this.checkLogin();
  }

  private checkLogin(): boolean {
    if (!this.store.selectSnapshot(TerminalPageState.authenticated)) {
      this.router.navigateByUrl('/terminal/login');

      return false;
    }

    return true;
  }
}
