import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { DataResponse, ListResponse, SuccessResponse } from '@shared/models/response.model';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { InspectionListItem } from '@shared/models/inspection-list-item.model';
import { Inspection } from '@shared/models/inspection.model';
import { InspectionDetails } from '@shared/models/inspection-details.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class InspectionService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<InspectionListItem>> {
    return this.api.get(`/inspection-list`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getAll(params?: object): Observable<ListResponse<Inspection>> {
    return this.api.get(`/inspections`, params);
  }

  getById(id): Observable<Inspection> {
    return this.api.get(`/inspection/${id}`);
  }

  getDetails(id): Observable<InspectionDetails> {
    return this.api.get(`/inspection-details/${id}`);
  }

  getExportURL(filter: object, sortingParams: object, jwt: string) {
    return `${this.api.apiURL}/inspection-list/export?filter=${JSON.stringify(filter)}&${this.api.getSortingStringForExport(sortingParams)}&jwt=${jwt}`;
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Inspection>> {
    return this.api.post(`/inspection`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<Inspection>> {
    return this.api.put(`/inspection/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/inspection/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  updateEmployees(id: number, data: object): Observable<SuccessResponse> {
    return this.api.put(`/inspection/${id}/employees`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  deleteEmployee(id: number, data: object): Observable<SuccessResponse> {
    return this.api.delete(`/inspection/${id}/employee`, data);
  }

  getPrintURL(id: number, jwt: string): string {
    return `${this.api.apiURL}/inspection/${id}/print?jwt=${jwt}`;
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
