<a *ngIf="router.url !=='/dashboard'" routerLink="/dashboard" class="breadcrumb">{{ 'dashboard' | translate }}</a>

<mat-icon *ngIf="router.url !=='/dashboard'" class="breadcrumb-separator">chevron_right</mat-icon>
<a *ngIf="subRoutes()" routerLink="/navigation/helper" [queryParams]="{navigation: 'safety-Ehs'}" class="breadcrumb">{{ 'safety-Ehs' | translate }}</a>
<mat-icon *ngIf="subRoutes()" class="breadcrumb-separator">chevron_right</mat-icon>
<a *ngIf="!hideBreadCrumb()" routerLink="/navigation/helper" [queryParams]="{navigation: getCurrentRouteLabel()}" class="breadcrumb">{{ getCurrentRouteLabel() | translate }}</a>
<mat-icon *ngIf="!hideBreadCrumb()" class="breadcrumb-separator">chevron_right</mat-icon>
<ng-container *ngFor="let breadcrumb of (breadcrumbs$ | async); let isLast = last">
  <a *ngIf="!isLast && !(isXSmall$ | async)" [routerLink]="[breadcrumb.url]" class="breadcrumb">{{ breadcrumb.label | translate }}</a>
  <span *ngIf="isLast" class="breadcrumb">{{ currentBreadcrumb$ | async }}</span>
  <mat-icon *ngIf="!isLast && !(isXSmall$ | async)" class="breadcrumb-separator">chevron_right</mat-icon>
</ng-container>
