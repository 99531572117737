import { NgModule } from '@angular/core';
import { HazardAssessmentTemplateCategoryFilterComponent } from './hazard-assessment-template-category-filter.component';
import { HazardAssessmentTemplateCategorySelectModule } from '@shared/components/hazard-assessment-template-category-select/hazard-assessment-template-category-select.module';
import { FilterModule } from '@shared/components/filter/filter.module';

@NgModule({
  imports: [
    HazardAssessmentTemplateCategorySelectModule,
    FilterModule,
  ],
  declarations: [
    HazardAssessmentTemplateCategoryFilterComponent,
  ],
  exports: [
    HazardAssessmentTemplateCategoryFilterComponent,
  ],
})
export class HazardAssessmentTemplateCategoryFilterModule {}
