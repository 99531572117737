import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ObservationTypeFilterComponent} from '@shared/components/observation-type-filter/observation-type-filter.component';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FilterModule} from '@shared/components/filter/filter.module';
import {ObservationTypeSelectModule} from '@shared/components/observation-type-select/observation-type-select.module';

@NgModule({
  declarations: [
    ObservationTypeFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    FilterModule,
    ObservationTypeSelectModule
  ],
  exports: [
    ObservationTypeFilterComponent
  ]
})
export class ObservationTypeFilterModule { }
