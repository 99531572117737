import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreaLocationFilterComponent } from './area-location-filter.component';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FilterModule} from '@shared/components/filter/filter.module';
import {EquipmentCategorySelectModule} from '@shared/components/equipment-category-select/equipment-category-select.module';
import {AreaLocationSelectModule} from '@shared/components/area-location-select/area-location-select.module';



@NgModule({
  declarations: [
    AreaLocationFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    FilterModule,
    EquipmentCategorySelectModule,
    AreaLocationSelectModule
  ],
  exports: [
    AreaLocationFilterComponent
  ]
})
export class AreaLocationFilterModule { }
