import { NgModule } from '@angular/core';
import { FilterModule } from '@shared/components/filter/filter.module';
import { ExternalCompanyCategorySelectModule } from '@shared/components/external-company-category-select/external-company-category-select.module';
import { ExternalCompanyCategoryFilterComponent } from './external-company-category-filter.component';

@NgModule({
  imports: [
    ExternalCompanyCategorySelectModule,
    FilterModule,
  ],
  declarations: [
    ExternalCompanyCategoryFilterComponent,
  ],
  exports: [
    ExternalCompanyCategoryFilterComponent,
  ],
})
export class ExternalCompanyCategoryFilterModule {}
