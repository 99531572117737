import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowFromSubCategoryFilterComponent } from './show-from-sub-category-filter.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FilterModule } from '@shared/components/filter/filter.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    FilterModule,
  ],
  declarations: [
    ShowFromSubCategoryFilterComponent,
  ],
  exports: [
    ShowFromSubCategoryFilterComponent,
  ],
})
export class ShowFromSubCategoryFilterModule {}
