import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {tap} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '@shared/services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslationService} from '@shared/services/translation.service';

@Component({
  selector: 'saf-captcha-dialog',
  templateUrl: './captcha-dialog.component.html',
  styleUrls: ['./captcha-dialog.component.scss']
})
export class CaptchaDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;
  public captchaText: string;

  constructor(
    private dialogRef: MatDialogRef<CaptchaDialogComponent>,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,

  ) { }

  ngOnInit(): void {
    this.generateCaptcha();
    this.buildForm();
  }
  buildForm() {
    this.formGroup = new UntypedFormGroup({
      captcha: new UntypedFormControl(),
    });
  }

  getRandomString(length: number, listOfChars: string) {
    let text = '';
    for (let i = 0; i < length; i++) {
      text += listOfChars.charAt(Math.floor(Math.random() * listOfChars.length));
    }
    return text;
  }
  generateCaptcha() {
    const listOfChars = '1234567890';
    const lengthOfCode = 5;

    this.captchaText = this.getRandomString(lengthOfCode, listOfChars);
  }

  close() {
    this.dialogRef.close(false);
  }

  submit() {
    if (this.formGroup.valid) {
      if (this.captchaText === this.formGroup.get('captcha').value) {
        this.dialogRef.close(this.captchaText === this.formGroup.get('captcha').value);
      } else {
        this.snackBar.open(this.translationService.translate('compareCaptchaWrong'));
        this.generateCaptcha();

      }
    } else {
      this.snackBar.open(this.translationService.translate('enterCaptchaTerm'));
    }
  }

}
