import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccidentCause } from '@shared/models/accident-cause.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccidentCauseService } from '@shared/services/accident-cause.service';
import { getDirtyState } from '@shared/utils/utils';
import { tap } from 'rxjs/operators';
import { DataResponse } from '@shared/models/response.model';

@Component({
  selector: 'saf-accident-cause-dialog',
  templateUrl: './accident-cause-dialog.component.html',
  styleUrls: ['./accident-cause-dialog.component.scss']
})
export class AccidentCauseDialogComponent implements OnInit {
  accidentCause: AccidentCause;
  formGroup: UntypedFormGroup;
  loading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AccidentCauseDialogComponent>,
    private accidentCauseService: AccidentCauseService,
    @Inject(MAT_DIALOG_DATA) public data?: object,
  ) { }

  ngOnInit() {
    this.accidentCause = new AccidentCause(this.data || new AccidentCause());
    this.buildForm(this.accidentCause);
  }

  buildForm(accidentCause: AccidentCause) {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(accidentCause.name, Validators.required),
    });
  }

  submitForm() {
    if (this.formGroup.valid) {
      const request = this.accidentCause.id
        ? this.accidentCauseService.update(this.accidentCause.id, getDirtyState(this.formGroup))
        : this.accidentCauseService.create(getDirtyState(this.formGroup));

      this.loading = true;

      request
        .pipe(tap(() => this.loading = false))
        .subscribe((result) => this.handleResult(result));
    }
  }

  handleResult(result: DataResponse<AccidentCause>) {
    if (result.success) {
      this.close(true);
    }
  }

  close(result: boolean = false) {
    this.dialogRef.close(result);
  }

}
