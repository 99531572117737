import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {DataResponse, ListResponse, SuccessResponse} from '../models/response.model';
import {Topic, TopicDetails, TopicStatusModel} from '../models/topic.model';
import {TopicBooklet} from '../models/topic-booklet.model';

const cacheNotifier: Subject<void> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class TopicService {

  constructor(private api: ApiService) {
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<Topic>> {
    return this.api.get(`/topics`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getListDetails(params?: object): Observable<ListResponse<TopicDetails>> {
    return this.api.get(`/topic-list`, params);
  }

  getTopicStatusModels(params?: object): Observable<ListResponse<TopicStatusModel>> {
    return this.api.get(`/topicStatusModel-list`, params);
  }

  getById(id: number): Observable<Topic> {
    return this.api.get(`/topic/${id}`);
  }

  getDetail(id: number): Observable<TopicDetails> {
    return this.api.get(`/topic-details/${id}`);
  }

  getExportURL(filter: object, sortingParams: object, jwt: string) {
    return `${this.api.apiURL}/topic-list/export?filter=${JSON.stringify(filter)}&${this.api.getSortingStringForExport(sortingParams)}&jwt=${jwt}`;
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Topic>> {
    return this.api.post(`/topic`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  copy(id: number, data: object): Observable<DataResponse<TopicDetails>> {
    return this.api.post(`/topic/${id}/copy`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<Topic>> {
    return this.api.put(`/topic/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/topic/${id}`);
  }

  createTopicFromImport(file: File, data: object): Observable<DataResponse<Topic>> {
    return this.api.postMulti(file, `/topic/importNew`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  createBookletFromImport(file: File, data: object): Observable<DataResponse<TopicBooklet>> {
    return this.api.postMulti(file, `/booklet/importNew`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  updateBookletFromImport(id: number, file: File, data: object): Observable<DataResponse<TopicBooklet>> {
    return this.api.postMulti(file, `/booklet/${id}/importNew`, data);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
