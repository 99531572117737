import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { HazardTemplate } from '@shared/models/hazard-template.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class HazardTemplateService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(classificationId: number, params?: object): Observable<ListResponse<HazardTemplate>> {
    return this.api.get(`/classificationHazardTemplates`, { classificationId, ...params });
  }

  getById(id: number): Observable<HazardTemplate> {
    return this.api.get(`/classificationHazardTemplate/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<HazardTemplate>> {
    return this.api.post(`/classificationHazardTemplate`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<HazardTemplate>> {
    return this.api.put(`/classificationHazardTemplate/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/classificationHazardTemplate/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
