import { NgModule } from '@angular/core';
import { RoleSelectComponent } from '@app/shared/components/role-select/role-select.component';
import { MaterialModule } from '@shared/material.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
  ],
  declarations: [
    RoleSelectComponent,
  ],
  exports: [
    RoleSelectComponent,
  ],
})
export class RoleSelectModule {}
