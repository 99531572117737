export class AccidentCause {
  id: number = null;
  name: string = '';
  editAble: boolean = true;
  numberInUse: number = null;

  constructor(config?: object) {
    if (config) {
      Object.keys(config)
      .filter(key => typeof config[key] !== 'undefined')
      .forEach(key => this[key] = config[key]);
    }
  }
}
