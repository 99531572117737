import { Injectable } from '@angular/core';
import {AppConfigService} from './app-config.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable()
export class AssetService {

  get apiURL() {
    return this.appConfigService.appConfig.apiURL;
  }

  constructor(private appConfigService: AppConfigService,
              private apiService: ApiService,
              private http: HttpClient) {}

  getAssetURL(path?: string) {
    return path ? `${this.apiURL}${path}` : '';
  }

  getHazardGroupImage(imagePath: string) {
    if (!imagePath || imagePath === 'default/no_icon.png') {
      return '';
    }

    if (imagePath.startsWith('/uploads')) {
      return this.getAssetURL(imagePath);
    }

    return this.getAssetURL(`/uploads/gbuRisc/${imagePath}`);
  }

  getHazardIconImage(type: string, imagePath: string) {
    if (!type || !imagePath) {
      return '';
    }

    return this.getAssetURL(`/uploads/hazardIcons/${type}/${imagePath}`);
  }
   downloadFile(url: string, headers?: Object): Observable<Blob> {
    return this.http.get<Blob>(`${this.apiURL}${url}`, {headers: ApiService.createHeadersPDF(headers), responseType: 'blob' as 'json'});
  }
}
