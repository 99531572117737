import {Component, OnInit} from '@angular/core';
import { Select } from '@ngxs/store';
import { BreakpointState } from '@shared/store/breakpoint/breakpoint.state';
import { BreadcrumbState } from '@shared/store/breadcrumb/breadcrumb.state';
import {ActivatedRoute, PRIMARY_OUTLET, Router} from '@angular/router';
import {NavigationComponent} from '@frontend/components/navigation/navigation.component';

@Component({
  selector: 'saf-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit{

  public navigation;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private navigationComponent: NavigationComponent,
  ) {
  }
  @Select(BreakpointState.isXSmall) isXSmall$;
  @Select(BreadcrumbState.breadcrumbs) breadcrumbs$;
  @Select(BreadcrumbState.currentBreadcrumb) currentBreadcrumb$;

  ngOnInit() {

  }

  getBreadCrumbs(string: any) {

  }

  getCurrentRouteLabel(): string {
    this.navigationComponent.getCategories().forEach(navigation => {
      navigation.routes.forEach(subNavigation => {
       if (this.router.url.includes(subNavigation.path)) {
         this.navigation = subNavigation.navigation;
       }
      });
    });
    return this.navigation;
  }

  hideBreadCrumb() {
    return (this.router.url.includes('navigation') || this.router.url.includes('dashboard') || this.router.url.includes('settings') || this.router.url.includes('translations') || this.router.url.includes('online/start') || this.router.url.includes('app/schooling'));
  }

  subRoutes() {
    if (this.router.url.includes('navigation')) {
      return this.router.url.includes('miscellaneous') || this.router.url.includes('administration');
    } else {
      const routes = this.route.children;

      // for (const route of routes) {
      //   if (route.outlet !== PRIMARY_OUTLET) {
      //     continue;
      //   }
      //
      //   if (route.snapshot.data.color) {
      //    return route.snapshot.data.module === 'userAdministration' || route.snapshot.data.module === 'permissionSettings';
      //   }
      // }
    }
  }
}
