import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopicFilterComponent } from './topic-filter.component';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FilterModule} from '@shared/components/filter/filter.module';
import {TopicSelectModule} from '@shared/components/topic-select/topic-select.module';



@NgModule({
    declarations: [
        TopicFilterComponent
    ],
    exports: [
        TopicFilterComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      MaterialModule,
      PipesModule,
      FilterModule,
      TopicSelectModule
    ]
})
export class TopicFilterModule { }
