import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalCompanySelectDialogComponent } from './external-company-select-dialog.component';
import {MaterialModule} from "@shared/material.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {EmptyMessageModule} from "@shared/components/empty-message/empty-message.module";
import {LoadingSpinnerModule} from "@shared/components/loading-spinner/loading-spinner.module";
import {LoadingOverlayModule} from "@shared/components/loading-overlay/loading-overlay.module";
import {FilterPanelModule} from "@shared/components/filter-panel/filter-panel.module";
import {PipesModule} from "@shared/pipes/pipes.module";
import {CompanyFilterModule} from "@shared/components/company-filter/company-filter.module";
import {
  ExternalCompanyCategoryFilterModule
} from "@shared/components/external-company-category-filter/external-company-category-filter.module";



@NgModule({
  declarations: [
    ExternalCompanySelectDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    EmptyMessageModule,
    LoadingSpinnerModule,
    LoadingOverlayModule,
    FilterPanelModule,
    PipesModule,
    CompanyFilterModule,
    ExternalCompanyCategoryFilterModule
  ],
  exports: [
    ExternalCompanySelectDialogComponent
  ]
})
export class ExternalCompanySelectDialogModule { }
