import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CredentialSelectComponent } from './credential-select.component';
import {MaterialModule} from '@shared/material.module';
import {FormsModule} from '@angular/forms';
import {PipesModule} from '@shared/pipes/pipes.module';



@NgModule({
  declarations: [
    CredentialSelectComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    PipesModule
  ],
  exports: [
    CredentialSelectComponent
  ]
})
export class CredentialSelectModule { }
