import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionQuickViewDirective } from './section-quick-view.directive';
import { SectionQuickViewCardComponent } from './section-quick-view-card/section-quick-view-card.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../material.module';
import {PipesModule} from '../../pipes/pipes.module';



@NgModule({
    declarations: [SectionQuickViewDirective, SectionQuickViewCardComponent],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        PipesModule,
    ],
    exports: [
        SectionQuickViewCardComponent,
        SectionQuickViewDirective
    ]
})
export class SectionQuickViewModule { }
