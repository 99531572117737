import { noop, uuid } from '@shared/utils/utils';

export class SystemNotification {
  id: string = uuid();
  message: string = '';
  action: Function = noop;
  icon: string = 'info';
  seen: boolean = false;
  date: string | null = null;
  data: any = {};

  constructor(config?: Partial<SystemNotification>) {
    if (config) {
      Object.keys(config)
        .filter(key => typeof config[key] !== 'undefined')
        .forEach(key => this[key] = config[key]);
    }
  }
}
