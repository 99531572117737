import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterChange } from '@shared/models/filter-change.model';

@Component({
  selector: 'saf-filter',
  template: '',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  @Output() valueChange = new EventEmitter<FilterChange>();
  @Output() valueReset = new EventEmitter<string>();
  @Input() filterParam: string;
  @Input() placeholder: string;

  clear() {}
}
