import { Component, ElementRef, HostListener, OnDestroy, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { fadeInOut } from '@shared/utils/animations';
import { BehaviorSubject } from 'rxjs';

export class SuggestionOption {
  label: string;
  value: any;

  constructor(config?: SuggestionOption) {
    if (config) {
      Object.keys(config)
        .filter(key => typeof config[key] !== 'undefined')
        .forEach(key => this[key] = config[key]);
    }
  }
}

export class SuggestionGroup {
  label: string;
  options: SuggestionOption[];

  constructor(config?: SuggestionGroup) {
    if (config) {
      Object.keys(config)
        .filter(key => typeof config[key] !== 'undefined')
        .forEach(key => this[key] = config[key]);
    }
  }
}

export type SearchSuggestions = SuggestionGroup[];

const SUGGESTIONS = [{
  label: 'Mitarbeiter',
  options: [
    { value: 1, label: 'Max Mustermann' },
    { value: 2, label: 'Rainer Zufall' },
  ]
}, {
  label: 'Abteilung',
  options: [
    { value: 3, label: 'Personalverwaltung' },
    { value: 4, label: 'Finanzverwaltung' },
  ]
}];

@Component({
  selector: 'saf-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  animations: [fadeInOut],
})
export class SearchBarComponent implements OnDestroy {
  @ViewChild('container') container: ElementRef;
  @ViewChild('template') template: TemplateRef<any>;
  @ViewChild('input') input: ElementRef;
  public active: boolean = false;
  public activeOverlay: OverlayRef;
  public searchText: string = '';
  public suggestions$ = new BehaviorSubject<SearchSuggestions>([]);
  public lastSearchText = '';

  constructor(
    private overlay: Overlay,
    private viewContainer: ViewContainerRef,
  ) {}

  open() {
    if (!this.active) {
      const overlayConfig = new OverlayConfig({
        hasBackdrop: true,
        positionStrategy: this.overlay.position()
          .flexibleConnectedTo(this.container)
          .withPositions([{
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'bottom'
          }]),
      });

      this.activeOverlay = this.overlay.create(overlayConfig);
      this.activeOverlay.attach(new TemplatePortal(this.template, this.viewContainer));
      this.activeOverlay.backdropClick().subscribe(() => this.close());
      this.active = true;
    }
  }

  close() {
    if (this.active) {
      this.searchText = '';
      this.reset();
      this.activeOverlay.detach();
      this.activeOverlay = null;
      this.active = false;
    }
  }

  reset() {
    this.lastSearchText = '';
    this.suggestions$.next([]);
  }

  toggle() {
    return this.active ? this.close() : this.open();
  }

  focus() {
    if (this.input) {
      this.input.nativeElement.focus();
    }
  }

  update() {
    const text = this.searchText.toLowerCase();

    if (this.lastSearchText !== text) {
      this.lastSearchText = text;
      this.suggestions$.next(!text.length ? [] : SUGGESTIONS
        .map(({ label, options }) => new SuggestionGroup({ label, options: options.map((option) => new SuggestionOption(option)).filter(({ label }) => label.toLowerCase().includes(text)) }))
        .filter(({ label, options }) => (options.length > 0 || label.toLowerCase().includes(text))));
    }
  }

  submit() {

  }

  onCloseAutocomplete() {
    this.close();
  }

  ngOnDestroy() {
    this.close();
  }
}
