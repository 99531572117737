import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Select, Store } from '@ngxs/store';
import { TerminalState } from '@shared/store/terminal/terminal.state';
import { LoadTerminals } from '@shared/store/terminal/terminal.actions';

@Component({
  selector: 'saf-terminal-select',
  templateUrl: './terminal-select.component.html',
  styleUrls: ['./terminal-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TerminalSelectComponent),
      multi: true
    }
  ],
})
export class TerminalSelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) { this._required = coerceBooleanProperty(value); }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled || this.store.selectSnapshot(TerminalState.loading); }
  set disabled(value: boolean) { this._disabled = coerceBooleanProperty(value); }
  private _disabled = false;

  @Input()
  get multiple(): boolean { return this._multiple; }
  set multiple(value: boolean) { this._multiple = coerceBooleanProperty(value); }
  private _multiple = false;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) { this._placeholder = value; }
  private _placeholder: string;

  @Input()
  get appearance(): MatFormFieldAppearance { return this._appearance; }
  set appearance(value: MatFormFieldAppearance) { this._appearance = value; }
  private _appearance: MatFormFieldAppearance = 'outline';

  @Input()
  get value(): any { return this._value; }
  set value(value: any) { this.writeValue(value); }
  private _value: any = null;

  get clearIcon(): boolean {
    return this.multiple || !this.required;
  }

  get hasValue(): boolean {
    return this.multiple ? this.value && this.value.length : this.value !== null;
  }

  @Select(TerminalState.terminals) terminals$;

  @Output() valueChange = new EventEmitter<any>();

  onChange: Function = () => {};
  onTouched: Function = () => {};

  constructor(private store: Store) {}

  ngOnInit() {
    setTimeout(() => {
      this.store.dispatch(new LoadTerminals());
    }, 200);
  }

  registerOnChange(onChange: Function) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.valueChange.emit(value);
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  clear() {
    this.value = this.multiple ? [] : null;
  }

  onClickClear(event) {
    this.clear();
    event.stopImmediatePropagation();
  }
}
