import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ApiService} from '@shared/services/api.service';
import {DataResponse, ListResponse, SuccessResponse} from '@shared/models/response.model';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {ChecklistCategory} from '@shared/models/checklist-category';
import {ChecklistDetails} from '@shared/models/checklist-details.model';
import {ChecklistRevisionDetailsModel} from '@shared/models/checklist-revision-details.model';
import {ChecklistRevisionModel} from '@shared/models/checklist-revision.model';
import {ChecklistFieldModel} from '@shared/models/checklist-field.model';
import {ChecklistFieldgroupModel} from '@shared/models/checklist-fieldgroup.model';
import {ChecklistExecutionDetailModel} from '@shared/models/checklist-execution-detail.model';
import {ChecklistFieldOptionModel} from '@shared/models/checklist-field-option.model';
import {ChecklistValuesModel} from '@shared/models/checklist-values.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  constructor(private api: ApiService) {
  }

  getDetailById(id): Observable<ChecklistDetails> {
    return this.api.get(`/checklist-detail/${id}`);
  }


  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<ChecklistDetails>> {
    return this.api.get(`/checklist-list`, params);
  }


  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<ChecklistDetails>> {
    return this.api.post(`/checklist-detail`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<ChecklistDetails>> {
    return this.api.put(`/checklist-detail/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  copy(id: number, data: object): Observable<DataResponse<ChecklistDetails>> {
    return this.api.put(`/checklist/${id}/copy`, data);
  }


  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/checklist/${id}`);
  }


  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getRevision(revisionId: number): Observable<ChecklistRevisionDetailsModel> {
    return this.api.get(`/checklist/revision/${revisionId}/details`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  addRevision(data: object): Observable<DataResponse<ChecklistRevisionModel>> {
    return this.api.post(`/checklist/revision`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  saveRevision(id: number, data: object): Observable<DataResponse<ChecklistRevisionModel>> {
    return this.api.put(`/checklist/revision/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delteRevision(id: number): Observable<DataResponse<ChecklistRevisionModel>> {
    return this.api.delete(`/checklist/revision/${id}`);
  }
/////////////////////////// Checklist Execution /////////////////////////////////////////////
  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  executionList(data: object): Observable<ListResponse<ChecklistExecutionDetailModel>> {
    return this.api.get(`/checklist/execution-list`, data);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getExecutionById(id: number): Observable<ChecklistExecutionDetailModel> {
    return this.api.get(`/checklist/execution-detail/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  addExecution(data: object): Observable<DataResponse<ChecklistExecutionDetailModel>> {
    return this.api.post(`/checklist/execution`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  deleteExecution(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/checklist/execution/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  saveExecutionValues(id: number, data: object): Observable<DataResponse<ChecklistExecutionDetailModel>> {
    return this.api.put(`/checklist/execution/${id}/values`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  measureToValue(data: object): Observable<DataResponse<ChecklistValuesModel>> {
    return this.api.post(`/checklist/value/measure`, data);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getValues(params?: object): Observable<ListResponse<ChecklistValuesModel>> {
    return this.api.get(`/checklist/values`, params);
  }

  //////////////////////////// field Service ////////////////////////////////////

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getFieldList(data?: object): Observable<ListResponse<ChecklistFieldModel>> {
    return this.api.get(`/checklist/fields`, data);
  }

  @Cacheable({
      cacheBusterObserver: cacheNotifier
    }
  )
  getField(id: number): Observable<ChecklistFieldModel> {
    return this.api.get(`/checklist/field/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  addField(data: object): Observable<DataResponse<ChecklistFieldModel>> {
    return this.api.post(`/checklist/field`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  updateField(id: number, data: object): Observable<DataResponse<ChecklistFieldModel>> {
    return this.api.put(`/checklist/field/${id}`, data);
  }


  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  deleteField(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/checklist/field/${id}`);
  }

  //////////////////////////////// FieldGroupService ////////////////////////////////////////////////////////

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getFieldGroupList(data?: object): Observable<ListResponse<ChecklistFieldgroupModel>> {
    return this.api.get(`/checklist/fieldGroups`, data);
  }

  @Cacheable({
      cacheBusterObserver: cacheNotifier
    }
  )
  getFieldGroup(id: number): Observable<ChecklistFieldgroupModel> {
    return this.api.get(`/checklist/fieldGroup/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  addFieldGroup(data: object): Observable<DataResponse<ChecklistFieldgroupModel>> {
    return this.api.post(`/checklist/fieldGroup`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  updateFieldGroup(id: number, data: object): Observable<DataResponse<ChecklistFieldgroupModel>> {
    return this.api.put(`/checklist/fieldGroup/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  deleteFieldGroup(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/checklist/fieldGroup/${id}`);
  }

/////////////////////////// Checklist Category /////////////////////////////////////////////

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getCategoryList(params?: object): Observable<ListResponse<ChecklistCategory>> {
    return this.api.get(`/checklist/categories`, params);
  }


  getCategoryById(id: number): Observable<ChecklistCategory> {
    return this.api.get(`/checklist/category/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  addCategory(data: object): Observable<DataResponse<ChecklistCategory>> {
    return this.api.post(`/checklist/category`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  updateCategory(id: number, data: object): Observable<DataResponse<ChecklistCategory>> {
    return this.api.put(`/checklist/category/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  deleteCategory(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/checklist/category/${id}`);
  }

  /////////////////////////// Checklist FieldOptions /////////////////////////////////////////////
  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getFieldOptionList(params?: object): Observable<ListResponse<ChecklistFieldOptionModel>> {
    return this.api.get(`/checklist/fieldOptions`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getFieldOption(id: number): Observable<ChecklistFieldOptionModel> {
    return this.api.get(`/checklist/fieldOption/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  addFieldOptions(data: object): Observable<DataResponse<ChecklistFieldOptionModel>> {
    return this.api.post(`/checklist/fieldOption`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  updateFieldOptions(id: number, data: object): Observable<DataResponse<ChecklistFieldOptionModel>> {
    return this.api.put(`/checklist/fieldOption/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delteFieldOption(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/checklist/fieldOption/${id}`);
  }

  getPrintUrl(id: number, empty: boolean, jwt: string) {
    return `${this.api.apiURL}/checklist/execution/${id}/print?empty=${empty}&jwt=${jwt}`;
  }

  getPrintUrlNew(id: number, empty: boolean, jwt: string) {
    return `${this.api.apiURL}/checklist/execution/new/${id}/print?empty=${empty}&jwt=${jwt}`;
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
