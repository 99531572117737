import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { DataResponse, SuccessResponse } from '@shared/models/response.model';
import { TerminalActivity } from '@shared/models/terminal-activity.model';

@Injectable({
  providedIn: 'root'
})
export class TerminalActivityService {

  constructor(private api: ApiService) {}

  getById(id): Observable<TerminalActivity> {
    return this.api.get(`/external/terminalActivity/${id}`);
  }

  getByTerminalId(terminalId): Observable<TerminalActivity> {
    return this.api.get(`/external/terminalActivity/open/${terminalId}`);
  }

  create(data: object): Observable<DataResponse<TerminalActivity>> {
    return this.api.post(`/external/terminalActivity`, data);
  }

  update(id: number, data: object): Observable<DataResponse<TerminalActivity>> {
    return this.api.put(`/external/terminalActivity/${id}`, data);
  }

  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/external/terminalActivity/${id}`);
  }
}
