import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from '@shared/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FilterModule} from '@shared/components/filter/filter.module';
import {DefaultBooleanFilterComponent} from '@shared/components/default-boolean-filter/default-boolean-filter.component';



@NgModule({
    declarations: [
      DefaultBooleanFilterComponent
    ],
    exports: [
      DefaultBooleanFilterComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      MaterialModule,
      PipesModule,
      FilterModule
    ]
})
export class DefaultBooleanFilterModule { }
