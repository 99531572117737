import { NgModule } from '@angular/core';
import { RoleSelectModule } from '../role-select/role-select.module';
import { RoleFilterComponent } from './role-filter.component';
import { FilterModule } from '@shared/components/filter/filter.module';

@NgModule({
  imports: [
    RoleSelectModule,
    FilterModule,
  ],
  declarations: [
    RoleFilterComponent,
  ],
  exports: [
    RoleFilterComponent,
  ],
})
export class RoleFilterModule {}
