import { NgModule } from '@angular/core';
import { FileManagementPanelComponent } from './file-management-panel.component';
import { MaterialModule } from '@shared/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FileRenameDialogModule } from '@shared/components/file-rename-dialog/file-rename-dialog.module';
import { DragAndDropPanelModule } from '@shared/components/drag-and-drop-panel/drag-and-drop-panel.module';
import { CommonModule } from '@angular/common';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';
import { ImageQuickViewModule } from '@shared/components/image-quick-view/image-quick-view.module';
import {FilterPanelModule} from "@shared/components/filter-panel/filter-panel.module";
import {FileCategoryFilterModule} from "@shared/components/file-category-filter/file-category-filter.module";
import {DepartmentFilterModule} from "@shared/components/department-filter/department-filter.module";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    PipesModule,
    DragAndDropPanelModule,
    FileRenameDialogModule,
    LoadingOverlayModule,
    ImageQuickViewModule,
    FilterPanelModule,
    FileCategoryFilterModule,
    DepartmentFilterModule,
  ],
  declarations: [
    FileManagementPanelComponent,
  ],
  exports: [
    FileManagementPanelComponent,
  ],
})
export class FileManagementPanelModule {}
