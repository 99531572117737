import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { DataResponse, ListResponse, SuccessResponse } from '@shared/models/response.model';
import { AccidentCause } from '../models/accident-cause.model';
import {Cacheable, CacheBuster} from 'ts-cacheable';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class AccidentCauseService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<AccidentCause>> {
    return this.api.get(`/accidentCauses`, params);
  }

  getById(id): Observable<AccidentCause> {
    return this.api.get(`/accidentCause/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<AccidentCause>> {
    return this.api.post(`/accidentCause`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<AccidentCause>> {
    return this.api.put(`/accidentCause/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/accidentCause/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
