import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeasureDialogComponent } from './measure-dialog.component';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';
import { DateSelectModule } from '@shared/components/date-select/date-select.module';
import { DepartmentSelectModule } from '@shared/components/department-select/department-select.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmployeeSelectModule } from '@shared/components/employee-select/employee-select.module';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner/loading-spinner.module';
import { HazardIconSelectModule } from '@shared/components/hazard-icon-select/hazard-icon-select.module';
import {MeasureTypeSelectModule} from '@shared/components/measure-type-select/measure-type-select.module';
import {CreateDateModule} from '@shared/components/create-date/create-date.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        EmployeeSelectModule,
        DateSelectModule,
        HazardIconSelectModule,
        LoadingSpinnerModule,
        LoadingOverlayModule,
        DepartmentSelectModule,
        MeasureTypeSelectModule,
        CreateDateModule,
    ],
    declarations: [
        MeasureDialogComponent,
    ],
    exports: [
        MeasureDialogComponent,
    ]
})
export class MeasureDialogModule {}
