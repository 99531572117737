import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Terminal } from '@shared/models/terminal.model';
import { TerminalActivity } from '@shared/models/terminal-activity.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import {
  AddExternalCompanyDetails,
  AddExternalCompanyDetailsError,
  AddExternalCompanyDetailsSuccess,
} from '@terminal/store/terminal-page/terminal-page.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '@shared/services/translation.service';
import { TerminalPageState } from '@terminal/store/terminal-page/terminal-page.state';

@Component({
  selector: 'saf-terminal-page-add-external-company-view',
  templateUrl: './terminal-page-add-external-company-view.component.html',
  styleUrls: ['./terminal-page-add-external-company-view.component.scss']
})
export class TerminalPageAddExternalCompanyViewComponent implements OnInit {
  @Input() terminal: Terminal;
  @Input() activity: TerminalActivity;
  @Output() viewCompleted = new EventEmitter();
  form: UntypedFormGroup;

  constructor(
    private store: Store,
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      street: new UntypedFormControl(''),
      zipCode: new UntypedFormControl(''),
      city: new UntypedFormControl(''),
    });

    this.actions$.pipe(ofActionCompleted(AddExternalCompanyDetailsSuccess))
      .subscribe(() => this.viewCompleted.emit());

    this.actions$.pipe(ofActionCompleted(AddExternalCompanyDetailsError))
      .subscribe(() => this.snackBar.open(this.translationService.translate('ERROR')));
  }

  onSubmit() {
    if (this.form.valid) {
      const formData = this.store.selectSnapshot(TerminalPageState.externalCompanyFormData);

      this.store.dispatch(new AddExternalCompanyDetails({
        ...formData,
        companyIds: this.activity.companyId,
        externalCompanyCategoryId: this.activity.externalCategoryId,
        selfLoginAllowed: true,
      }));
    }
  }
}
