import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuSelectionDialogComponent } from './menu-selection-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {PipesModule} from '@shared/pipes/pipes.module';



@NgModule({
  declarations: [
    MenuSelectionDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    PipesModule
  ]
})
export class MenuSelectionDialogModule { }
