import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslationService } from '@shared/services/translation.service';

export class PaginatorI18n {

  constructor(private readonly translationService: TranslationService) {}

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = this.translationService.translate('itemsPerPage', 'paginator');
    paginatorIntl.nextPageLabel = this.translationService.translate('nextPage', 'paginator');
    paginatorIntl.previousPageLabel = this.translationService.translate('previousPage', 'paginator');
    paginatorIntl.firstPageLabel = this.translationService.translate('firstPage', 'paginator');
    paginatorIntl.lastPageLabel = this.translationService.translate('lastPage', 'paginator');
    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);

    return paginatorIntl;
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return this.translationService.translate('rangeLabelFirstPage', 'paginator', length);
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translationService.translate('rangeLabel', 'paginator', startIndex + 1, endIndex, length);
  }
}
