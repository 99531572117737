import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetDefaultTheme, SetTheme } from '@shared/store/theme/theme.actions';
import { Theme } from '@shared/models/theme.model';
import {Injectable} from '@angular/core';

const THEMES = [
  {
    active: true,
    class: 'theme-light',
    default: false,
    name: 'light',
  },
  {
    active: true,
    class: 'theme-dark',
    default: true,
    name: 'dark',
  }
];

export class ThemeStateModel {
  themes: Theme[];
  theme: Theme;
  loading: boolean;
}

@State<ThemeStateModel>({
  name: 'theme',
  defaults: {
    themes: THEMES,
    theme: THEMES.find((theme) => theme.default),
    loading: false,
  }
})
@Injectable()
export class ThemeState {

  @Selector()
  static themes(state: ThemeStateModel) {
    return state.themes;
  }

  @Selector()
  static activeThemes(state: ThemeStateModel) {
    return state.themes.filter((theme) => theme.active);
  }

  @Selector()
  static theme(state: ThemeStateModel) {
    return state.theme;
  }

  @Selector()
  static defaultTheme(state: ThemeStateModel) {
    return state.themes.find((theme) => theme.default);
  }

  @Selector()
  static loading(state: ThemeStateModel) {
    return state.loading;
  }

  @Action(SetTheme)
  setTheme({ patchState }: StateContext<ThemeStateModel>, { theme }: SetTheme) {
    patchState({ theme });
  }

  @Action(SetDefaultTheme)
  setDefaultTheme({ getState, patchState }: StateContext<ThemeStateModel>) {
    patchState({
      theme: getState().themes.find((theme) => theme.default),
    });
  }
}
