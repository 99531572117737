import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  constructor(private translationService: TranslationService) {}

  transform(token: string | number, category?: string, ...values: any[]): any {
    if (token === null || token === undefined) {
      return '';
    }

    return this.translationService.translate(`${token}`, category, ...values);
  }
}
