import { NgModule } from '@angular/core';
import { EmployeeSelectModule } from '../employee-select/employee-select.module';
import { EmployeeFilterComponent } from './employee-filter.component';
import { FilterModule } from '@shared/components/filter/filter.module';

@NgModule({
  imports: [
    EmployeeSelectModule,
    FilterModule,
  ],
  declarations: [
    EmployeeFilterComponent,
  ],
  exports: [
    EmployeeFilterComponent,
  ],
})
export class EmployeeFilterModule {}
