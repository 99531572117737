import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FilterModule } from '@shared/components/filter/filter.module';
import {SendMailFilterComponent} from '@shared/components/send-mail-filter/send-mail-filter.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    FilterModule,
  ],
  declarations: [
    SendMailFilterComponent,
  ],
  exports: [
    SendMailFilterComponent,
  ],
})
export class SendMailFilterModule {}
