import { Injectable } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { browserLanguage } from '@shared/utils/utils';
import { LanguageState } from '@shared/store/language/language.state';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private dictionary = {};

	constructor(private httpClient: HttpClient) {
    this.httpClient.get<any>('https://translator.safety-doors.app/fileupload/uploads/translations.json').subscribe((data) =>
			this.dictionary = data
		);
    }

  @SelectSnapshot(LanguageState.currentLanguage) currentLanguage;
  fallbackLanguage: string = Object.keys(this.dictionary).includes(browserLanguage) ? browserLanguage : 'de';
  missingTranslations: { token: string, category: string }[] = [];
  missingCategories: string[] = [];
  missingLanguages: string[] = [];


  translate(token: string, category: string = 'general', ...values: any[]): string {

    const language = this.currentLanguage ? this.currentLanguage.acronym : this.fallbackLanguage,
      dictionary = this.dictionary[language];

    if (!token) {
      return '';
    }

    if (!dictionary) {
      if (!this.missingLanguages.includes(language)) {
        this.missingLanguages.push(language);

        console.info(`[Missing Translation Language]: language: ${language}`);
      }

      return `#${token}#`;
    }

    if (!(category in dictionary)) {
      if (!this.missingCategories.includes(category)) {
        this.missingCategories.push(category);

        console.info(`[Missing Translation Category]: category: ${category}", language: ${language}`);
      }

      return `#${token}#`;
    }

    if (!(token in dictionary[category])) {
      if (!this.missingTranslations.some((translation) => (translation.token === token && translation.category === category))) {
        this.missingTranslations.push({ token, category });

        console.info(`[Missing Translation Token] token: ${token}, category: ${category}`);
      }

      return `#${token}#`;
    }

    return (values.length > 0) ? this.format(dictionary[category][token], ...values) : dictionary[category][token];
  }

  translateList(...token) {
    return token.map((key) => this.translate(key));
  }

  format(string, ...args) {
    return string.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined') ? args[number] : match);
  }
}
