<div class="closeCross">
  <span mat-dialog-title>{{ title }}</span>
  <mat-icon (click)="dialog.close(false)" class="cross">close</mat-icon>
</div>

<mat-dialog-content>
  <p>{{ message }} <b>{{objectTitle}}</b></p>
  <p>{{ secondMessage }} </p>
</mat-dialog-content>


<mat-dialog-actions align="end">
  <button mat-raised-button class="administration deleteButtonHover" color="primary" (click)="dialog.close(false)">{{ cancelText || ('cancel' | translate) }}</button>
  <button mat-raised-button class="administration saveButtonHover" (click)="dialog.close(true)" color="primary" autofocus>{{ confirmText || ('save' | translate) }}</button>
</mat-dialog-actions>
