import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HintMessageComponent } from './hint-message.component';



@NgModule({
    declarations: [
        HintMessageComponent
    ],
    exports: [
        HintMessageComponent
    ],
    imports: [
        CommonModule
    ]
})
export class HintMessageModule { }
