import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CreateDateComponent} from '@shared/components/create-date/create-date.component';
import {PipesModule} from '@shared/pipes/pipes.module';



@NgModule({
  declarations: [CreateDateComponent],
  exports: [CreateDateComponent],
  imports: [
    CommonModule,
    PipesModule
  ]
})
export class CreateDateModule { }
