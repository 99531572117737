import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentCategoryFilterComponent } from './equipment-category-filter.component';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FilterModule} from '@shared/components/filter/filter.module';
import {EquipmentCategorySelectModule} from '@shared/components/equipment-category-select/equipment-category-select.module';
import {TopicCategorySelectModule} from '@shared/components/topic-category-select/topic-category-select.module';



@NgModule({
  declarations: [
    EquipmentCategoryFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    FilterModule,
    EquipmentCategorySelectModule,
    TopicCategorySelectModule
  ],
  exports: [
    EquipmentCategoryFilterComponent
  ]
})
export class EquipmentCategoryFilterModule { }
