import { Theme } from '@shared/models/theme.model';

export class SetTheme {
  static readonly type = '[Theme] Set Theme';
  constructor(public theme: Theme) {}
}

export class SetDefaultTheme {
  static readonly type = '[Theme] Set Default Theme';
}
