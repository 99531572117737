import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import {AppConfigService} from './app-config.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  get apiURL() {
    if (this.appConfigService.appConfig) {
      return this.appConfigService.appConfig.apiURL;
    }
  }

  constructor(
    private http: HttpClient,
    private store: Store,
    private appConfigService: AppConfigService
  ) {}

  getSortingStringForExport(sortingParams: object): string {
    // return `start=${JSON.stringify(sortingParams['start'])}&limit=${JSON.stringify(sortingParams['limit'])}&sort=${sortingParams['sort']}&dir=${sortingParams['dir']}`
    return `sort=${sortingParams['sort']}&dir=${sortingParams['dir']}`
  }

  get(path: string, params?: object, headers?: object): Observable<any> {
    return this.http.get(`${this.apiURL}${path}`, {
      headers: ApiService.createHeaders(headers),
      params: ApiService.createParams(params),
    }).pipe(catchError(ApiService.formatErrors));
  }

  put(path: string, data: any = {}, params?: object, headers?: object): Observable<any> {
    return this.http.put(`${this.apiURL}${path}`, data, {
      headers: ApiService.createHeaders(headers),
      params: ApiService.createParams(params),
    }).pipe(catchError(ApiService.formatErrors));
  }

  post(path: string, data: any = {}, params?: object, headers?: object): Observable<any> {
    return this.http.post(`${this.apiURL}${path}`, data, {
      headers: ApiService.createHeaders(headers),
      params: ApiService.createParams(params),
    }).pipe(catchError(ApiService.formatErrors));
  }

  postMulti(file: File, path: string, params: object, method: string = 'POST', headers?: object): Observable<any> {
    const formData = new FormData();

    formData.append('importFile', file, file.name);

    return this.http.request(new HttpRequest(method, `${this.apiURL}${path}`, formData, {
      params: ApiService.createParams(params),
      reportProgress: false,
      headers: ApiService.createHeaders(headers)
    }));
  }

  delete(path, params?: object, headers?: object): Observable<any> {
    return this.http.delete(`${this.apiURL}${path}`, {
      headers: ApiService.createHeaders(headers),
      params: ApiService.createParams(params),
    }).pipe(catchError(ApiService.formatErrors));
  }

  static createParams(params: object = {}): HttpParams {
    let httpParams = new HttpParams();

    for (const [param, value] of Object.entries(params)) {
      httpParams = httpParams.set(param, (typeof value === 'string' ? value : JSON.stringify(value)));
    }

    return httpParams;
  }

  static createHeaders(headers: object = {}): HttpHeaders {

    if (localStorage.jwt !== undefined) {
        return new HttpHeaders({
            'Authorization': 'Bearer ' + localStorage.jwt,
            ...headers
        });

    }

    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...headers
    });
  }

  static createHeadersHtml(headers: object = {}): HttpHeaders {

    if (localStorage.jwt !== undefined) {
      return new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.jwt,
        ...headers
      });

    }

    return new HttpHeaders({
      'Content-Type': 'text/html; charset=utf-8',
      'Accept': 'text/html',
      ...headers
    });
  }

  static createHeadersPDF(headers: object = {}): HttpHeaders {

    if (localStorage.jwt !== undefined) {
      return new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.jwt,
        ...headers
      });

    }

    return new HttpHeaders({
      'Content-Type': 'application/pdf',
      'Accept': 'application/pdf',
      ...headers
    });
  }

  ge

  static formatErrors(error: any) {

    return new Observable(error.error);
  }
}
