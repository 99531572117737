import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AccidentExternalWitnessAddDialogComponent
} from '@shared/components/accident-external-witness-add-dialog/accident-external-witness-add-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {PipesModule} from '@shared/pipes/pipes.module';



@NgModule({
  declarations: [AccidentExternalWitnessAddDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    LoadingOverlayModule,
    PipesModule
  ],
  exports: [
    AccidentExternalWitnessAddDialogComponent
  ]
})
export class AccidentExternalWitnessAddDialogModule { }
