import { NgModule } from '@angular/core';
import { FilterModule } from '@shared/components/filter/filter.module';
import { CompanySelectModule } from '@shared/components/company-select/company-select.module';
import { CompanyFilterComponent } from './company-filter.component';
import {PipesModule} from '@shared/pipes/pipes.module';
import {DepartmentSelectModule} from '@shared/components/department-select/department-select.module';

@NgModule({
  imports: [
    CompanySelectModule,
    FilterModule,
    PipesModule,
    DepartmentSelectModule,
  ],
  declarations: [
    CompanyFilterComponent,
  ],
  exports: [
    CompanyFilterComponent,
  ],
})
export class CompanyFilterModule {}
