import { NgModule } from '@angular/core';
import { AssetService } from '@shared/services/asset.service';
import { ApiService } from '@shared/services/api.service';
import { AuthService } from '@shared/services/auth.service';
import { LanguageService } from '@shared/services/language.service';
import { TranslationService } from '@shared/services/translation.service';
import { CompanyService } from '@shared/services/company.service';
import { DepartmentService } from '@shared/services/department.service';
import { EmployeeService } from '@shared/services/employee.service';
import { UserDetailsService } from '@shared/services/user-details.service';
import { EmployeeCategoryService } from '@shared/services/employee-category.service';
import { RoleService } from '@shared/services/role.service';
import { FileService } from '@shared/services/file/file.service';
import { CountryService } from '@shared/services/country.service';
import { UserService } from '@shared/services/user.service';
import { HazardGroupService } from '@shared/services/hazard-group.service';
import { HazardAssessmentTemplateCategoryService } from '@shared/services/hazard-assessment-template-category.service';
import { HazardClassificationService } from '@shared/services/hazard-classification.service';
import { HazardTemplateService } from '@shared/services/hazard-template.service';
import { MeasureTemplateService } from '@shared/services/measure-template.service';
import { ProfileService } from '@shared/services/profile.service';
import { PermissionService } from '@shared/services/permission.service';
import { FacilityStateService } from '@shared/services/facility-state.service';
import { HazardAssessmentTemplateService } from '@shared/services/hazard-assessment-template.service';
import { TemplateClassificationService } from '@shared/services/template-classification.service';
import { TemplateHazardService } from '@shared/services/template-hazard.service';
import { TemplateMeasureService } from '@shared/services/template-measure.service';
import { PwaService } from '@shared/services/pwa.service';
import { NotificationService } from '@shared/services/notification.service';
import { WidgetService } from '@shared/services/widget.service';
import { ObservationService } from '@shared/services/observation.service';
import { ObservationTypeService } from '@shared/services/observation-type.service';
import { AuditService } from '@shared/services/audit.service';
import { InspectionService } from '@shared/services/inspection.service';
import { ExternalCompanyService } from '@shared/services/external-company.service';
import { TitleService } from '@shared/services/title.service';
import { ExternalCompanyCategoryService } from '@shared/services/external-company-category.service';
import { ExternalCompanyEmployeeService } from '@shared/services/external-company-employee.service';
import { AccidentService } from '@shared/services/accident.service';
import { AccidentCauseService } from '@shared/services/accident-cause.service';
import { TerminalService } from '@shared/services/terminal.service';
import { TerminalActivityService } from '@shared/services/terminal-activity.service';
import {BodypartService} from '@shared/services/bodypart.service';

@NgModule({
  providers: [
    AssetService,
    ApiService,
    AuthService,
    LanguageService,
    TranslationService,
    CompanyService,
    DepartmentService,
    EmployeeService,
    UserDetailsService,
    EmployeeCategoryService,
    RoleService,
    FileService,
    CountryService,
    UserService,
    HazardGroupService,
    HazardAssessmentTemplateCategoryService,
    HazardClassificationService,
    HazardTemplateService,
    MeasureTemplateService,
    ProfileService,
    PermissionService,
    FacilityStateService,
    HazardAssessmentTemplateService,
    TemplateClassificationService,
    TemplateHazardService,
    TemplateMeasureService,
    PwaService,
    NotificationService,
    WidgetService,
    ObservationService,
    ObservationTypeService,
    AuditService,
    InspectionService,
    ExternalCompanyService,
    ExternalCompanyCategoryService,
    ExternalCompanyEmployeeService,
    TerminalService,
    TerminalActivityService,
    TitleService,
    AccidentService,
    AccidentCauseService,
    BodypartService,
  ],
})
export class ServiceModule {}
