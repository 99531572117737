<mat-form-field [appearance]="appearance" (click)="openEquipmentCategorySelectDialog($event)">
  <mat-label>{{ placeholder || ((multiple ? 'equipmentCategories' : 'equipmentCategory') | translate) }}</mat-label>
  <input matInput
         class="select-input"
         [(ngModel)]="_valueText"
         [required]="required"
         [multiple]="multiple"
         [readonly]="true"
         [disabled]="_disabled"
  />
  <div *ngIf="hasNoValue" matSuffix>
    <button type="button" mat-icon-button matSuffix [disabled]="_disabled">
      <mat-icon class="{{_disabled? '' : 'iconColor'}}">category</mat-icon>
    </button>
  </div>
  <div *ngIf="hasValue" matSuffix>
    <button type="button" mat-icon-button [disabled]="disabled" (click)="openEquipmentCategorySelectDialog($event)">
      <mat-icon class="iconColor">category</mat-icon>
    </button>
    <button type="button" mat-icon-button [disabled]="disabled" (click)="onClickClear($event)">
      <mat-icon class="deleteIcon">close</mat-icon>
    </button>
  </div>
</mat-form-field>
