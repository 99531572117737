import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {PwaService} from '@shared/services/pwa.service';
import {Select, Store} from '@ngxs/store';
import {Theme} from '@shared/models/theme.model';
import {ThemeState} from '@shared/store/theme/theme.state';
import {Router} from '@angular/router';
import {Navigate} from '@ngxs/router-plugin';
import {CheckLogin, Logout, RefreshLogin, UpdateAuthState} from '@shared/store/auth/auth.actions';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AuthState} from '@shared/store/auth/auth.state';

@Component({
  selector: 'saf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Select(ThemeState.theme) theme$;
  private currentTheme: Theme = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private pwaService: PwaService,
    private router: Router,
    private store: Store
  ) {
  }

  ngOnInit() {
    this.theme$.subscribe((theme) => this.applyTheme(theme));
    this.pwaService.checkForUpdates();
    this.store.dispatch(new UpdateAuthState());
    if (localStorage.jwt && localStorage.refreshToken) {
      const helper = new JwtHelperService();

      if (helper.isTokenExpired(localStorage.jwt)) {
        this.store.dispatch(new RefreshLogin())
          .subscribe(() => this.store.dispatch(new Navigate(['/login'])));
      }
    }
  }

  applyTheme(theme) {
    if (this.currentTheme) {
      this.renderer.removeClass(this.document.body, this.currentTheme.class);
    }

    this.currentTheme = theme;

    if (this.currentTheme) {
      this.renderer.addClass(this.document.body, this.currentTheme.class);
    }
  }
}
