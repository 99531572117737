import { NgModule } from '@angular/core';
import { FilterDrawerComponent } from '@shared/components/filter-drawer/filter-drawer.component';
import { MaterialModule } from '@shared/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FilterModule } from '@shared/components/filter/filter.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    FilterDrawerComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    PipesModule,
    FilterModule,
  ],
  exports: [
    FilterDrawerComponent,
  ]
})
export class FilterDrawerModule {}
