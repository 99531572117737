import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TerminalActivityDialogComponent } from './terminal-activity-dialog.component';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TerminalSelectModule } from '@shared/components/terminal-select/terminal-select.module';
import { CompanySelectModule } from '@shared/components/company-select/company-select.module';
import { ExternalCompanySelectModule } from '@shared/components/external-company-select/external-company-select.module';
import { ExternalCompanyCategorySelectModule } from '@shared/components/external-company-category-select/external-company-category-select.module';
import { TerminalActivityTypeSelectModule } from '@shared/components/terminal-activity-type-select/terminal-activity-type-select.module';
import { ExternalCompanyEmployeeSelectModule } from '@shared/components/external-company-employee-select/external-company-employee-select.module';
import {DepartmentSelectModule} from '@shared/components/department-select/department-select.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        ReactiveFormsModule,
        TerminalActivityTypeSelectModule,
        TerminalSelectModule,
        CompanySelectModule,
        ExternalCompanySelectModule,
        ExternalCompanyCategorySelectModule,
        ExternalCompanyEmployeeSelectModule,
        LoadingOverlayModule,
        DepartmentSelectModule,
    ],
    declarations: [
        TerminalActivityDialogComponent,
    ],
    exports: [
        TerminalActivityDialogComponent,
    ]
})
export class TerminalActivityDialogModule {}
