import { animate, state, style, transition, trigger } from '@angular/animations';

export const EASING_DEFAULT = 'cubic-bezier(0.4, 0, 0.2, 1)';
export const EASING_ENTER = 'cubic-bezier(0.4, 0, 1, 1)';
export const EASING_LEAVE = 'cubic-bezier(0, 0, 0.2, 1)';

export const fadeInOut = trigger('fadeInOut', [
  state('void', style({ opacity: 0 })),
  state('*', style({ opacity: 1 })),
  transition('void => *', animate(`250ms ${EASING_ENTER}`)),
  transition('* => void', animate(`200ms ${EASING_LEAVE}`)),
]);

export const fadeInOutToggle = trigger('fadeInOutToggle', [
  state('false', style({ opacity: 0 })),
  state('true', style({ opacity: 1 })),
  transition('false => true', animate(`250ms ${EASING_ENTER}`)),
  transition('true => false', animate(`200ms ${EASING_LEAVE}`)),
]);

export const transformPanel = trigger('transformPanel', [
  state('void', style({
    transform: 'scaleY(0.8)',
    minWidth: '100%',
    opacity: 0
  })),
  state('showing', style({
    opacity: 1,
    minWidth: 'calc(100% + 32px)',
    transform: 'scaleY(1)'
  })),
  state('showing-multiple', style({
    opacity: 1,
    minWidth: 'calc(100% + 64px)',
    transform: 'scaleY(1)'
  })),
  transition('void => *', animate('120ms cubic-bezier(0, 0, 0.2, 1)')),
  transition('* => void', animate('100ms 25ms linear', style({opacity: 0})))
]);
