import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObservationTypeSelectComponent } from './observation-type-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
  ],
  declarations: [
    ObservationTypeSelectComponent
  ],
  exports: [
    ObservationTypeSelectComponent,
  ],
})
export class ObservationTypeSelectModule {}
