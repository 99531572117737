import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { HazardGroup, HazardGroupWithClassifications } from '@shared/models/hazard-group.model';
import { HazardClassificationDetails, HazardGroupDetails, HazardTemplateDetails } from '@shared/models/hazard-group-details.model';
import {HazardTemplate} from '@shared/models/hazard-template.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class HazardGroupService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<HazardGroup>> {
    return this.api.get(`/hazardAssessmentGroups`, params);
  }

  @Cacheable()
  getListWithClassifications(params?: object): Observable<ListResponse<HazardGroupWithClassifications>> {
    return this.api.get(`/hazardAssessmentGroup-list`, params);
  }

  getById(id: number): Observable<HazardGroup> {
    return this.api.get(`/hazardAssessmentGroup/${id}`);
  }

  getHazardGroupDetails(groupId: number): Observable<HazardGroupDetails> {
    return this.api.get(`/hazardAssessmentGroup-details/${groupId}`);
  }

  getHazardClassificationDetails(classificationId: number): Observable<HazardClassificationDetails> {
    return this.api.get(`/hazardAssessmentClassification-details/${classificationId}`);
  }


  getTemplateList(params?: object): Observable<ListResponse<HazardTemplate>> {
    return this.api.get('/classificationHazardTemplate-list', params);
  }

  getHazardTemplateDetails(hazardTemplateId: number): Observable<HazardTemplateDetails> {
    return this.api.get(`/classificationHazardTemplateDetails/${hazardTemplateId}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<HazardGroup>> {
    return this.api.post(`/hazardAssessmentGroup`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<HazardGroup>> {
    return this.api.put(`/hazardAssessmentGroup/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/hazardAssessmentGroup/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
