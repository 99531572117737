import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {TopicCategorySelectComponent} from '@shared/components/topic-category-select/topic-category-select.component';



@NgModule({
  declarations: [
    TopicCategorySelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
  ],
  exports: [
    TopicCategorySelectComponent
  ]
})
export class TopicCategorySelectModule { }
