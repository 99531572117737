import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {DataResponse, ListResponse, SuccessResponse} from '../models/response.model';
import {SchoolingOnline, SchoolingOnlineDetails} from '../models/schooling-online.model';

const cacheNotifier: Subject<void> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class SchoolingOnlineService {

  constructor(private api: ApiService) {
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<SchoolingOnline>> {
    return this.api.get(`/schooling/onlines`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getListDetail(params?: object): Observable<ListResponse<SchoolingOnlineDetails>> {
    return this.api.get(`/schooling/online-list`, params);
  }

  getById(id: number): Observable<DataResponse<SchoolingOnline>> {
    return this.api.get(`/schooling/online/${id}`);
  }

  getDetail(id: number): Observable<DataResponse<SchoolingOnlineDetails>> {
    return this.api.get(`/schooling/online-detail/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<SchoolingOnline>> {
    return this.api.post(`/schooling/online`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<SchoolingOnline>> {
    return this.api.put(`/schooling/online/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  start(data: object): Observable<DataResponse<SchoolingOnline>> {
    return this.api.post(`/schooling/online/start`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  finish(id: number): Observable<DataResponse<SchoolingOnline>> {
    return this.api.put(`/schooling/online/${id}/finish`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  block(id: number): Observable<DataResponse<SchoolingOnline>> {
    return this.api.put(`/schooling/online/${id}/block`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  progress(id: number, data: object): Observable<DataResponse<SchoolingOnline>> {
    return this.api.put(`/schooling/online/${id}/progress`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/schooling/online/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
