import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {TopicIndex} from "@shared/models/topic-index.model";
import {TranslationService} from "@shared/services/translation.service";
import {SchoolingOnlineService} from "@shared/services/schooling-online.service";
import {TopicBookletService} from "@shared/services/topic-booklet.service";
import {TopicIndexService} from "@shared/services/topic-index.service";
import {TopicService} from "@shared/services/topic.service";
import {Store} from "@ngxs/store";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS, pageChange} from '@shared/utils/utils';
import {tap} from "rxjs/operators";
import {SetCurrentBreadcrumb} from "@shared/store/breadcrumb/breadcrumb.actions";
import {DataResponse} from "@shared/models/response.model";
import {SchoolingOnline} from "@shared/models/schooling-online.model";

@Component({
  selector: 'saf-terminal-page-online-view',
  templateUrl: './terminal-page-online-view.component.html',
  styleUrls: ['./terminal-page-online-view.component.scss']
})
export class TerminalPageOnlineViewComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() planId: number;
  @Input() topicId: number;
  @Input() externalEmployeeId: number;
  @Output() viewCompleted = new EventEmitter();
  formGroup: UntypedFormGroup;
  public schoolingOnlineId: number;
  public bookletId: number;
  public lastIndexId: number;
  public tableColumns = ['number', 'title', 'type'];
  public dataSource = new MatTableDataSource<TopicIndex>();
  public drawerFilterParams: any = {};
  public searchFilterParams: any = {};
  public loading = false;
  public contentLoading = false;
  public totalCount = 0;
  public pageNumber: number;
  public lastPage: number;
  public start = false;
  public executionSeconds = 0;
  public progress = 100;
  public progressSteps = 0;
  public seconds = 0;
  public progressInterval: any;
  public indexContentData: any;
  quizSuccess: boolean;
  attempts: number;
  maxAttempts: number;
  pageType: number;
  fire = false;
  fold = true;
  public trackById = (item) => item.id;

  constructor(
    private translationService: TranslationService,
    private schoolingOnlineService: SchoolingOnlineService,
    private topicBookletService: TopicBookletService,
    private changeDetectorRef: ChangeDetectorRef,
    private indexService: TopicIndexService,
    private topicService: TopicService,
    private store: Store,
    private snackBar: MatSnackBar,
    @Inject(PAGE_SIZE_OPTIONS) public pageSizeOptions,
    @Inject(DEFAULT_PAGE_SIZE) public pageSize,
  ) { }

  ngOnInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    screen.orientation.lock('landscape');
  }

  isLoading(isLoading) {
    this.contentLoading = isLoading;
    if (!isLoading && this.executionSeconds !== 0) {
      this.startExecutionTimer();
    } else {
      clearInterval(this.progressInterval);
    }
  }

  ngAfterViewInit() {
    this.buildForm();
    this.changeDetectorRef.detectChanges();
  }

  getBookletInfo() {
    this.topicBookletService.getById(this.bookletId).subscribe((data: any) => {
      this.maxAttempts = data.maxAttempts;
      this.executionSeconds = data.minExecutionTimeInMinutes;
      this.seconds = data.minExecutionTimeInMinutes;
      this.progressSteps = 100 / this.executionSeconds;
    });
  }

  startExecutionTimer() {
    this.progressInterval = setInterval(() => {
      this.executionSeconds--;
      this.progress -= this.progressSteps;
      if (this.executionSeconds === 0) {
        this.progress = 100;
        clearInterval(this.progressInterval);
      }
    }, 1000);
  }

  buildForm() {
    this.formGroup = new UntypedFormGroup({
      planId: new UntypedFormControl(this.planId, Validators.required),
      externalEmployeeId: new UntypedFormControl(this.externalEmployeeId, Validators.required),
    });

    if (this.topicId) {
      this.topicService.getById(this.topicId)
        .pipe(tap())
        .subscribe((topic) => {
          this.store.dispatch(new SetCurrentBreadcrumb(topic.name));
        });
    }
  }

  loadTable() {
    const params = {
      filter: {
        ...this.searchFilterParams,
        ...this.drawerFilterParams,
        bookletId: this.bookletId,
        active: true
      },
      sort: this.sort.direction ? this.sort.active : '',
      dir: this.sort.direction,
      start: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize,
    };

    this.loading = true;

    this.indexService.getList(params)
      .pipe(tap(() => this.loading = false))
      .subscribe((result) => {
        result.data.forEach((item, x, array) => {
          this.lastPage = array.length;
          if (item.pageNumber === 1) {
            this.lastIndexId = item.id;
            this.pageNumber = item.pageNumber;
            this.pageType = item.pageType;
          }
        });
        this.totalCount = result.total;
        this.dataSource.data = result.data;
      });
  }

  reloadTable() {
    TopicIndexService.clearCache();
    this.loadTable();
  }

  getTypeDescription(pageType: number) {
    switch (pageType) {
      case 1: return this.translationService.translate('questionary');
      case 2: return this.translationService.translate('video');
      default: return this.translationService.translate('page');
    }
  }

  getTypeIcon(pageType: number) {
    switch (pageType) {
      case 1: return 'quiz';
      case 2: return 'video_file';
      default: return 'description';
    }
  }

  startSchooling() {
    this.start = true;
    this.schoolingOnlineService.start(this.formGroup.getRawValue()).subscribe((data: DataResponse<SchoolingOnline>) => {
      this.attempts = data.data.attempt;
      this.bookletId = data.data.bookletId;
      this.schoolingOnlineId = data.data.id;
      this.reloadTable();
      this.getBookletInfo();
      this.getContent();
    });
  }

  getContent() {
    if (this.lastIndexId) {
      this.indexService.getContent(this.lastIndexId)
        .subscribe((data: any) => {
          this.indexContentData = data;
        });
    }
  }

  next() {
    this.executionSeconds = this.seconds;
    this.pageNumber = this.pageNumber + 1;
    this.dataSource.data.forEach(item => {
      if (item.pageNumber === this.pageNumber) {
        this.pageNumber = item.pageNumber;
        this.lastIndexId = item.id;
        this.pageType = item.pageType;
      }
    });
    const params = {
      indexId: this.lastIndexId
    };
    this.schoolingOnlineService.progress(this.schoolingOnlineId, params).subscribe();
    this.getContent();
  }

  back() {
    this.executionSeconds = 0;
    this.progress = 100;
    clearInterval(this.progressInterval);
    this.pageNumber = this.pageNumber - 1;
    this.dataSource.data.forEach(item => {
      if (item.pageNumber === this.pageNumber) {
        this.pageNumber = item.pageNumber;
        this.lastIndexId = item.id;
        this.pageType = item.pageType;
      }
    });
    const params = {
      indexId: this.lastIndexId
    };
    this.schoolingOnlineService.progress(this.schoolingOnlineId, params).subscribe();
    this.getContent();
  }

  finish() {
    this.schoolingOnlineService.finish(this.schoolingOnlineId).subscribe(() => {
      this.viewCompleted.emit();
    });
  }

  showResult() {
    this.fire = true;
  }

  foldTable() {
    this.fold = !this.fold;
  }

  showQuestionResult(result) {
    this.snackBar.open(result[0], '', {
      duration: 5000
    });
    if (result[1].success) {
      this.quizSuccess = true;
    } else {
      this.executionSeconds = this.seconds;
      if (!result[1].failureReason) {
        this.attempts++;
        if (this.attempts === this.maxAttempts) {
          if (result[1].questionary.incorrectAnswerJumpToIndexId) {
            this.lastIndexId = result[1].questionary.incorrectAnswerJumpToIndexId;
            this.dataSource.data.forEach(item => {
              if (item.id === this.lastIndexId) {
                this.pageNumber = item.pageNumber;
                this.lastIndexId = item.id;
                this.pageType = item.pageType;
              }
            });
            this.getContent();
          } else {
            this.schoolingOnlineService.block(this.schoolingOnlineId);
            setTimeout(() => {
              this.viewCompleted.emit();
            }, 5000);
          }
        }
      }
    }
    this.fire = false;
  }

    protected readonly pageChange = pageChange;
}
