import { NgModule } from '@angular/core';
import { AccidentStatusFilterComponent } from './accident-status-filter.component';
import { FilterModule } from '@shared/components/filter/filter.module';
import { AccidentStatusSelectModule } from '@shared/components/accident-status-select/accident-status-select.module';



@NgModule({
  declarations: [
    AccidentStatusFilterComponent
  ],
  imports: [
    AccidentStatusSelectModule,
    FilterModule,
  ],
  exports: [
    AccidentStatusFilterComponent,
  ],
})
export class AccidentStatusFilterModule { }
