import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HazardAssessmentDialogComponent } from './hazard-assessment-dialog.component';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FilterPanelModule } from '@shared/components/filter-panel/filter-panel.module';
import { HazardAssessmentTemplateCategoryFilterModule } from '@shared/components/hazard-assessment-template-category-filter/hazard-assessment-template-category-filter.module';
import { HazardAssessmentTemplateStateFilterModule } from '@shared/components/hazard-assessment-template-state-filter/hazard-assessment-template-state-filter.module';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {EmptyMessageModule} from '@shared/components/empty-message/empty-message.module';
import {LoadingSpinnerModule} from '@shared/components/loading-spinner/loading-spinner.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        FilterPanelModule,
        HazardAssessmentTemplateCategoryFilterModule,
        HazardAssessmentTemplateStateFilterModule,
        LoadingOverlayModule,
        FlexModule,
        FlexLayoutModule,
        EmptyMessageModule,
        LoadingSpinnerModule,
    ],
    declarations: [
        HazardAssessmentDialogComponent,
    ],
    exports: [
        HazardAssessmentDialogComponent,
    ]
})
export class HazardAssessmentDialogModule {}
