export class OpenSidenav {
  static readonly type = '[Fronted] Open Sidenav';
}

export class CloseSidenav {
  static readonly type = '[Fronted] Close Sidenav';
}

export class ToggleSidenav {
  static readonly type = '[Fronted] Toggle Sidenav';
}
