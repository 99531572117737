import { Injectable } from '@angular/core';
import { SystemNotification } from '@shared/models/system-notification.model';
import { BehaviorSubject } from 'rxjs';

interface NotificationOptions {
  message: string;
  action?: Function;
  icon?: string;
  seen?: boolean;
  date?: string;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _notifications: SystemNotification[] = [];
  public notifications$ = new BehaviorSubject<SystemNotification[]>([]);
  public unseenNotifications$ = new BehaviorSubject<SystemNotification[]>([]);
  public loading = false;

  constructor() {
    this.loadNotifications();
  }

  loadNotifications() {
    this.updateNotifications();
  }

  loadMoreNotifications() {

  }

  addNotification(options: NotificationOptions) {
    const notification = new SystemNotification(options);
    this._notifications.push(notification);
    this.updateNotifications();

    return notification;
  }

  removeNotification(notification: SystemNotification) {
    const index = this._notifications.indexOf(notification),
      remove = index !== -1;

    if (remove) {
      this._notifications.splice(index, 1);
      this.updateNotifications();
    }

    return remove;
  }

  runAction(notification) {
    this.markAsSeen(notification);
    notification.action(notification);
  }

  toggleSeenStatus(notification: SystemNotification) {
    notification.seen = !notification.seen;
    this.updateNotifications();
  }

  markAsSeen(notification: SystemNotification) {
    if (!notification.seen) {
      notification.seen = true;
      this.updateNotifications();
    }
  }

  markAllAsSeen() {
    if (this._notifications.length) {
      for (const notification of this._notifications) {
        notification.seen = true;
      }

      this.updateNotifications();
    }
  }

  clearNotifications() {
    if (this._notifications.length) {
      this._notifications.length = 0;
      this.updateNotifications();
    }
  }

  updateNotifications() {
    this.notifications$.next(this._notifications);
    this.unseenNotifications$.next(this._notifications.filter((notification) => !notification.seen));
  }
}
