import {Injectable} from '@angular/core';
import {ApiService} from '@shared/services/api.service';
import {Observable} from 'rxjs';
import {SuccessResponse} from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

	constructor(
		private api: ApiService
	) {}

  login(credentials: { login: string, password: string, saveSession: boolean }): Observable<any> {
    return this.api.post(`/login`, credentials);
  }

  logout(): Observable<any> {
    return this.api.get(`/logout`);
  }

	refreshLogin(params: {refreshToken: string}): Observable<any> {
		return this.api.get(`/refreshJWT`, params);
	}

  forgetPassword(params: object): Observable<SuccessResponse> {
    return this.api.put(`/login/resetPassword`, params);
  }

}
