<div class="search-container" [style.margin-bottom.px]="isDialog ? '15' : '0'">
  <saf-search-filter [disabled]="disabled" [preSearch]="preSearch" [placeholder]="placeholder" [listName]="listName"
                     [filterParam]="searchFilterParam" [autofocus]="autofocusSearch"></saf-search-filter>
  <button type="button" mat-mini-fab *ngIf="isDialog && contentFilters.length"
          color="primary"
          class="toggle-filters administration"
          (click)="toggle()"
          [fxShow]="collapsible"
          matTooltip="{{ (opened ? 'hideMoreFilters' : 'showMoreFilters') | translate }}">
    <mat-icon>{{ opened ? 'remove' : 'filter_list' }}</mat-icon>
  </button>
<!--  <button mat-mini-fab-->
<!--          *ngIf="filtersActive"-->
<!--          color="primary"-->
<!--          class="toggle-filters administration"-->
<!--          (click)="clearFilters()"-->
<!--          matTooltip="{{ 'resetFilter' | translate }}">-->
<!--    <mat-icon class="deleteIcon">delete</mat-icon>-->
<!--  </button>-->
</div>

<div class="filter-container" [fxShow]="opened">
  <ng-content select="[slot=firstRow]" class="filterItem"></ng-content>
</div>
<div class="filter-container" [fxShow]="opened">
  <ng-content select="[slot=secondRow]" class="filterItem"></ng-content>
</div>
<div class="filter-container" [fxShow]="opened">
  <ng-content select="[slot=thirdRow]" class="filterItem"></ng-content>
</div>
