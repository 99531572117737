<a mat-list-item (click)="onItemSelected(item)" *ngIf="item.label === 'dashboard'" class="menu-list-item">

  <div class="hexagon-small"
       [ngStyle]="{'background': item.moduleColor ? item.moduleColor : 'black'}">
    <div class="hex-icon-div-small">
      <mat-icon class="hexIcon" fontSet="material-symbols-outlined">{{ item.icon }}</mat-icon>
      <mat-icon class="addNavIcon" fontSet="material-symbols-outlined" *ngIf="item.add">add</mat-icon>
    </div>
  </div>

  <span class="label" [ngStyle]="{'color': item.moduleColor}">{{item.label | translate}}</span>
  <span class="expandArrow" *ngIf="item.children && item.children.length">
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">expand_more</mat-icon>
  </span>
</a>
  <mat-expansion-panel *ngIf="item.label !== 'dashboard'" (click)="onItemSelected(item)">
    <mat-expansion-panel-header [expandedHeight]="'48px'">
      <div class="hexagon-small"
           [ngStyle]="{'background': item.moduleColor ? item.moduleColor : 'black', 'margin-left': item.children ? '0' : '20px'}">
        <div class="hex-icon-div-small">
          <mat-icon class="hexIcon" fontSet="material-symbols-outlined">{{ item.icon }}</mat-icon>
        </div>
      </div>
      <span class="label">{{ item.label | translate }}</span>
    </mat-expansion-panel-header>

    <mat-divider></mat-divider>

    <div *ngFor="let child of item.children; let last = last">
      <a mat-list-item (click)="onItemSelected(child)" [ngClass]="{'expanded': expanded}" class="menu-list-item">
        <div class="hexagon-small"
             [ngStyle]="{'background': item.moduleColor ? item.moduleColor : 'black', 'margin-left': '15px'}">
          <div class="hex-icon-div-small">
            <mat-icon class="hexIcon" fontSet="material-symbols-outlined">{{ child.icon }}</mat-icon>
            <mat-icon class="addNavIcon" fontSet="material-symbols-outlined" *ngIf="child.add">add</mat-icon>
          </div>
        </div>
        <span class="label">{{ child.label | translate }}</span>
      </a>
      <mat-divider *ngIf="!last"></mat-divider>
    </div>

  </mat-expansion-panel>

  <mat-divider></mat-divider>


<!--<a mat-list-item (click)="onItemSelected(item)" [ngClass]="{'expanded': expanded}" class="menu-list-item">-->

<!--  <div class="hexagon-small"-->
<!--       [ngStyle]="{'background': item.moduleColor ? item.moduleColor : 'black', 'margin-left': item.children ? '0' : '20px'}">-->
<!--    <div class="hex-icon-div-small">-->
<!--      <mat-icon class="hexIcon" fontSet="material-symbols-outlined">{{ item.icon }}</mat-icon>-->
<!--      <mat-icon class="addNavIcon" fontSet="material-symbols-outlined" *ngIf="item.add">add</mat-icon>-->
<!--    </div>-->
<!--  </div>-->

<!--&lt;!&ndash;  <mat-icon fontSet="{{item.icon === 'mail' ? '' : 'material-symbols-outlined'}}"  [ngStyle]="{'background': item.moduleColor ? item.moduleColor : 'black'}" class="routeIcon">{{item.icon}}</mat-icon>&ndash;&gt;-->
<!--  <span class="label" [ngStyle]="{'color': item.moduleColor}">{{item.label | translate}}</span>-->
<!--  <span class="expandArrow" *ngIf="item.children && item.children.length">-->
<!--    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">expand_more</mat-icon>-->
<!--  </span>-->
<!--</a>-->

<!--<mat-divider *ngIf="expanded"></mat-divider>-->

<!--<div *ngIf="expanded">-->
<!--  <saf-navigation-list-item *ngFor="let child of item.children" [item]="child">-->
<!--  </saf-navigation-list-item>-->
<!--</div>-->

<!--<mat-divider *ngIf="!expanded"></mat-divider>-->
