<div *ngIf="showToggle" fxLayout="row" style="position: fixed; top: 0">
  <button  class="user-notifications" mat-icon-button (click)="toggleSidenav()">
    <mat-icon>arrow_back</mat-icon>
  </button>
</div>

<div *ngIf="!showToggle" fxLayout="row">
  <div class="user-container" fxFlex="1 0 auto">
    <img class="company-avatar" *ngIf="companyLogoUrl" src="{{companyLogoUrl}}" alt="''">
    <div>
      <div class="user-name" [matTooltip]="name">{{ name }}</div>
      <div class="user-title" [matTooltip]="title">{{ title }}</div>
    </div>
    <div class="{{systemUser ? 'system-user' : 'user-avatar'}}" *ngIf="avatarURL" [style.background-image]="'url('+ avatarURL +')'"></div>
    <div class="{{systemUser ? 'system-user' : 'user-avatar'}}" *ngIf="!avatarURL"></div>
  </div>
  <button disabled  class="user-notifications" mat-icon-button >
  </button>
</div>
