import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

const SALUTATIONS: string[] = ['Herr', 'Frau', 'Divers'];

@Injectable({
  providedIn: 'root'
})
export class SalutationService {
  public readonly salutations$: Observable<string[]> = of(SALUTATIONS);

  getList(): Observable<string[]> {
    return this.salutations$;
  }
}
