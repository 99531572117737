import { NgModule } from '@angular/core';
import { MaterialModule } from '@shared/material.module';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FileRenameDialogComponent } from '@shared/components/file-rename-dialog/file-rename-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import {LoadingOverlayModule} from "@shared/components/loading-overlay/loading-overlay.module";
import {FileCategorySelectModule} from "@shared/components/file-category-select/file-category-select.module";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    ReactiveFormsModule,
    LoadingOverlayModule,
    FileCategorySelectModule,
  ],
    declarations: [
        FileRenameDialogComponent,
    ],
    exports: [
        FileRenameDialogComponent,
    ]
})
export class FileRenameDialogModule {}
