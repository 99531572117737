<form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()">
  <span class="center-header" mat-dialog-title>{{ 'captchaMsg'| translate }}</span>
  <div mat-dialog-content class="block-fields">
    <div class="containerCaptcha">
    <div class="captcha">
      <span class="captchaFont">{{captchaText}}</span>
    </div>
    <div style="padding-left: 20px">
      <button type="button" mat-icon-button class="administration" color="primary" matSuffix (click)="generateCaptcha()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Captcha' }}</mat-label>
      <input type="text" matInput required formControlName="captcha">
    </mat-form-field>

  </div>
  <div class="button-position" mat-dialog-actions fxLayoutAlign="center">
    <button color="primary" class="administration deleteButtonHover" mat-raised-button type="button" (click)="close()">{{ 'cancel' | translate }}</button>
    <button class="administration saveButtonHover" mat-raised-button type="submit" color="primary">{{ 'Versenden' }}</button>
  </div>
</form>

