import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { Department } from '@shared/models/department.model';
import { DepartmentNode } from '@shared/models/department-node.model';
import { DataResponse, SuccessResponse } from '@shared/models/response.model';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {ListResponse} from '../models/response.model';
import {DepartmentDetailModel} from '../models/department-detail.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable()
export class DepartmentService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<DepartmentNode[]> {
    return this.api.get(`/section/tree`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getSectionList(params?: object): Observable<ListResponse<Department>> {
    return this.api.get(`/sections`, params);
  }
  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getCompanyList(params?: object): Observable<ListResponse<Department>> {
    return this.api.get(`/companies`, params);
  }

  getMainMandantLogo(): Observable<any> {
    return this.api.get(`/section/main-mandant`);
  }

  getById(id: number): Observable<Department> {
    return this.api.get(`/section/${id}`);
  }

  getDetailsById(id: number): Observable<DepartmentDetailModel> {
    return this.api.get(`/section/detail/${id}`);
  }
  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Department>> {
    return this.api.post(`/section`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<Department>> {
    return this.api.put(`/section/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/section/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  safeDelete(id: number): Observable<SuccessResponse> {
    return this.api.get(`/section/${id}/canBeSafelyDeleted`);
  }

  changePosition(nodeId: number, parentId: number, index: number) {

  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getRolesInSection(params?: object): Observable<ListResponse> {
    return this.api.get(`/userAnalyse/rolesAndAllowedSectionsNewFrontend`, params);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
