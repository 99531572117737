import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { DataResponse, ListResponse, SuccessResponse } from '@shared/models/response.model';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { Terminal } from '@shared/models/terminal.model';
import { TerminalActivity } from '@shared/models/terminal-activity.model';

const cacheNotifier: Subject<void> = new Subject();
interface TerminalLoginResponse {
  success: boolean;
  failureReason: string;
  terminal: Terminal | null;
}

@Injectable({
  providedIn: 'root'
})
export class TerminalService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getAll(params?: object): Observable<ListResponse<Terminal>> {
    return this.api.get(`/external/terminals`, params);
  }

  getById(id): Observable<Terminal> {
    return this.api.get(`/external/terminal/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Terminal>> {
    return this.api.post(`/external/terminal`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<Terminal>> {
    return this.api.put(`/external/terminal/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/external/terminal/${id}`);
  }

  login(credentials: { terminalId: number, password: string }): Observable<TerminalLoginResponse> {
    return this.api.post(`/external/terminal/login`, credentials);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
