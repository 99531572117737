import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { HazardAssessmentSelectComponent } from './hazard-assessment-select.component';
import { HazardAssessmentDialogModule } from '@shared/components/hazard-assessment-dialog/hazard-assessment-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    HazardAssessmentDialogModule,
  ],
  declarations: [
    HazardAssessmentSelectComponent,
  ],
  exports: [
    HazardAssessmentSelectComponent,
  ],
})
export class HazardAssessmentSelectModule {}
