export class LoadExternalCompanyCategories {
  static readonly type = '[ExternalCompanyCategory] Load External Company Categories';
}

export class CreateExternalCompanyCategory {
  static readonly type = '[ExternalCompanyCategory] Create External Company Category';
  constructor(public payload: any) {}
}

export class UpdateExternalCompanyCategory {
  static readonly type = '[ExternalCompanyCategory] Update External Company Category';
  constructor(public id: number, public payload: any) {}
}

export class DeleteExternalCompanyCategory {
  static readonly type = '[ExternalCompanyCategory] Delete External Company Category';
  constructor(public id: number) {}
}
