import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngxs/store';
import { AuthState } from '@shared/store/auth/auth.state';
import { JwtHelperService } from '@auth0/angular-jwt';
import {CheckLogin, Logout, RefreshLogin} from '@shared/store/auth/auth.actions';
import { Navigate } from '@ngxs/router-plugin';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedInGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private store: Store,
    private router: Router,
    private location: Location
  ) {}

  canActivate(): boolean {
    return this.checkLogin();
  }

  canActivateChild(): boolean {
    return this.checkLogin();
  }

  canLoad(): boolean {
    return this.checkLogin();
  }

	private checkLogin(): boolean {

		if (!this.store.selectSnapshot(AuthState.authenticated)) {
			const path = this.location.path();

			if (path && !path.includes('logout') && !path.includes('login')) {
				sessionStorage.setItem('redirect', path);
			}

			this.router.navigateByUrl('/login');

			return false;
		}

		if (localStorage.jwt) {

		  const helper = new JwtHelperService();

		  if (!helper.isTokenExpired(localStorage.jwt)) {
			  return true
		  }

		  this.store.dispatch(new RefreshLogin())
			.subscribe(() => this.store.dispatch(new Navigate(['/login'])));


		  return false;
		} else {
		  this.store.dispatch(new Logout())
			  .subscribe(() => this.store.dispatch(new Navigate(['/login'])));
		  return false;
		}

  }
}
