import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {ApiService} from './api.service';
import { DataResponse, ListResponse, SuccessResponse } from '../models/response.model';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import { AuditListItem } from '../models/audit-list-item.model';
import { Audit } from '../models/audit.model';
import { AuditDetails } from '../models/audit-details.model';
import { AuditState } from '../models/audit-state.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<AuditListItem>> {
    return this.api.get(`/audit-list`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getAll(params?: object): Observable<ListResponse<Audit>> {
    return this.api.get(`/audits`, params);
  }

  getById(id): Observable<Audit> {
    return this.api.get(`/audit/${id}`);
  }

  getDetails(id): Observable<AuditDetails> {
    return this.api.get(`/audit-details/${id}`);
  }

  getStates(auditId): Observable<ListResponse<AuditState>> {
    return this.api.get(`/audit-states/${auditId}`);
  }

  getEmployeeStates(employeeId): Observable<ListResponse<AuditState>> {
    return this.api.get(`/audit-employee-states/${employeeId}`);
  }

  getExportURL(filter: object, sortingParams: object, jwt: string) {
    return `${this.api.apiURL}/audit-list/export?filter=${JSON.stringify(filter)}&${this.api.getSortingStringForExport(sortingParams)}&jwt=${jwt}`;
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Audit>> {
    return this.api.post(`/audit`, data);
  }
  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<Audit>> {
    return this.api.put(`/audit/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/audit/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
