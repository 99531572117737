import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionTypeFilterComponent } from './instruction-type-filter.component';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FormsModule} from '@angular/forms';
import {InstructionTypeSelectModule} from '@shared/components/instruction-type-select/instruction-type-select.module';
import {FilterModule} from '@shared/components/filter/filter.module';



@NgModule({
  declarations: [
    InstructionTypeFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    FilterModule,
    InstructionTypeSelectModule
  ],
  exports: [
    InstructionTypeFilterComponent
  ]
})
export class InstructionTypeFilterModule { }
