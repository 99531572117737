<mat-form-field #field [appearance]="appearance" title="{{ placeholder }}" class="no-hint" (click)="openDepartmentDialog($event)">
  <mat-label>{{ placeholder || ((multiple ? 'departments' : 'department') | translate) }}</mat-label>
<!--  <mat-label>{{ 'corporateStructure' | translate }}</mat-label>-->
  <input matInput
         class="select-input"
         [(ngModel)]="_valueText"
         [required]="required"
         [disabled]="disabled"
         [multiple]="multiple"
         [readonly]="true">
  <button *ngIf="hasNoValue" type="button" mat-icon-button matSuffix [disabled]="disabled">
    <mat-icon class="{{disabled ? '' : 'iconColor'}}">domain</mat-icon>
  </button>
  <div *ngIf="hasValue" matSuffix>
    <button type="button" mat-icon-button [disabled]="disabled" (click)="openDepartmentDialog($event)">
      <mat-icon class="iconColor">domain</mat-icon>
    </button>
    <button type="button" mat-icon-button [disabled]="disabled" (click)="onClickClear($event)">
      <mat-icon class="deleteIcon">clear</mat-icon>
    </button>
  </div>
</mat-form-field>

<!--<ng-template #template>-->
<!--  <div class="department-menu" [@transformPanel] (@transformPanel.done)="focusFilter()">-->
<!--    <ng-container *ngIf="_departments.length > 0">-->
<!--      <div class="department-menu-header">-->
<!--        <input #filter-->
<!--               matInput-->
<!--               [(ngModel)]="_searchText"-->
<!--               (ngModelChange)="onChangeSearchText()"-->
<!--               class="department-menu-search"-->
<!--               placeholder="{{ 'enterSearchTerm' | translate }}"-->
<!--               autocomplete="disabled">-->
<!--        <button *ngIf="multiple" mat-icon-button class="nested-select-toggle" (click)="toggleAllNodes()" matTooltip="{{ 'selectAllEntries' | translate }}">-->
<!--          <mat-icon class="iconColor">playlist_add_check</mat-icon>-->
<!--        </button>-->
<!--      </div>-->
<!--      <mat-divider></mat-divider>-->
<!--      <mat-tree *ngIf="_dataSource.data.length > 0" [dataSource]="_dataSource" [treeControl]="_treeControl">-->
<!--        <mat-nested-tree-node *matTreeNodeDef="let node">-->
<!--          <div class="tree-node">-->
<!--            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node[labelField]" [disabled]="!node.children.length">-->
<!--              <mat-icon class="mat-icon-rtl-mirror iconColor" *ngIf="node.children.length">{{ _treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}</mat-icon>-->
<!--            </button>-->
<!--            <mat-checkbox *ngIf="multiple"-->
<!--                          class="tree-node-checkbox"-->
<!--                          [checked]="allChildrenSelected(node)"-->
<!--                          [indeterminate]="someChildrenSelected(node)"-->
<!--                          (change)="onChangeCheckbox(node)">{{ node[labelField] }}</mat-checkbox>-->
<!--            <span *ngIf="!multiple" class="tree-node-label" (click)="selectSingleNode(node)">{{ node[labelField] }}</span>-->
<!--          </div>-->
<!--          <div class="tree-node-children" [class.tree-node-hidden]="!_treeControl.isExpanded(node)">-->
<!--            <ng-container matTreeNodeOutlet></ng-container>-->
<!--          </div>-->
<!--        </mat-nested-tree-node>-->
<!--      </mat-tree>-->
<!--    </ng-container>-->
<!--    <div *ngIf="!_dataSource.data.length" class="tree-node-empty-label">{{ 'noEntriesFound' | translate }}</div>-->
<!--  </div>-->
<!--</ng-template>-->
