import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {DataResponse, ListResponse, SuccessResponse} from '../models/response.model';
import {SchoolingPlan, SchoolingPlanDetails, SchoolingPlanStatusModel} from '../models/schooling-plan.model';
import {SchoolingOnlineState} from '../models/schooling-online.model';

const cacheNotifier: Subject<void> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class SchoolingPlanService {

  constructor(private api: ApiService) {
  }

  getList(params?: object): Observable<ListResponse<SchoolingPlan>> {
    return this.api.get(`/schooling/plans`, params);
  }

  getListDetail(params?: object): Observable<ListResponse<SchoolingPlanDetails>> {
    return this.api.get(`/schooling/plan-list`, params);
  }

  getListSchoolingStatus(params?: object): Observable<ListResponse<SchoolingPlanStatusModel>> {
    return this.api.get(`/schooling/schoolingStatusModel-list`, params);
  }

  getListSchoolingState(params?: object): Observable<ListResponse<SchoolingOnlineState>> {
    return this.api.get(`/schooling/onlineStateModel-list`, params);
  }

  getById(id: number): Observable<SchoolingPlan> {
    return this.api.get(`/schooling/plan/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<SchoolingPlan>> {
    return this.api.post(`/schooling/plan`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  createMultiple(data: object): Observable<DataResponse<SuccessResponse>> {
    return this.api.post(`/schooling/plans`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<SchoolingPlan>> {
    return this.api.put(`/schooling/plan/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/schooling/plan/${id}`);
  }

  getPrintURL(id: number, jwt: string): string {
    return `${this.api.apiURL}/schooling/plan/${id}/print?jwt=${jwt}`;
  }

  getExportURL(filter: object, sortingParams: object, jwt: string) {
    return `${this.api.apiURL}/schooling/plans/export?filter=${JSON.stringify(filter)}&${this.api.getSortingStringForExport(sortingParams)}&jwt=${jwt}`;
  }


  getPlanMatrixExportURL(filter: object, sortingParams: object, jwt: string) {
    return `${this.api.apiURL}/schooling/plan-matrix/export?filter=${JSON.stringify(filter)}&${this.api.getSortingStringForExport(sortingParams)}&jwt=${jwt}`;
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  sendReminder(data: object): Observable<SuccessResponse> {
    return this.api.post(`/schooling/reminder`, data);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
