import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';
import { ObservationDialogComponent } from '@shared/components/observation-dialog/observation-dialog.component';
import { DateSelectModule } from '@shared/components/date-select/date-select.module';
import { DepartmentSelectModule } from '@shared/components/department-select/department-select.module';
import { FileManagementPanelModule } from '@shared/components/file-management-panel/file-management-panel.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmployeeSelectModule } from '@shared/components/employee-select/employee-select.module';
import { ObservationTypeSelectModule } from '@shared/components/observation-type-select/observation-type-select.module';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner/loading-spinner.module';
import {TimeInputModule} from '@shared/components/time-input/time-input.module';
import {DirectivesModule} from "@shared/directives/directives.module";

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        LoadingOverlayModule,
        LoadingSpinnerModule,
        ObservationTypeSelectModule,
        DateSelectModule,
        DepartmentSelectModule,
        FileManagementPanelModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        EmployeeSelectModule,
        TimeInputModule,
        DirectivesModule,
    ],
    declarations: [
        ObservationDialogComponent,
    ],
    exports: [
        ObservationDialogComponent,
    ]
})
export class ObservationDialogModule {}
