import { NgModule } from '@angular/core';
import { AccidentCauseListComponent } from './accident-cause-list/accident-cause-list.component';
import { SharedModule } from '@shared/shared.module';
import { AccidentCauseRoutingModule } from '@frontend/modules/accident-cause/accident-cause-routing.module';
import { AccidentCauseDialogComponent } from './accident-cause-dialog/accident-cause-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        AccidentCauseRoutingModule,
    ],
    declarations: [
        AccidentCauseDialogComponent
    ]
})
export class AccidentCauseModule { }
