import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { MeasureTemplate } from '@shared/models/measure-template.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class MeasureTemplateService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(hazardTemplateId: number, params?: object): Observable<ListResponse<MeasureTemplate>> {
    return this.api.get(`/classificationMeasureTemplates`, { hazardTemplateId, ...params });
  }

  getById(id: number): Observable<MeasureTemplate> {
    return this.api.get(`/classificationMeasureTemplate/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<MeasureTemplate>> {
    return this.api.post(`/classificationMeasureTemplate`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<MeasureTemplate>> {
    return this.api.put(`/classificationMeasureTemplate/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/classificationMeasureTemplate/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
