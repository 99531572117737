import { NgModule } from '@angular/core';
import { EmployeeCategorySelectModule } from '../employee-category-select/employee-category-select.module';
import { EmployeeCategoryFilterComponent } from './employee-category-filter.component';
import { FilterModule } from '@shared/components/filter/filter.module';

@NgModule({
  imports: [
    EmployeeCategorySelectModule,
    FilterModule,
  ],
  declarations: [
    EmployeeCategoryFilterComponent,
  ],
  exports: [
    EmployeeCategoryFilterComponent,
  ],
})
export class EmployeeCategoryFilterModule {}
