import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccidentBookListComponent } from '@frontend/modules/accident-book/accident-book-list/accident-book-list.component';
import { AccidentCauseListComponent } from '@frontend/modules/accident-cause/accident-cause-list/accident-cause-list.component';

const routes: Routes = [
  { path: '', component: AccidentCauseListComponent, data: { title: '' } },
  // { path: 'create', component: AccidentCauseListComponent, data: { title: 'createAccident' } },
  // { path: ':id', component: AccidentCauseListComponent, data: { title: 'editAccident' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccidentCauseRoutingModule {}
