import {AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {Country} from '@shared/models/country.model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TranslationService} from '@shared/services/translation.service';
import {CountryService} from '@shared/services/country.service';
import {filter, tap} from 'rxjs/operators';
import {CountrySelectDialogComponent} from '@shared/components/country-select-dialog/country-select-dialog.component';
import {killEvent} from '@shared/utils/utils';

@Component({
  selector: 'saf-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountrySelectComponent),
      multi: true
    }
  ],
})
export class CountrySelectComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  _valueText: string = '';

  private country: Country;
  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) { this._required = coerceBooleanProperty(value); }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled }
  set disabled(value: boolean) { this._disabled = coerceBooleanProperty(value); }
  private _disabled = false;

  @Input()
  get multiple(): boolean { return this._multiple; }
  set multiple(value: boolean) { this._multiple = coerceBooleanProperty(value); }
  private _multiple = false;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) { this._placeholder = value; }
  private _placeholder: string;

  @Input()
  get placeholderSearchTerm(): string { return this._placeholderSearchTerm; }
  set placeholderSearchTerm(value: string) { this._placeholderSearchTerm = value; }
  private _placeholderSearchTerm: string;

  @Input()
  get appearance(): MatFormFieldAppearance { return this._appearance; }
  set appearance(value: MatFormFieldAppearance) { this._appearance = value; }
  private _appearance: MatFormFieldAppearance = 'outline';

  @Input()
  get value(): any { return this._value; }
  set value(value: any) { this.writeValue(value); }
  private _value: any = null;

  get loading(): boolean { return this._loading; }
  private _loading: boolean = false;

  @Input()
  get valueField(): string | null { return this._valueField; }
  set valueField(value: string | null) { this._valueField = value; }
  private _valueField: string | null = null;

  get showClearButton(): boolean {
    return this.hasValue && !this.disabled && (this.multiple || !this.required);
  }

  get hasValue(): boolean {
    return this.multiple ? this.value && this.value.length : this.value !== null;
  }

  get hasNoValue(): boolean {
    if (this._valueText === '' || this.value === null ) {
      return true;
    }
  }

  @Output() valueChange = new EventEmitter<any>();

  onChange: Function = () => {};
  onTouched: Function = () => {};

  constructor(
    private countryService: CountryService,
    private dialog: MatDialog,
    private translationService: TranslationService
  ) { }

  ngOnInit() {

  }
  ngAfterViewInit() {
    this.loadById(this.value);
  }

  loadById(id: number) {
    if (id) {
      this._loading = true;

      this.countryService.getById(id)
        .pipe(tap(() => this._loading = false))
        .subscribe((response) => {
          this._valueText = response.name;
          this.country = response;
        });
    }
  }

  openCountrySelectDialog(e) {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'table-dialog',
      disableClose: false,
      data: {
        multiple: this.multiple,
        selected: this.country ? [this.country] : null,
        placeholder: this._placeholder,
        placeholderSearchTerm: this._placeholderSearchTerm
      }
    };

    this.dialog.open(CountrySelectDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(filter((result) => result))
      .subscribe((result) => {
        this.value = this.multiple
          ? result.map(country => country.id)
          : result[0].id;
        // this.value = this.multiple
        //   ? this.country = result
        //   : this.country = result[0].id;
        this._valueText = this.multiple
          ? result.map((country) => country.name).join(', ')
          : result[0].name;
      });

    killEvent(e);
  }

  registerOnChange(onChange: Function) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.valueChange.emit(value);
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  clear() {
    this.value = null;
    this._valueText = null;
    this.country = null;
  }

  onClickClear(event) {
    this.clear();
    event.stopImmediatePropagation();
  }
}
