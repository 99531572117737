<mat-form-field [appearance]="appearance">
  <mat-label>{{ placeholder || ('enterSearchTerm' | translate) }}</mat-label>
  <input matInput [safAutofocus]="autofocus" [(ngModel)]="value" [required]="required" [disabled]="disabled" autocomplete="off">
  <button mat-button *ngIf="!showClearButton" [disabled]="disabled" type="button" matSuffix mat-icon-button>
    <mat-icon class="iconColor">search</mat-icon>
  </button>
  <button mat-button *ngIf="showClearButton" [disabled]="disabled" type="button" matSuffix mat-icon-button (click)="onClickClear($event)">
    <mat-icon class="deleteIcon">close</mat-icon>
  </button>
</mat-form-field>
