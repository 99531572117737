import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeInputComponent } from './time-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        PipesModule,
    ],
  declarations: [
    TimeInputComponent,
  ],
  exports: [
    TimeInputComponent,
  ],
})
export class TimeInputModule {}
