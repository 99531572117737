import { NgModule } from '@angular/core';
import { DepartmentSelectModule } from '../department-select/department-select.module';
import { DepartmentFilterComponent } from './department-filter.component';
import { FilterModule } from '@shared/components/filter/filter.module';

@NgModule({
  imports: [
    DepartmentSelectModule,
    FilterModule,
  ],
  declarations: [
    DepartmentFilterComponent,
  ],
  exports: [
    DepartmentFilterComponent,
  ],
})
export class DepartmentFilterModule {}
