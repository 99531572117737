import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Terminal } from '@shared/models/terminal.model';
import { TerminalActivity } from '@shared/models/terminal-activity.model';
import { ExternalCompanyEmployeeService } from '@shared/services/external-company-employee.service';
import { tap } from 'rxjs/operators';
import { ExternalCompanyService } from '@shared/services/external-company.service';
import { ExternalPresenceService } from '@shared/services/external-presence.service';
import { ExternalCompanyEmployeeDetails } from '@shared/models/external-company-employee-details.model';

@Component({
  selector: 'saf-terminal-page-confirm-external-employee-view',
  templateUrl: './terminal-page-confirm-external-employee-view.component.html',
  styleUrls: ['./terminal-page-confirm-external-employee-view.component.scss']
})
export class TerminalPageConfirmExternalEmployeeViewComponent implements OnInit {
  @Input() terminal: Terminal;
  @Input() activity: TerminalActivity;
  @Output() viewCompleted = new EventEmitter();
  form: UntypedFormGroup;
  loading: boolean;
  externalCompanyEmployeeDetails: ExternalCompanyEmployeeDetails;

  constructor(
    private externalCompanyService: ExternalCompanyService,
    private externalCompanyEmployeeService: ExternalCompanyEmployeeService,
    private externalPresenceService: ExternalPresenceService,
  ) {}

  ngOnInit() {
    this.loading = true;

    this.externalCompanyEmployeeService.getDetails(this.activity.externalEmployeeId)
      .pipe(tap(() => this.loading = false))
      .subscribe((externalCompanyEmployeeDetails) => this.buildForm(externalCompanyEmployeeDetails));
  }

  buildForm(externalCompanyEmployeeDetails: ExternalCompanyEmployeeDetails) {
    const { externalCompany, externalEmployee } = externalCompanyEmployeeDetails;

    this.form = new UntypedFormGroup({
      companyName: new UntypedFormControl(externalCompany.name),
      firstName: new UntypedFormControl(externalEmployee.firstName),
      name: new UntypedFormControl(externalEmployee.name),
    });

    this.externalCompanyEmployeeDetails = externalCompanyEmployeeDetails;
  }

  onSubmit() {
    if (this.form.valid) {
      const params = {
        terminalId: this.terminal.id,
        externalEmployeeId: this.activity.externalEmployeeId,
      };

      if (this.externalCompanyEmployeeDetails.referenceEmployee) {
        params['referenceEmployeeId'] = this.externalCompanyEmployeeDetails.referenceEmployee.id;
      }

      this.loading = true;

      this.externalPresenceService.create(params)
        .pipe(tap(() => this.loading = false))
        .subscribe(() => this.viewCompleted.emit());
    }
  }
}
