import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamTypeSelectAddComponent } from './exam-type-select-add.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {PipesModule} from "@shared/pipes/pipes.module";



@NgModule({
  declarations: [
    ExamTypeSelectAddComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        PipesModule,
        ReactiveFormsModule
    ]
})
export class ExamTypeSelectAddModule { }
