import {Component, Input, OnInit} from '@angular/core';
import { TranslationService } from '@shared/services/translation.service';
import { AuthService } from '@shared/services/auth.service';
import { Employee } from '@shared/models/employee.model';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '@shared/store/auth/auth.state';
import { ToggleSidenav } from '@frontend/store/frontend/frontend.actions';
import { AssetService } from '@shared/services/asset.service';
import {CompanySettingModel} from '@shared/models/companySettingModel';
import {Department} from '@shared/models/department.model';
import {DepartmentService} from '@shared/services/department.service';
import {SelectSnapshot} from '@ngxs-labs/select-snapshot';
import {BreakpointState} from '@shared/store/breakpoint/breakpoint.state';
import {Breakpoints} from '@shared/models/breakpoints.model';
import {isMobileViewport} from '@shared/utils/utils';

@Component({
  selector: 'saf-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent implements OnInit {
  @Select(AuthState.employee) employee$;
  public mobileViewport = false;
  private employee: Employee;
  public companySettings = new CompanySettingModel;
  public department = new Department();
  public systemUser: boolean = false;

  @Input()
  get showToggle(): boolean { return this._showToggle; }
  set showToggle(value: boolean) { this._showToggle = value; }
  private _showToggle: boolean = true;


  get avatarURL() {
    if (!this.employee) {
      return '';
    }

    return this.assetService.getAssetURL(this.employee.imageURLPath);
  }

  get name() {
    if (!this.employee) {
      return this.translationService.translate('systemUser');
    }

    return `${this.employee.firstName} ${this.employee.name}`;
  }

  get companyLogoUrl() {
    if (!this.department) {
      return '';
    }

    return this.assetService.getAssetURL(this.department.logoURL);
  }

  get title() {
    return this.department.name;
    // if (!this.employee) {
    //   return this.translationService.translate('admin');
    // }
    //
    // return this.employee.modeOfEmployment
    //   ? this.employee.modeOfEmployment
    //   : this.translationService.translate('employee');
  }

  constructor(
    private store: Store,
    private assetService: AssetService,
    private departmentService: DepartmentService,
    private authService: AuthService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.mobileViewport = isMobileViewport();
    this.employee$.subscribe((employee) => this.employee = employee);

    if (this.employee) {
      this.companySettings = JSON.parse(localStorage.getItem('companySettings'));
      this.departmentService.getById(this.companySettings.companyId)
        .pipe()
        .subscribe((result) => {
          this.department = result;
        } );
    } else {
      this.systemUser = true;
    }
  }

  toggleSidenav() {
    this.store.dispatch(new ToggleSidenav());
  }
}
