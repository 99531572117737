import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChecklistDropDownAddEntryComponent} from '@shared/components/checklist-drop-down-add-entry/checklist-drop-down-add-entry.component';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FlexModule} from '@angular/flex-layout';



@NgModule({
  declarations: [ChecklistDropDownAddEntryComponent],
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        ReactiveFormsModule,
        FlexModule
    ],
  exports: [ChecklistDropDownAddEntryComponent]
})
export class ChecklistDropDownAddEntryModule { }
