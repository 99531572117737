import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslationTableFilterComponent} from '@shared/components/translation-table-filter/translation-table-filter.component';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FilterModule} from '@shared/components/filter/filter.module';



@NgModule({
  declarations: [TranslationTableFilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    FilterModule,
  ],
  exports: [TranslationTableFilterComponent]
})
export class TranslationTableFilterModule { }
