import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreeningPackageSelectDialogComponent } from './screening-package-select-dialog.component';
import {EmptyMessageModule} from "@shared/components/empty-message/empty-message.module";
import {FilterPanelModule} from "@shared/components/filter-panel/filter-panel.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {LoadingOverlayModule} from "@shared/components/loading-overlay/loading-overlay.module";
import {LoadingSpinnerModule} from "@shared/components/loading-spinner/loading-spinner.module";
import {PipesModule} from "@shared/pipes/pipes.module";
import {MaterialModule} from "@shared/material.module";
import {FormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    ScreeningPackageSelectDialogComponent
  ],
    imports: [
      CommonModule,
      MaterialModule,
      FormsModule,
      PipesModule,
      FlexLayoutModule,
      FilterPanelModule,
      EmptyMessageModule,
      LoadingSpinnerModule,
      LoadingOverlayModule
    ],
  exports: [
    ScreeningPackageSelectDialogComponent
  ]
})
export class ScreeningPackageSelectDialogModule { }
