import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { DataResponse, SuccessResponse, ListResponse } from '../models/response.model';
import { HazardAssessmentTemplateHazard } from '../models/hazard-assessment-template-details.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateHazardService {

  constructor(private api: ApiService) {}

  getList(templateClassificationId: number): Observable<ListResponse<HazardAssessmentTemplateHazard>> {
    return this.api.get(`/templateHazards`, { templateClassificationId });
  }

  getById(hazardId: number): Observable<HazardAssessmentTemplateHazard> {
    return this.api.get(`/templateHazard/${hazardId}`);
  }

  create(data: object): Observable<DataResponse<HazardAssessmentTemplateHazard>> {
    return this.api.post(`/templateHazard`, data);
  }

  update(hazardId: number, data: object): Observable<DataResponse<HazardAssessmentTemplateHazard>> {
    return this.api.put(`/templateHazard/${hazardId}`, data);
  }

  delete(hazardId: number): Observable<SuccessResponse> {
    return this.api.delete(`/templateHazard/${hazardId}`);
  }
}
