import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {AppConfig} from '../models/app-config.model';
import {environment} from '../../../environments/environment';

@Injectable()
export class AppConfigService {

  get appConfig() {
    return this._appConfig;
  }
  private _appConfig: AppConfig;

  constructor(private http: HttpClient) {}

  load(): Observable<AppConfig> {
    // todo - fetch config from database
    return this.http.get<AppConfig>(`assets/config/${environment.config}.json`)
      .pipe(
        tap((appConfig) => this._appConfig = appConfig)
      );
  }
}
