import { NgModule } from '@angular/core';
import { FrontendComponent } from '@frontend/frontend.component';
import { HeaderComponent } from '@frontend/components/header/header.component';
import { UserPanelComponent } from '@frontend/components/user-panel/user-panel.component';
import { NavigationComponent } from '@frontend/components/navigation/navigation.component';
import { SharedModule } from '@shared/shared.module';
import { FrontendRoutingModule } from '@frontend/frontend-routing.module';
import { SearchBarComponent } from '@frontend/components/search-bar/search-bar.component';
import { NotificationMenuComponent } from './components/notification-menu/notification-menu.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { NgxsModule } from '@ngxs/store';
import { FrontendState } from '@frontend/store/frontend/frontend.state';
import { SettingsState } from '@frontend/store/settings/settings.state';
import { NavigationWithChildComponent } from './components/navigation-with-child/navigation-with-child.component';
import { NavigationListItemComponent } from './components/navigation-list-item/navigation-list-item.component';
import { AccidentObservationComponent } from './modules/accident-observation/accident-observation.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuditMobileListComponent } from './mobile/audit-mobile-list/audit-mobile-list.component';
import { AuditMobileDetailsInspectionListComponent } from './mobile/audit-mobile-details-inspection-list/audit-mobile-details-inspection-list.component';
import { InspectionMobileListComponent } from './mobile/inspection-mobile-list/inspection-mobile-list.component';

@NgModule({
    imports: [
        SharedModule,
        FrontendRoutingModule,
        NgxsModule.forFeature([
            FrontendState,
            SettingsState,
        ]),
    ],
    declarations: [
        FrontendComponent,
        HeaderComponent,
        SearchBarComponent,
        UserPanelComponent,
        NavigationComponent,
        NotificationMenuComponent,
        BreadcrumbsComponent,
        NavigationWithChildComponent,
        NavigationListItemComponent,
        AccidentObservationComponent,
        FooterComponent,
        AuditMobileListComponent,
        AuditMobileDetailsInspectionListComponent,
        InspectionMobileListComponent,
    ],
  exports: [
    SearchBarComponent,
    AuditMobileDetailsInspectionListComponent
  ]
})
export class FrontendModule {}
