import { Injectable } from '@angular/core';
import { ApiService } from '@shared/services/api.service';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { Observable, Subject } from 'rxjs';
import { DataResponse, ListResponse, SuccessResponse } from '@shared/models/response.model';
import { Language } from '@shared/models/language.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getAll(params?: object): Observable<ListResponse<Language>> {
    return this.api.get(`/languages`, params);
  }

  getById(id): Observable<Language> {
    return this.api.get(`/language/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Language>> {
    return this.api.post(`/language`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<Language>> {
    return this.api.put(`/language/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  setLanguage(id: number): Observable<DataResponse<Language>> {
    return this.api.put(`/languageSession/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/language/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
