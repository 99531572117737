<div class="overlay" *ngIf="!start">
  <button class="playButton" mat-raised-button (click)="startSchooling()">
    <mat-icon class="playCircleSchooling play_circle_outline">play_circle</mat-icon>
  </button>
</div>
<div class="container">

  <div class="row">
    <section [class]="fold ? 'tableFold' : 'tableUnfold'">
      <mat-card class="content">
        <div class="table-container" [fxShow]="dataSource.data.length">
          <table mat-table [dataSource]="dataSource" id="table" class="table" matSort matSortActive="pageNumber" matSortStart="asc"
                 [trackBy]="trackById">

            <ng-container matColumnDef="number">
              <th mat-header-cell *matHeaderCellDef>{{ 'number' | translate }}</th>
              <td mat-cell *matCellDef="let index">
                <span [matTooltip]="index.pageNumber">{{ index.pageNumber }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef>{{ 'title' | translate }}</th>
              <td mat-cell *matCellDef="let index">
                <span [matTooltip]="index.name">{{ index.name }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>{{ 'type' | translate }}</th>
              <td mat-cell *matCellDef="let index">
                <mat-icon matTooltip="{{getTypeDescription(index.pageType)}}">{{getTypeIcon(index.pageType)}}</mat-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
            <tr mat-row class="selectable" *matRowDef="let row; columns: tableColumns;"></tr>
          </table>
          <mat-paginator class="mat-paginator-sticky" [pageSize]="10000" [pageSizeOptions]="pageSizeOptions"
                         [hidePageSize]="true" [length]="totalCount" [showFirstLastButtons]="true" (page)="pageChange($event)"></mat-paginator>
        </div>
        <saf-empty-message *ngIf="!dataSource.data.length && !loading" (reloadClicked)="reloadTable()"></saf-empty-message>
      </mat-card>
    </section>

    <div class="foldButton" (click)="foldTable()">
      <mat-icon *ngIf="!fold">chevron_left</mat-icon>
      <mat-icon *ngIf="fold">chevron_right</mat-icon>
    </div>

    <section [class]="fold ? 'viewFold' : 'viewUnfold'">
      <mat-card class="contentBox">
<!--
        <saf-schooling-index-content [indexId]="lastIndexId" [fire]="fire" (result)="showQuestionResult($event)"
                                     (isContentLoading)="isLoading($event)"></saf-schooling-index-content>
-->
      </mat-card>
    </section>

  </div>

  <div class="buttons">
    <button mat-raised-button (click)="back()" disabled="{{pageNumber <= 1 || contentLoading}}">
      <div class="buttonContent">
        <mat-icon>arrow_back</mat-icon>
        <p>{{ 'back' | translate }}</p>
      </div>
    </button>

    <button mat-raised-button *ngIf="pageNumber !== dataSource.data.length" disabled="{{executionSeconds !== 0 || contentLoading}}">
      <div class="buttonContent" *ngIf="pageType !== 1 || quizSuccess" (click)="next()"
           style="{{executionSeconds !== 0 || contentLoading ? 'pointer-events: none' : ''}}">
        <div class="spinnerArea" *ngIf="executionSeconds !== 0">
          <mat-progress-spinner diameter="30" value="{{progress}}" mode="determinate"></mat-progress-spinner>
          <p>{{executionSeconds}}</p>
        </div>
        <p>{{ 'next' | translate }}</p>
        <mat-icon>arrow_forward</mat-icon>
      </div>
      <div class="buttonContent" *ngIf="pageType === 1 && !quizSuccess" (click)="showResult()"
           style="{{executionSeconds !== 0 ? 'pointer-events: none' : ''}}">
        <div class="spinnerArea" *ngIf="executionSeconds !== 0">
          <mat-progress-spinner diameter="30" value="{{progress}}" mode="determinate"></mat-progress-spinner>
          <p>{{executionSeconds}}</p>
        </div>
        <p>{{ 'result' | translate }}</p>
        <mat-icon>lightbulb</mat-icon>
      </div>
    </button>

    <button mat-raised-button *ngIf="pageNumber === dataSource.data.length" disabled="{{executionSeconds !== 0 || contentLoading}}">
      <div class="buttonContent" *ngIf="pageType !== 1 || quizSuccess" (click)="finish()"
           style="{{executionSeconds !== 0 || contentLoading ? 'pointer-events: none' : ''}}">
        <div class="spinnerArea" *ngIf="executionSeconds !== 0">
          <mat-progress-spinner diameter="30" value="{{progress}}" mode="determinate"></mat-progress-spinner>
          <p>{{executionSeconds}}</p>
        </div>
        <p>{{ 'ready' | translate }}</p>
        <mat-icon>check</mat-icon>
      </div>
      <div class="buttonContent" *ngIf="pageType === 1 && !quizSuccess" (click)="showResult()"
           style="{{executionSeconds !== 0 ? 'pointer-events: none' : ''}}">
        <div class="spinnerArea" *ngIf="executionSeconds !== 0">
          <mat-progress-spinner diameter="30" value="{{progress}}" mode="determinate"></mat-progress-spinner>
          <p>{{executionSeconds}}</p>
        </div>
        <p>{{ 'result' | translate }}</p>
        <mat-icon>lightbulb</mat-icon>
      </div>
    </button>
  </div>
</div>

