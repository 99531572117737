import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { PipesModule } from '../../pipes/pipes.module';
import { ProfileSelectComponent } from './profile-select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
  ],
  declarations: [
    ProfileSelectComponent,
  ],
  exports: [
    ProfileSelectComponent,
  ],
})
export class ProfileSelectModule {}
