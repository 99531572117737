import { Action, Selector, State, StateContext } from '@ngxs/store';
import { parseJSON } from '@shared/utils/utils';
import { LoadSettings, SaveSettings, SetSettings } from '@frontend/store/settings/settings.actions';
import { SetLanguage } from '@shared/store/language/language.actions';
import { SetTheme } from '@shared/store/theme/theme.actions';
import { Language } from '@shared/models/language.model';
import { Theme } from '@shared/models/theme.model';
import { FormState } from '@shared/models/form-state.model';
import {Injectable} from '@angular/core';

export class SettingsStateModel {
  language: Language;
  theme: Theme;
  loading: boolean;
  settingsForm?: FormState<{
    language: Language,
    theme: Theme,
  }>;
}

@State<SettingsStateModel>({
  name: 'settings',
  defaults: {
    language: null,
    theme: null,
    loading: false,
  }
})

@Injectable()
export class SettingsState {

  @Selector()
  static language(state: SettingsStateModel) {
    return state.language;
  }

  @Selector()
  static theme(state: SettingsStateModel) {
    return state.theme;
  }

  @Selector()
  static settingsForm(state: SettingsStateModel) {
    return state.settingsForm;
  }

  @Action(LoadSettings)
  loadSettings({ dispatch }: StateContext<SettingsStateModel>) {
    if (localStorage.getItem('settings')) {
      const settings = parseJSON(localStorage.getItem('settings'));

      if (settings) {
        dispatch(new SetSettings(settings));
      }
    }
  }

  @Action(SaveSettings)
  saveSettings({ getState }: StateContext<SettingsStateModel>) {
    localStorage.setItem('settings', JSON.stringify(getState()));
  }

  @Action(SetSettings)
  setSettings({ patchState, dispatch }: StateContext<SettingsStateModel>, { payload }: SetSettings) {
    patchState({
      language: payload.language,
      theme: payload.theme,
    });

    dispatch(new SetLanguage(payload.language));
    dispatch(new SetTheme(payload.theme));
  }
}
