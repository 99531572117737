<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="submitForm()">
  <div class="closeCross">
    <span mat-dialog-title>{{ 'createEquipmentCategory' | translate }}</span>
    <mat-icon (click)="close()" class="cross">close</mat-icon>
  </div>
  <div mat-dialog-content class="block-fields">

    <mat-form-field appearance="outline">
      <mat-label>{{ 'title' | translate }}</mat-label>
      <input type="text" matInput formControlName="name" required>
    </mat-form-field>

    <saf-department-select placeholder="{{ 'company' | translate }}" [isCompany]="true" formControlName="companyId" required></saf-department-select>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'description' | translate }}</mat-label>
      <textarea cdkTextareaAutosize matInput formControlName="description"></textarea>
    </mat-form-field>

    <saf-equipment-category-select [isFilter]="true" placeholder="{{ 'parentCategory' | translate }}" formControlName="parentCategoryId"></saf-equipment-category-select>

  </div>
  <div mat-dialog-actions align="end">
    <button class="administration deleteButtonHover" mat-raised-button type="button" color="primary" (click)="close()">{{ 'cancel' | translate }}</button>
    <button class="administration saveButtonHover" mat-raised-button type="submit" color="primary">{{ 'save' | translate }}</button>
  </div>
</form>

