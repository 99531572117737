import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ExternalCompanyService } from '@shared/services/external-company.service';
import {
  CreateExternalCompany,
  DeleteExternalCompany,
  LoadExternalCompanies, UpdateExternalCompany
} from '@shared/store/external-company/external-company.actions';
import { ExternalCompany } from '@shared/models/external-company.model';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import {Injectable} from '@angular/core';

export class ExternalCompanyStateModel {
  externalCompanies: ExternalCompany[];
  loading: boolean;
}

@State<ExternalCompanyStateModel>({
  name: 'externalCompany',
  defaults: {
    externalCompanies: [],
    loading: false,
  }
})
@Injectable()
export class ExternalCompanyState {

  @Selector()
  static externalCompanies(state: ExternalCompanyStateModel) {
    return state.externalCompanies;
  }

  @Selector()
  static loading(state: ExternalCompanyStateModel) {
    return state.loading;
  }

  constructor(private externalCompanyService: ExternalCompanyService) {}

  @Action(LoadExternalCompanies, { cancelUncompleted: true })
  loadExternalCompanies({ patchState }: StateContext<ExternalCompanyStateModel>) {
    patchState({ loading: true });

    return this.externalCompanyService.getAll()
      .pipe(
        tap((result) => patchState({
          externalCompanies: result.data,
          loading: false,
        }))
      );
  }

  @Action(CreateExternalCompany)
  createExternalCompanyCategory({ patchState, setState }: StateContext<ExternalCompanyStateModel>, { payload }: CreateExternalCompany) {
    patchState({ loading: true });

    return this.externalCompanyService.create(payload)
      .pipe(
        tap((result) => {
          setState(patch<ExternalCompanyStateModel>({
            externalCompanies: append([result.data]),
            loading: false,
          }))
        })
      );
  }

  @Action(UpdateExternalCompany)
  updateExternalCompanyCategory({ patchState, setState }: StateContext<ExternalCompanyStateModel>, { id, payload }: UpdateExternalCompany) {
    patchState({ loading: true });

    return this.externalCompanyService.update(id, payload)
      .pipe(
        tap((result) => {
          setState(patch<ExternalCompanyStateModel>({
            externalCompanies: updateItem((category) => category.id === id, result.data),
            loading: false,
          }))
        })
      );
  }

  @Action(DeleteExternalCompany)
  deleteExternalCompanyCategory({ patchState, setState }: StateContext<ExternalCompanyStateModel>, { id }: DeleteExternalCompany) {
    patchState({ loading: true });

    return this.externalCompanyService.delete(id)
      .pipe(
        tap(() => {
          setState(patch<ExternalCompanyStateModel>({
            externalCompanies: removeItem((category) => category.id === id),
            loading: false,
          }))
        })
      );
  }
}
