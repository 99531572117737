import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {QualificationSelectComponent} from './qualification-select.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../material.module';
import {PipesModule} from '../../pipes/pipes.module';
import {FlexLayoutModule} from '@angular/flex-layout';



@NgModule({
  declarations: [QualificationSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    FlexLayoutModule,
  ],
  exports: [QualificationSelectComponent]
})
export class QualificationSelectModule { }
