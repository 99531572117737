import { NgModule } from '@angular/core';
import { FilterPanelComponent } from 'app/shared/components/filter-panel/filter-panel.component';
import { SearchFilterModule } from '../search-filter/search-filter.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FilterModule } from '@shared/components/filter/filter.module';
import {CommonModule} from '@angular/common';
import {DirectivesModule} from '@shared/directives/directives.module';

@NgModule({
  declarations: [
    FilterPanelComponent,
  ],
    imports: [
        MaterialModule,
        FlexLayoutModule,
        SearchFilterModule,
        PipesModule,
        FilterModule,
        CommonModule,
        DirectivesModule,
    ],
  exports: [
    FilterPanelComponent,
  ],
})
export class FilterPanelModule {}
