import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Terminal } from '@shared/models/terminal.model';
import { TerminalActivity } from '@shared/models/terminal-activity.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { AddExternalCompanyEmployee, AddExternalCompanyEmployeeError, AddExternalCompanyEmployeeSuccess } from '@terminal/store/terminal-page/terminal-page.actions';
import { LanguageState } from '@shared/store/language/language.state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '@shared/services/translation.service';
import { TerminalPageState } from '@terminal/store/terminal-page/terminal-page.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';

@Component({
  selector: 'saf-terminal-page-add-external-employee-view',
  templateUrl: './terminal-page-add-external-employee-view.component.html',
  styleUrls: ['./terminal-page-add-external-employee-view.component.scss']
})
export class TerminalPageAddExternalEmployeeViewComponent implements OnInit {
  @Input() terminal: Terminal;
  @Input() activity: TerminalActivity;
  @Output() viewCompleted = new EventEmitter();
  form: UntypedFormGroup;

  @SelectSnapshot(TerminalPageState.externalCompany) externalCompany;

  @HostBinding('class.simple-view')
  get simpleView() {
    return this.terminal.simpleView;
  }

  constructor(
    private store: Store,
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    const currentLanguage = this.store.selectSnapshot(LanguageState.currentLanguage),
      externalCompanyId = this.activity.externalCompanyId || this.externalCompany.id;

    this.form = new UntypedFormGroup({
      externalCompanyId: new UntypedFormControl({ value: externalCompanyId, disabled: true }),
      salutation: new UntypedFormControl(''),
      title: new UntypedFormControl(''),
      firstName: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      login: new UntypedFormControl(''),
      password: new UntypedFormControl(''),
      languageId: new UntypedFormControl(currentLanguage ? currentLanguage.id : 1, Validators.required),
      email: new UntypedFormControl(''),
      employeeNumber: new UntypedFormControl(''),
      terminalId: new UntypedFormControl(this.terminal.id)
    });

    this.actions$.pipe(ofActionCompleted(AddExternalCompanyEmployeeSuccess))
      .subscribe(() => this.viewCompleted.emit());

    this.actions$.pipe(ofActionCompleted(AddExternalCompanyEmployeeError))
      .subscribe(() => this.snackBar.open(this.translationService.translate('ERROR')));
  }

  onSubmit() {
    if (this.form.valid) {
      this.store.dispatch(new AddExternalCompanyEmployee(this.store.selectSnapshot(TerminalPageState.externalEmployeeFormData)));
    }
  }
}
