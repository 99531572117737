import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ExternalCompanySelectComponent } from '@shared/components/external-company-select/external-company-select.component';
import { FilterChange } from '@shared/models/filter-change.model';
import { FilterComponent } from '@shared/components/filter/filter.component';

@Component({
  selector: 'saf-external-company-category-filter',
  templateUrl: './external-company-category-filter.component.html',
  styleUrls: ['./external-company-category-filter.component.scss'],
  host: { 'class': 'filter' },
  providers: [{
    provide: FilterComponent,
    useExisting: forwardRef(() => ExternalCompanyCategoryFilterComponent),
  }]
})
export class ExternalCompanyCategoryFilterComponent {
  @ViewChild(ExternalCompanySelectComponent) externalCompanySelect: ExternalCompanySelectComponent;
  @Output() valueChange = new EventEmitter<FilterChange>();
  @Output() valueReset = new EventEmitter<string>();
  @Input() filterParam: string = 'categoryIds';
  @Input() listName: string;
  @Input() placeholder: string;
  @Input() multiple: boolean = true;

  onChange(id: number | number[]) {
    this.valueChange.emit({
      param: this.filterParam,
      value: (Array.isArray(id) ? (id.length && id) : id) || null,
    });
  }

  clear() {
    this.externalCompanySelect.clear();
    this.valueReset.emit(this.filterParam);
  }
}
