import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FilterPanelModule } from '@shared/components/filter-panel/filter-panel.module';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';
import { HazardAssessmentTemplateDialogComponent } from '@shared/components/hazard-assessment-template-dialog/hazard-assessment-template-dialog.component';
import { HazardAssessmentTemplateStateFilterModule } from '@shared/components/hazard-assessment-template-state-filter/hazard-assessment-template-state-filter.module';
import { HazardAssessmentTemplateCategoryFilterModule } from '@shared/components/hazard-assessment-template-category-filter/hazard-assessment-template-category-filter.module';
import {ChipFilterModule} from '@shared/components/chip-filter/chip-filter.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    FilterPanelModule,
    HazardAssessmentTemplateCategoryFilterModule,
    HazardAssessmentTemplateStateFilterModule,
    LoadingOverlayModule,
    ChipFilterModule,
  ],
    declarations: [
        HazardAssessmentTemplateDialogComponent,
    ],
    exports: [
        HazardAssessmentTemplateDialogComponent,
    ]
})
export class HazardAssessmentTemplateDialogModule {}
