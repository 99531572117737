import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChecklistCategoryDialogComponent } from './checklist-category-dialog.component';
import {MaterialModule} from '@shared/material.module';
import {FilterPanelModule} from '@shared/components/filter-panel/filter-panel.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {PipesModule} from '@shared/pipes/pipes.module';
import {EmptyMessageModule} from '@shared/components/empty-message/empty-message.module';
import {LoadingSpinnerModule} from '@shared/components/loading-spinner/loading-spinner.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';



@NgModule({
  declarations: [
    ChecklistCategoryDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FilterPanelModule,
    FlexLayoutModule,
    PipesModule,
    EmptyMessageModule,
    LoadingSpinnerModule,
    LoadingOverlayModule
  ],
  exports: [
    ChecklistCategoryDialogComponent
  ]
})
export class ChecklistCategoryDialogModule { }
