import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DateSelectFilterComponent} from './date-select-filter.component';
import {DateSelectModule} from '@shared/components/date-select/date-select.module';



@NgModule({
  declarations: [DateSelectFilterComponent],
  imports: [
    CommonModule,
    DateSelectModule
  ],
  exports: [DateSelectFilterComponent]
})
export class DateSelectFilterModule { }
