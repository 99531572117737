export enum TerminalActivityType {
  ADD_EXTERNAL_EMPLOYEE = 0,
  CONFIRM_AND_SCHOOLING = 3,
  ADD_EXTERNAL_COMPANY = 4,
}

export enum TerminalActivityStatus {
  OPEN = 0,
  DONE = 1,
  CANCELLED = 2,
  TIMEOUT = 3,
}

export class TerminalActivity {
  activity: TerminalActivityType = null;
  activityStartedByUserId: number = null;
  companyId: number = null;
  externalCategoryId: number = null;
  externalCompanyId: number = null;
  externalEmployeeId: number = null;
  id: number = null;
  lastEditTimestamp: string = '';
  status: TerminalActivityStatus = TerminalActivityStatus.OPEN;
  terminalId: number = null;

  constructor(config?: Partial<TerminalActivity>) {
    if (config) {
      Object.keys(config)
        .filter(key => typeof config[key] !== 'undefined')
        .forEach(key => this[key] = config[key]);
    }
  }
}
