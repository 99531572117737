import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Role } from '@shared/models/role.model';
import { ApiService } from '@shared/services/api.service';
import { ListResponse } from '@shared/models/response.model';
import {CacheBuster} from 'ts-cacheable';
import {DataResponse, SuccessResponse} from "../models/response.model";

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private api: ApiService) {}

  getList(params?: object): Observable<ListResponse<Role>> {
    return this.api.get(`/roles`, params);
  }

  getById(id: number): Observable<Role> {
    return this.api.get(`/role/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Role>> {
    return this.api.post(`/role`, data);
  }

  update(id: number, data: object): Observable<DataResponse<Role>> {
    return this.api.put(`/role/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/role/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
