import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ApiService} from '@shared/services/api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {ListResponse} from '@shared/models/response.model';
import {BodypartModel} from '../models/bodypart.model';
import {DataResponse, SuccessResponse} from '../models/response.model';

const cacheNotifier: Subject<void> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class BodypartService {

  constructor(private api: ApiService) {
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<BodypartModel>> {
    return this.api.get(`/medical/bodyparts`, params);
  }

  getById(id): Observable<BodypartModel> {
    return this.api.get(`/medical/bodypart/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<BodypartModel>> {
    return this.api.post(`/medical/bodypart`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<BodypartModel>> {
    return this.api.put(`/medical/bodypart/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/medical/bodypart/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
