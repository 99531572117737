import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { DataResponse, SuccessResponse, ListResponse } from '../models/response.model';
import { HazardAssessmentTemplateMeasure } from '../models/hazard-assessment-template-details.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateMeasureService {

  constructor(private api: ApiService) {}

  getList(hazardId: number): Observable<ListResponse<HazardAssessmentTemplateMeasure>> {
    return this.api.get(`/templateMeasures`, { hazardId });
  }

  getById(templateMeasureId: number): Observable<HazardAssessmentTemplateMeasure> {
    return this.api.get(`/templateMeasure/${templateMeasureId}`);
  }

  create(data: object): Observable<DataResponse<HazardAssessmentTemplateMeasure>> {
    return this.api.post(`/templateMeasure`, data);
  }

  update(templateMeasureId: number, data: object): Observable<DataResponse<HazardAssessmentTemplateMeasure>> {
    return this.api.put(`/templateMeasure/${templateMeasureId}`, data);
  }

  delete(templateMeasureId: number): Observable<SuccessResponse> {
    return this.api.delete(`/templateMeasure/${templateMeasureId}`);
  }
}
