import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import {AppConfigService} from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  currentTitle$: BehaviorSubject<string>;

  constructor(
    private title: Title,
    private appConfigService: AppConfigService,
  ) {
    this.currentTitle$ = new BehaviorSubject<string>(this.title.getTitle());
    this.currentTitle$.subscribe(title => {
      if (title && title !== this.title.getTitle()) {
        this.title.setTitle(`${title} - ${this.appConfigService.appConfig.appTitle}`);
      }
    });
  }

  getTitle() {
    return this.currentTitle$.getValue();
  }

  setTitle(title: string) {
    if (title && (title !== this.getTitle())) {
      this.currentTitle$.next(title);
    }
  }
}
