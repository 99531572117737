import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentCategorySelectAddComponent } from './equipment-category-select-add.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "@shared/material.module";
import {PipesModule} from "@shared/pipes/pipes.module";
import {DepartmentSelectModule} from "@shared/components/department-select/department-select.module";
import {
  EquipmentCategorySelectModule
} from "@shared/components/equipment-category-select/equipment-category-select.module";
import {LoadingOverlayModule} from "@shared/components/loading-overlay/loading-overlay.module";

@NgModule({
  declarations: [
    EquipmentCategorySelectAddComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    DepartmentSelectModule,
    EquipmentCategorySelectModule,
    LoadingOverlayModule
  ],
  exports: [
    EquipmentCategorySelectAddComponent
  ]
})
export class EquipmentCategorySelectAddModule { }
