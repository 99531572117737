import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChecklistFieldDialogComponent} from '@shared/components/checklist-field-dialog/checklist-field-dialog.component';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {ReactiveFormsModule} from '@angular/forms';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';



@NgModule({
    declarations: [
        ChecklistFieldDialogComponent
    ],
    imports: [
        MaterialModule,
        CommonModule,
        PipesModule,
        ReactiveFormsModule,
        LoadingOverlayModule
    ],
    exports: [
        ChecklistFieldDialogComponent
    ]
})
export class ChecklistFieldDialogModule { }
