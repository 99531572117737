import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'saf-empty-message',
  templateUrl: './empty-message.component.html',
  styleUrls: ['./empty-message.component.scss']
})
export class EmptyMessageComponent {
  @Input() public icon: string = 'info_outline';
  @Input() public message: string = '';
  @Input() public reloadButton: boolean = true;
  @Output() reloadClicked = new EventEmitter();
}
