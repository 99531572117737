import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ImageQuickViewCardComponent } from '@shared/components/image-quick-view/image-quick-view-card/image-quick-view-card.component';
import { ImageQuickViewDirective } from '@shared/components/image-quick-view/image-quick-view.directive';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        PipesModule,
        FlexLayoutModule,
    ],
    declarations: [
        ImageQuickViewCardComponent,
        ImageQuickViewDirective,
    ],
    exports: [
        ImageQuickViewCardComponent,
        ImageQuickViewDirective,
    ]
})
export class ImageQuickViewModule {}
