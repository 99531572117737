export class Permission {
  companyId: number;
  description: string;
  group: string;
  id: number;
  name: string;
  translatedGroupName: string;
  translatedName: string;

}
export class PermissionKeys {
  // Safety
  static SAFETY_COMPANY_EDIT = 'structureManageTree';
  static SAFETY_EMPLOYEE_ADD = 'administrationCreateEmployee';
  static SAFETY_EMPLOYEE_EDIT = 'administrationEditEmployee';
  static SAFETY_EMPLOYEE_DELETE = 'administrationDeleteEmployee';
  static SAFETY_EMPLOYEE_EXPORT = 'administrationExportEmployee';
  static SAFETY_EMPLOYEE_IMPORT = 'administrationImportEmployee';
  static SAFETY_DETAIL_DATA_EDIT = 'administrationEditDetaildata';
  static SAFETY_PERMISSION_EDIT = 'administrationEditPermission';
  static SAFETY_MODULE_EDIT  = 'administrationEditModules';
  static SAFETY_COSTCENTER_EDIT = 'administrationCostCenters';
  static SAFETY_FILESYSTEM_FILE = 'administrationFileAttachment';
  static SAFETY_FILESYSTEM_EDIT = 'netEdit';
  static SAFETY_CREATE_EDIT_GBU_BTA = 'editSpecialEntrys';
  static SAFETY_READ_ALL = 'readAllPermissions';

  // Observation
  static OBSERVATION_EDIT = 'observationsEdit';
  static OBSERVATION_DELETE = 'observationsDelete';

  // Audit
  static AUDIT_EDIT = 'proAddEditAudits';
  static AUDIT_INSPECTION_EDIT = 'proAddEditProtocols';

  // HazardAssessment
  static HAZARD_ASSESSMENT_ADD = 'gbuAdd';
  static HAZARD_ASSESSMENT_EDIT = 'gbuEdit';
  static HAZARD_ASSESSMENT_DELETE = 'gbuDelete';
  static HAZARD_ASSESSMENT_RATE = 'gbuRateOrganisation';
  static HAZARD_ASSESSMENT_CATALOGUE = 'gbuEditCatalogue';
  static HAZARD_ASSESSMENT_DATABASE = 'gbuDatabase';

  // Instruction
  static INSTRUCTION_ADD = 'instAdd';
  static INSTRUCTION_EDIT = 'instEdit';
  static INSTRUCTION_DELETE = 'instDelete';
  static INSTRUCTION_SHOW_SECTIONS = 'instShowAllSections';

  // Schooling
  static SCHOOLING_PLAN_EDIT = 'executionAssignEmployee';
  static SCHOOLING_PLAN_ASSIGN = 'executionAssignActivity';
  static SCHOOLING_TUITION_EDIT = 'executionManageGroup';
  static SCHOOLING_EXPORT = 'executionExport';
  static SCHOOLING_TOPIC_EDIT = 'executionTopManage';
  static SCHOOLING_TOPIC_ASSIGN = 'executionAssignTopic';
  static SCHOOLING_PACKAGE_EDIT = 'executionProfileManage';
  static SCHOOLING_PACKAGE_ASSIGN = 'executionAssignPaket';

  // Qualification
  static QUALIFICATION_EDIT = 'quaManageQualifications';
  static QUALIFICATION_EMPLOYEE_EDIT = 'quaAddEditUserQualifications';

  // Accident
  static ACCIDENT_COMPLETE_EDIT = 'medicalShowCompleteOverview';
  static ACCIDENT_OWN_EDIT = 'medicalShowOwnOverview';
  static ACCIDENT_DELETE = 'medicalDelete';
  static ACCIDENT_EXPORT = 'medicalExport';
  static ACCIDENT_REPORT_ACCESS = 'medicalAccessBericht';
  static ACCIDENT_ADVERT_ACCESS = 'medicalAccessAnzeige';
  static ACCIDENT_EDIT_ACCESS = 'medicalAccessNachbearbeitung';
  static ACCIDENT_PREREPORT_DELETE = 'medicalAccidentPreReportDelete';

  // Screening
  static SCREENING_ADD = 'scrAddScreening';
  static SCREENING_EDIT = 'scrEditScreening';
  static SCREENING_DELETE = 'scrDeleteScreening';
  static SCREENING_PRINT = 'scrPrint';
  static SCREENING_MAIL = 'scrMail';
  static SCREENING_EXPORT = 'scrExport';
  static SCREENING_VIEW_RESULT = 'scrEditViewExaminationResult';

  // Maintenance
  static MAINTENANCE_EDIT = 'manageEquipmentAndExams';
  static MAINTENANCE_EXAM_EXECUTE = 'executeExams'

  // ExternalCompany
  static EXTERNAL_COMPANY_EDIT = 'extManageCompany';
  static EXTERNAL_EMPLOYEE_EDIT = 'extManageEmployee';
  static EXTERNAL_COMPANY_EXPORT = 'extExport';
  static EXTERNAL_COMPANY_TERMINAL = 'extScanGatekeepter';
  static EXTERNAL_COMPANY_ASSIGN = 'extAssign';

  // Measure
  static MEASURE_EDIT = 'mesCreate';
  static MEASURE_DELETE = 'mesDelete';
  static MEASURE_EXPORT = 'mesExport';
  static MEASURE_CHANGE_DATE = 'mesChangePastDate';
}

