import { Language } from '@shared/models/language.model';

export class LoadLanguages {
  static readonly type = '[Language] Load Languages';
}

export class LoadLanguagesSuccess {
  static readonly type = '[Language] Load Languages Success';
  constructor(public languages: Language[]) {}
}

export class LoadLanguagesError {
  static readonly type = '[Language] Load Languages Error';
  constructor(public error: any) {}
}

export class SetLanguage {
  static readonly type = '[Language] Set Language';
  constructor(public payload: Language) {}
}

export class SetLanguageById {
  static readonly type = '[Language] Set Language By Id';
  constructor(public languageId: number) {}
}

export class SetDefaultLanguage {
  static readonly type = '[Language] Set Default Language';
}
