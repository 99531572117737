import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AccidentBodypartInjuryComponent} from '@shared/components/accident-bodypart-injury/accident-bodypart-injury.component';
import {PipesModule} from '@shared/pipes/pipes.module';



@NgModule({
  declarations: [
    AccidentBodypartInjuryComponent
  ],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [
    AccidentBodypartInjuryComponent
  ]
})
export class AccidentBodypartInjuryModule { }
