import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamExecutionStatusFilterComponent } from './exam-execution-status-filter.component';
import {FormsModule} from "@angular/forms";
import {MaterialModule} from "@shared/material.module";
import {PipesModule} from "@shared/pipes/pipes.module";
import {FilterModule} from "@shared/components/filter/filter.module";



@NgModule({
  declarations: [
    ExamExecutionStatusFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    FilterModule
  ],
  exports: [
    ExamExecutionStatusFilterComponent
  ]
})
export class ExamExecutionStatusFilterModule { }
