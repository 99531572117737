import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { LoadCountries, LoadCountriesSuccess } from '@shared/store/country/country.actions';
import { CountryService } from '@shared/services/country.service';
import { Country } from '@shared/models/country.model';
import {Injectable} from '@angular/core';

export class CountryStateModel {
  countries: Country[];
  loading: boolean;
}

@State<CountryStateModel>({
  name: 'country',
  defaults: {
    countries: [],
    loading: false,
  }
})
@Injectable()
export class CountryState {

  @Selector()
  static countries(state: CountryStateModel) {
    return state.countries;
  }

  @Selector()
  static loading(state: CountryStateModel) {
    return state.loading;
  }

  constructor(private countryService: CountryService) {}

  @Action(LoadCountries, { cancelUncompleted: true })
  loadLanguages({ dispatch, patchState }: StateContext<CountryStateModel>) {
    patchState({ loading: true });

    return this.countryService.getAll()
      .pipe(
        tap((result) => dispatch(new LoadCountriesSuccess(result.data)))
      );
  }

  @Action(LoadCountriesSuccess)
  loadLanguagesSuccess({ patchState }: StateContext<CountryStateModel>, { payload }: LoadCountriesSuccess) {
    patchState({
      countries: payload,
      loading: false,
    });
  }
}
