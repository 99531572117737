import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SalutationService } from '@shared/services/salutation.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'saf-salutation-select',
  templateUrl: './salutation-select.component.html',
  styleUrls: ['./salutation-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SalutationSelectComponent),
      multi: true
    }
  ],
})
export class SalutationSelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) { this._required = coerceBooleanProperty(value); }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) { this._disabled = coerceBooleanProperty(value); }
  private _disabled = false;

  @Input()
  get multiple(): boolean { return this._multiple; }
  set multiple(value: boolean) { this._multiple = coerceBooleanProperty(value); }
  private _multiple = false;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) { this._placeholder = value; }
  private _placeholder: string;

  @Input()
  get appearance(): MatFormFieldAppearance { return this._appearance; }
  set appearance(value: MatFormFieldAppearance) { this._appearance = value; }
  private _appearance: MatFormFieldAppearance = 'outline';

  @Input()
  get value(): any { return this._value; }
  set value(value: any) { this.writeValue(value); }
  private _value: any = null;

  get showClearButton(): boolean {
    return this.hasValue && !this.disabled && (this.multiple || !this.required);
  }

  get hasValue(): boolean {
    return this.multiple ? this.value && this.value.length : this.value;
  }

  _salutations: string[] = [];

  @Output() valueChange = new EventEmitter<any>();

  onChange: Function = () => {};
  onTouched: Function = () => {};

  constructor(private salutationService: SalutationService) {}

  ngOnInit() {
    this.salutationService.getList()
      .subscribe((salutations) => this._salutations = salutations);
  }

  registerOnChange(onChange: Function) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.valueChange.emit(value);
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  clear() {
    this.value = this.multiple ? [] : null;
  }

  onClickClear(event) {
    this.clear();

    event.stopImmediatePropagation();
  }
}
