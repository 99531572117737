import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccidentCauseSelectComponent } from './accident-cause-select.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        PipesModule,
        ReactiveFormsModule,
    ],
  declarations: [
    AccidentCauseSelectComponent
  ],
  exports: [
    AccidentCauseSelectComponent,
  ],
})
export class AccidentCauseSelectModule {}
