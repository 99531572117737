export class CompanySettingModel {
  companyId: number;
  companyImportReference: string = '';
  language: number;
  hazardAssessmentSettings: HazardAssessmentSettings;
  instructionSettings: InstructionSettings;
  medicalSettings: MedicalSettings;
  showAccident = 0;
  showObservation = 0;
}

interface HazardAssessmentSettings {
  ratingType: number;
  showNumber: boolean;
}

interface InstructionSettings {
  colorBio: string;
  colorGeneral: string;
  colorHazardSubstance: string;
  colorMachine: string;
  showNumber: boolean;
}

interface MedicalSettings {
  calculateDowntimeDays: boolean;
  hideInstantReportFields: boolean;
  obligationToReportAfterDays: number;
}
