import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { File as FileModel } from '@shared/models/file/file.model'
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import {FileDetails} from "../../models/file/file.model";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private api: ApiService, private httpClient: HttpClient) {}

  getList(params: object): Observable<ListResponse<FileModel>> {
    return this.api.get(`/files`, params);
  }

  getListDetails(params: object): Observable<ListResponse<FileDetails>> {
    return this.api.get(`/file-list`, params);
  }

  getById(id: number): Observable<FileModel> {
    return this.api.get(`/file/${id}`);
  }

  getByDataId(dataId: number, module: string): Observable<ListResponse<FileModel>> {
    return this.api.get(`/file/byDataId`, { dataId, module });
  }

  deleteByDataId(dataId: number, module: string): Observable<SuccessResponse> {
    return this.api.delete(`/file/byDataId`, { dataId, module });
  }

  getDownloadURL(file: FileModel, jwt: string) {
    return `${this.api.apiURL}/file/${file.id}/download?jwt=${jwt}`;
  }

  getViewURL(file: FileModel) {
    return `${this.api.apiURL}/file/${file.id}/view`;
  }

  upload(file: File, params: { dataId: number, module: string, name: string, directoryId?: number, categoryId?: number }, headers?: object): Observable<HttpEvent<any>> {
    const formData = new FormData();

    formData.append('addFile', file, file.name);

    return this.httpClient.request(new HttpRequest('POST', `${this.api.apiURL}/file`, formData,{
      params: ApiService.createParams(params),
      reportProgress: true,
      headers: ApiService.createHeaders(headers)
    }));
  }

  update(fileId: number, data: object): Observable<DataResponse<FileModel>> {
    return this.api.put(`/file/${fileId}`, data);
  }

  delete(fileId: number): Observable<SuccessResponse> {
    return this.api.delete(`/file/${fileId}`);
  }
}
