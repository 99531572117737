import { NgModule } from '@angular/core';
import { EmployeeActivityFilterComponent } from './employee-activity-filter.component';
import { EmployeeActivitySelectModule } from '../employee-activity-select/employee-activity-select.module';
import { FilterModule } from '@shared/components/filter/filter.module';

@NgModule({
  imports: [
    EmployeeActivitySelectModule,
    FilterModule,
  ],
  declarations: [
    EmployeeActivityFilterComponent,
  ],
  exports: [
    EmployeeActivityFilterComponent,
  ],
})
export class EmployeeActivityFilterModule {}
