import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HazardIconDialogComponent } from './hazard-icon-dialog.component';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        LoadingOverlayModule,
        SearchInputModule,
    ],
    declarations: [
        HazardIconDialogComponent,
    ],
    exports: [
        HazardIconDialogComponent,
    ]
})
export class HazardIconDialogModule {}
