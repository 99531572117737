import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Terminal } from '@shared/models/terminal.model';
import { TerminalActivity } from '@shared/models/terminal-activity.model';
import {PollActivity} from "@terminal/store/terminal-page/terminal-page.actions";
import {Store} from "@ngxs/store";
import {TerminalPageState} from "@terminal/store/terminal-page/terminal-page.state";
import {tap} from "rxjs/operators";
import {SchoolingPlanService} from "@shared/services/schooling-plan.service";
import {MatTableDataSource} from "@angular/material/table";
import {SchoolingPlanDetails} from "@shared/models/schooling-plan.model";
import {MatSort} from "@angular/material/sort";
import {ModuleColor} from "@shared/models/module-color";

@Component({
  selector: 'saf-terminal-page-schooling-view',
  templateUrl: './terminal-page-schooling-view.component.html',
  styleUrls: ['./terminal-page-schooling-view.component.scss']
})
export class TerminalPageSchoolingViewComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() terminal: Terminal;
  @Input() activity: TerminalActivity;
  @Output() viewCompleted = new EventEmitter();
  @Output() viewOnline = new EventEmitter();
  externalEmployeeId: number;
  public dataSource = new MatTableDataSource<SchoolingPlanDetails>([]);
  public tableColumns = ['topicName', 'status', 'intervalUntil', 'options'];
  loading: boolean;
  public moduleColor = ModuleColor;

  constructor(
    private store: Store,
    private schoolingPlanService: SchoolingPlanService
  ) {}

  ngOnInit() {
    this.dataSource.sort = this.sort;

    this.store.dispatch(new PollActivity({ terminalId: this.terminal.id }))
      .subscribe(() => {
        const activity = this.store.selectSnapshot(TerminalPageState.activity);
        if (activity) {
          this.externalEmployeeId = activity.externalEmployeeId;
          this.load(activity.externalEmployeeId);
        }
      });
  }

  load(externalEmployeeId: number) {
    const params = {
      filter: {
        externalEmployeeId : externalEmployeeId,
        active : true,
        allowTuition : false,
        startable : true
      },
      start: 0,
      limit: 50,
    };

    this.loading = true;
    this.schoolingPlanService.getListDetail(params)
      .pipe(tap(() => this.loading = false))
      .subscribe((result) => {
          this.dataSource.data = result.data;
        },
        error => this.loading = false);
  }

  openOnline(planDetails: SchoolingPlanDetails) {
    this.viewOnline.emit(planDetails);
  }

  onClickNext() {
    this.viewCompleted.emit();
  }

  reload() {
    if (this.loading) {
      return;
    }

    if (!this.externalEmployeeId) {
      this.load(this.store.selectSnapshot(TerminalPageState.activity).externalEmployeeId);
    }

    this.load(this.externalEmployeeId);
  }

  getDate(planDetails: SchoolingPlanDetails) {
    if (planDetails.plan.lastExecutionDate) {
      let date = new Date(planDetails.plan.lastExecutionDate);
      date.setMonth(date.getMonth() + planDetails.plan.executionTimeInMonth + planDetails.plan.validityInMonth);
      return date;
    }

    let date = new Date(planDetails.plan.startDate);
    date.setMonth(date.getMonth() + planDetails.plan.executionTimeInMonth);
    return date;
  }

    protected readonly ModuleColor = ModuleColor;
}
