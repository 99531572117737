import { NgModule } from '@angular/core';
import { HazardAssessmentTemplateStateFilterComponent } from './hazard-assessment-template-state-filter.component';
import { HazardAssessmentTemplateStateSelectModule } from '@shared/components/hazard-assessment-template-state-select/hazard-assessment-template-state-select.module';
import { FilterModule } from '@shared/components/filter/filter.module';

@NgModule({
  imports: [
    HazardAssessmentTemplateStateSelectModule,
    FilterModule,
  ],
  declarations: [
    HazardAssessmentTemplateStateFilterComponent,
  ],
  exports: [
    HazardAssessmentTemplateStateFilterComponent,
  ],
})
export class HazardAssessmentTemplateStateFilterModule {}
