import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { environment } from '@env/environment';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AuthState } from '@shared/store/auth/auth.state';
import { TerminalState } from '@shared/store/terminal/terminal.state';
import { BreakpointState } from '@shared/store/breakpoint/breakpoint.state';
import { ThemeState } from '@shared/store/theme/theme.state';
import { LanguageState } from '@shared/store/language/language.state';
import { CountryState } from '@shared/store/country/country.state';
import { ExternalCompanyState } from '@shared/store/external-company/external-company.state';
import { ExternalCompanyCategoryState } from '@shared/store/external-company-category/external-company-category.state';
import { BreadcrumbState } from '@shared/store/breadcrumb/breadcrumb.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      AuthState,
      TerminalState,
      BreakpointState,
      BreadcrumbState,
      ThemeState,
      LanguageState,
      CountryState,
      ExternalCompanyState,
      ExternalCompanyCategoryState,
    ], { developmentMode: !environment.production }),
    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
  ],
})
export class StoreModule {}
